import React from 'react';

import GetInTouchSection from 'components/main/contact/GetInTouchSection';
import ContactPoints from 'components/main/contact/ContactPoints';

import teamImage from './images/img_team.jpeg';

import './ContactPageContent.css';
import AboutAndFounders from '../AboutAndFounders';


const ContactPageContent = () => {
  return (
    <div className={'contact-page-content__component'}>
      {/* Team Image */}
      <div className="team-image-container">
        <img src={teamImage} alt=""/>
      </div>

      {/* Get in Touch section */}
      <GetInTouchSection />

      {/* Contact Points */}
      <ContactPoints />

      {/* About Us and Founders */}
      <AboutAndFounders/>

    </div>
  );
};

export default ContactPageContent;
