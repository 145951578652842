import React from 'react';

import './HowItWorksPageContent.css';

function HowItWorksPageContent() {
  return (
    <div className="how-it-works-page-content__component">
      <div className="info-text">
        <strong>NFTs</strong> (aka non-fungible token) prove ownership of a unique digital
        asset like image, art, music, collectibles, videos or anything
        else using blockchain technology.
      </div>

      <div className="sections-title">
        Create NFT on HeyMint.me in 3 simple steps
      </div>

      {/* Step 1 */}
      <div className="step-section">
        <div className="section-header">
          <div className="icon step-1" />
          <div className="text-block">
            <div className="title">Create Account and Top It Up</div>
            <div className="undertext">Super easy. No tech skills needed</div>
          </div>
        </div>
        <div className="image-container step-1" />
      </div>

      {/* Step 2 */}
      <div className="step-section">
        <div className="section-header">
          <div className="icon step-2" />
          <div className="text-block">
            <div className="title">Add Name, Description, Price & Upload File</div>
            <div className="undertext">As easy as 1-2-3</div>
          </div>
        </div>
        <div className="image-container step-2" />
      </div>

      {/* Step 3 */}
      <div className="step-section">
        <div className="section-header">
          <div className="icon step-3" />
          <div className="text-block">
            <div className="title">Mint NFT and List for Sale</div>
            <div className="undertext">Just $3 to mint. Sell to the whole world</div>
          </div>
        </div>
        <div className="image-container step-3" />
      </div>
    </div>
  );
}


export default HowItWorksPageContent;
