import restapi from 'utils/restapi';
import { getCookie, removeCookie } from 'utils/cookies';

import {
  SITE_LOADING,
  NOT_FOUND,
  NETWORK_ERROR,
  ACTION_SUCCESS_NOTIFICATION,
} from 'redux/common/types';

import {
  CLIENT_ID,
  CLIENT_SECRET,
  UNAUTHORIZED,
  LOGGED_IN,
} from 'redux/users/types';


const errorHandler = (dispatch, errorResponse, errorConst) => {
  switch (errorResponse.status) {
    case 401:
      if (getCookie('refreshToken')) {
        restapi.post('v1/users/token/refresh/',
          {
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            refresh_token: getCookie('refreshToken'),
          },
        )
        .then((response) => {
          dispatch({
            type: LOGGED_IN,
            payload: {
              data: {
                access_token: response.data.access_token,
                refresh_token: response.data.refresh_token,
              },
            },
          });
          dispatch({
            type: SITE_LOADING,
            payload: false,
          });
          return;
        })
        .catch(error => {
          if (String(error).includes('code 403')) {
            removeCookie();
            window.location.href = '/';
          }
          dispatch({
            type: UNAUTHORIZED,
          });
          dispatch({
            type: SITE_LOADING,
            payload: false,
          });
          return;
        });
      } else {
        dispatch({
          type: UNAUTHORIZED,
        });
        dispatch({
          type: SITE_LOADING,
          payload: false,
        });
      }
      break;
    case 403:
      removeCookie();
      window.location.href = '/';
      break;
    case 404:
      dispatch({
        type: NOT_FOUND,
      });
      dispatch({
        type: SITE_LOADING,
        payload: false,
      });
      break;
    default:
      dispatch({
        type: errorConst,
        payload: errorResponse,
      });
      dispatch({
        type: SITE_LOADING,
        payload: false,
      });
  }
};

export const baseErrorsHandlerDecorator = (
  successConst,
  errorConst,
  func,
  loading = true,
  actionSuccess = false,
) => {
  return dispatch => {
    dispatch({
      type: SITE_LOADING,
      payload: loading,
    });
    return func(dispatch)
      .then((response) => {
        dispatch({
          type: successConst,
          payload: response,
        });
        dispatch({
          type: SITE_LOADING,
          payload: false,
        });
        if (actionSuccess) {
          dispatch({
            type: ACTION_SUCCESS_NOTIFICATION,
            payload: actionSuccess,
          });
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response) {
          errorHandler(dispatch, error.response, errorConst);
        } else {
          dispatch({
            type: NETWORK_ERROR,
            payload: true,
          });
        }
      });
  };
};
