import React, { useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchMyCollectionsList,
  clearFetchMyCollectionsList,
} from 'redux/collections/actions';

import { Link } from 'react-router-dom';

import Button from 'components/common/elements/buttons/Button';
import CollectionsList from 'components/main/collections/common/CollectionsList';

import './MyCollectionsPageContent.css';


function MyCollectionsPageContent(props) {
  const {
    fetchMyCollectionsList,
    clearFetchMyCollectionsList,
    myCollectionsList,
    myCollectionsListError,
  } = props;

  useEffect(() => {
    fetchMyCollectionsList();
    return () => {
      clearFetchMyCollectionsList();
    }
  }, []);

  return (
    <div className="my-collections-page-content__component">
      <div className="header-block">
        <div className="text-block">
          <h1>My Collections</h1>
          <div className="text">
            Create, curate, and manage collections of unique NFTs to share and sell.
          </div>
        </div>

        <Link to="/collection/create">
          <Button className="primary">
            Mint New Collection
          </Button>
        </Link>
      </div>

      {/* My Collections List */}
      <CollectionsList
        currentList={myCollectionsList}
        currentListError={myCollectionsListError}
        loadFunction={pageNumber => fetchMyCollectionsList(pageNumber)}
      />

    </div>
  );
}

MyCollectionsPageContent.propTypes = {
  fetchMyCollectionsList: PropTypes.func.isRequired,
  clearFetchMyCollectionsList: PropTypes.func.isRequired,
  myCollectionsList: PropTypes.arrayOf(PropTypes.element),
  myCollectionsListError: PropTypes.object,
};

MyCollectionsPageContent.defaultProps = {
  fetchMyCollectionsList: null,
  myCollectionsListError: null,
};

const mapStateToProps = state => (
  {
    myCollectionsList: state.collectionsReducer.myCollectionsList,
    myCollectionsListError: state.collectionsReducer.myCollectionsListError,
  }
);

export default connect(mapStateToProps, {
  fetchMyCollectionsList,
  clearFetchMyCollectionsList,
})(MyCollectionsPageContent);
