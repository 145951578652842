import React from 'react';

import { Helmet } from 'react-helmet';

import AuthLayout from 'components/common/layouts/AuthLayout';
import LoginForm from 'components/authentication/login/LoginForm';

const LoginPage = () => (
  <AuthLayout form="login">
    <Helmet>
      <title>First Hedera NFT Marketplace | HeyMint.me</title>
      <meta property="og:title" content="First Hedera NFT Marketplace | HeyMint.me" />
      <meta
        name="description"
        content="HeyLink.me Log In page. The amazing digital marketplace for NFTs of the world is here."
      />
      <meta
        property="og:description"
        content="HeyLink.me Log In page. The amazing digital marketplace for NFTs of the world is here."
      />
    </Helmet>
    <LoginForm />
  </AuthLayout>
);

export default LoginPage;
