import React from 'react';
import PropTypes from 'prop-types';

import './CommonLogoContainer.css';


const CommonLogoContainer = ({ logoUrl, type, width, height }) => (
  <div
    className={`common-logo-container__component${type ? ` ${type}` : ''}`}
    style={{
      backgroundImage: logoUrl && `url(${logoUrl})`,
      width: `${width}px` || '40px',
      minWidth: `${width}px` || '40px',
      height: `${height}px` || '40px',
    }}
  />
);

CommonLogoContainer.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  type: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

CommonLogoContainer.defaultProps = {
  type: null,
  width: null,
  height: null,
};

export default CommonLogoContainer;
