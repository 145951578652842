import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { logout } from 'redux/users/actions';

import { Link } from 'react-router-dom';

import MainLogo from 'components/common/logos/MainLogo';
import Button from 'components/common/elements/buttons/Button';
import CommonLogoContainer from 'components/common/logos/CommonLogoContainer';
import ProfileWallet from 'components/main/profile/ProfileWallet';
import GlobalSearch from 'components/common/searches/GlobalSearch';

import './MobileMenu.css';


function MobileMenu(props) {
  const {
    logout,
    me,
    history,
    walletBalance,
  } = props;

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="main-mobile-menu__component">
      <div
        className="mobile-menu-icon"
        onClick={() => setMobileMenuOpen(true)}
        role="button"
        tabIndex={-1}
      />

      {mobileMenuOpen &&
        <div className="mobile-menu-wrapper">
          {/* Header */}
          <div className="header">
            <div
              className="logo-container"
              onClick={() => {
                setMobileMenuOpen(false);
                history.push('/');
              }}
              role="link"
              tabIndex={-1}
            >
              <MainLogo />
            </div>
            <div
              className="close-button"
              onClick={() => setMobileMenuOpen(false)}
              role="button"
              tabIndex={-1}
            />
          </div>

          <div className="mobile-search">
            <GlobalSearch />
          </div>

          {/* User Info */}
          {me &&
            <div className="account-info">
              <CommonLogoContainer
                logoUrl={me.avatar}
                width={50}
                height={50}
              />
              <div className="info">
                <div className="username">{me.username}</div>
              </div>
            </div>
          }

          {me && me.wallet &&
            <div className="blockchain-info">
              <ProfileWallet profileData={me} />
              {walletBalance &&
                <div className="wallet-balance">
                  Balance:&nbsp;&nbsp;
                  <span>{Number(walletBalance?.balance)} {walletBalance?.currency_code.toUpperCase()}</span>
                </div>
              }
            </div>
          }

          {/* Auth Buttons */}
          {!me &&
            <div className="auth-buttons">
              <Link to="/login">
                <Button className="secondary">
                  Log In
                </Button>
              </Link>

              <Link to="/signup">
                <Button className="primary">
                  Sign Up
                </Button>
              </Link>
            </div>
          }


          {/* Main Navigation */}
          <div className="nav-wrapper">
            {me &&
              <Link
                className="nav-item"
                to="/asset/create"
                onClick={() => setMobileMenuOpen(false)}
              >
                <div className="icon create" />
                <div className="text">Create</div>
              </Link>
            }

            <Link
              className="nav-item"
              to="/explore"
              onClick={() => setMobileMenuOpen(false)}
            >
              <div className="icon explore" />
              <div className="text">Explore</div>
            </Link>

            <Link
              className="nav-item"
              to="/contact"
              onClick={() => setMobileMenuOpen(false)}
            >
              <div className="icon contact" />
              <div className="text">Contact</div>
            </Link>

            <Link
              className="nav-item"
              to="/how-it-works"
              onClick={() => setMobileMenuOpen(false)}
            >
              <div className="icon how-it-works" />
              <div className="text">How it works</div>
            </Link>

            <Link
              className="nav-item"
              to="/pricing"
              onClick={() => setMobileMenuOpen(false)}
            >
              <div className="icon pricing" />
              <div className="text">Pricing</div>
            </Link>

            {me &&
              <Fragment>
                <Link
                  className="nav-item"
                  to="/profile/settings"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <div className="icon settings" />
                  <div className="text">Settings</div>
                </Link>

                <Link
                  className="nav-item"
                  to="/profile"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <div className="icon profile" />
                  <div className="text">My profile</div>
                </Link>

                <Link
                  className="nav-item"
                  to="/collections"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <div className="icon collections" />
                  <div className="text">My collections</div>
                </Link>
              </Fragment>
            }
          </div>

          {/* Disconnect Button */}
          {me &&
            <div className="disconnect-button">
              <Button
                className="primary"
                onClick={() => {
                  logout();
                  setMobileMenuOpen(false);
                }}
              >
                Disconnect
              </Button>
            </div>
          }

        </div>
      }
    </div>
  );
}

MobileMenu.propTypes = {
  logout: PropTypes.func.isRequired,
  me: PropTypes.object,
  history: PropTypes.object.isRequired,
  walletBalance: PropTypes.object,
};

MobileMenu.defaultProps = {
  me: null,
  walletBalance: null,
};

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
    walletBalance: state.blockchainReducer.walletBalance,
  }
);

export default connect(
  mapStateToProps,
  {
    logout,
  },
)(MobileMenu);
