import React, { useCallback } from 'react';
import PropTypes from "prop-types";

import { useDropzone } from 'react-dropzone';

import './UploadAssetMedia.css';


function UploadAssetMedia(props) {
  const {
    setAssetMedia,
    setCurrentMedia,
    currentMedia,
  } = props;

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles) {
      setAssetMedia(acceptedFiles[0]);
      setCurrentMedia(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
      }))[0]);
    }
  }, []);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/png, image/gif, image/svg',
    maxFiles: 1,
  });

  return (
    <div className="upload-asset-media__component">
      <div className="title">Upload File</div>
      <div className="undertext">
        Drag or choose your file to upload
      </div>
      <div className="dropzone-container">
        <div
          className="dropzone-area"
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          {currentMedia ?
            <div className="image-block">
              <img src={currentMedia.preview} alt=""/>
            </div>
            :
            <div className="info-block">
              <div className="icon" />
              <div className="text">
                <div>JPG, PNG, GIF or SVG. Max size: 100 MB</div>
              </div>
            </div>
          }

        </div>
      </div>

      {currentMedia &&
        <div
          className="remove-button"
          onClick={() => setCurrentMedia(null)}
          role={'button'}
          tabIndex={-1}
        >
          Remove
        </div>
      }
    </div>
  );
}

UploadAssetMedia.propTypes = {
  setAssetMedia: PropTypes.func.isRequired,
  setCurrentMedia: PropTypes.func.isRequired,
  currentMedia: PropTypes.object,
};

UploadAssetMedia.defaultProps = {
  currentMedia: null,
};

export default UploadAssetMedia;
