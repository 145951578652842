import React from 'react';
import PropTypes from "prop-types";

import AssetsSaleTypeSelector from 'components/main/assets/filters-sorting/AssetsSaleTypeSelector';
import AssetsSortingSelector from 'components/main/assets/filters-sorting/AssetsSortingSelector';

import './AssetsFiltersBar.css';


function AssetsFiltersBar(props) {
  const {
    currentFilters,
    setCurrentFilters,
  }  = props;

  return (
    <div className="assets-filters-bar__component">
      <AssetsSaleTypeSelector
        currentFilters={currentFilters}
        setCurrentFilters={setCurrentFilters}
      />
      <AssetsSortingSelector
        currentFilters={currentFilters}
        setCurrentFilters={setCurrentFilters}
      />
    </div>
  );
}

AssetsFiltersBar.propTypes = {
  setCurrentFilters: PropTypes.func.isRequired,
  currentFilters: PropTypes.object,
};

AssetsFiltersBar.defaultProps = {
  setCurrentFilters: PropTypes.func.isRequired,
  currentFilters: null,
};

export default AssetsFiltersBar;
