import React from 'react';
import PropTypes from 'prop-types';

import './CommonTabList.css';

function CommonTabList(props) {
  const {
    children,
    className,
    center,
  } = props;

  const calculateClassname = () => {
    let value = 'common-tab-list__component';
    if (className) { value += ` ${className}`; }
    if (center) { value += ' center'; }
    return value;
  };

  return (
    <div className={calculateClassname()}>
      {children}
    </div>
  );
}

CommonTabList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
  className: PropTypes.string,
  center: PropTypes.bool,
};
CommonTabList.defaultProps = {
  className: null,
  center: null,
};

export default CommonTabList;
