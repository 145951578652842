import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import Button from 'components/common/elements/buttons/Button';

import './RegistrationSuccess.css';


function RegistrationSuccess(props) {
  const {
    registrateUserSuccess,
    me,
  } = props;

  if (!registrateUserSuccess) return null;

  return (
    <div className="registration-success__component">
      <h1>Welcome to HeyMint.me</h1>

      {me &&
        <div className="email">
          {me.email}
        </div>
      }
      <div className="text">
        <div>
          To confirm & verify your profile, please click on the link we have sent to your email.
        </div>
        <div>
          If you still can&prime;t verify your email after a few attempts,
          please send an email to team@heymint.me
        </div>
      </div>
      <Link to="/explore">
        <Button className="primary">
          <div className="button-text">Continue to HeyMint.me</div>
          <div className="icon"/>
        </Button>
      </Link>
    </div>
  );
}

RegistrationSuccess.propTypes = {
  registrateUserSuccess: PropTypes.object,
  me: PropTypes.object,
};

RegistrationSuccess.defaultProps = {
  registrateUserSuccess: null,
  me: null,
};

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
    registrateUserSuccess: state.usersReducer.registrateUserSuccess,
  }
);

export default connect(
  mapStateToProps,
  {}
)(RegistrationSuccess);
