import React from 'react';

import { Outlet } from 'react-router-dom';

import TopMenu from 'components/common/navigation/TopMenu';
import CommonFooter from 'components/common/navigation/CommonFooter';

import './CommonPageLayout.css';

function CommonPageLayout() {
  return (
    <div className="common-page-layout__component">
      <div className="main-wrapper">
        {/* Top Menu */}
        <div className="top-menu">
          <TopMenu />
        </div>

        {/* Content Area */}
        <div className="content-area">
          <Outlet />
        </div>

        {/* Footer */}
        <div className="common-footer">
          <CommonFooter />
        </div>
      </div>
    </div>
  );
}

export default CommonPageLayout;
