import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import { buyAsset, clearBuyAsset } from 'redux/assets/actions';
import { fetchAssetDetails } from 'redux/assets/actions';
import { fetchWalletBalance } from 'redux/blockchain/actions';

import { Dimmer, Loader } from "semantic-ui-react";

import Button from 'components/common/elements/buttons/Button';
import CommonMessage from 'components/common/messages/CommonMessage';
import CommonModal from 'components/common/elements/modals/CommonModal';

import './AssetBuyModal.css';


function AssetBuyModal(props) {
  const {
    buyAsset,
    clearBuyAsset,
    fetchAssetDetails,
    fetchWalletBalance,
    onClose,
    open,
    assetDetails,
    buyAssetSuccess,
    buyAssetError,
    walletBalance,
    projectSettings,
  } = props;

  const [showLoader, setShowLoader] = useState(false);

  const assetPrice = assetDetails?.price?.price_coin;
  const isBalanceEnough = (Number(walletBalance?.balance) - Number(assetPrice)) >= 0 ? true : false;
  const transactionFee = projectSettings?.nft_fees?.nft_buy_fee;

  useEffect(() => {
    if (buyAssetSuccess) {
      fetchAssetDetails(assetDetails.token_id, assetDetails.serial_number);
      fetchWalletBalance();
      setShowLoader(false);
    }
  }, [buyAssetSuccess]);

  useEffect(() => {
    if (buyAssetError) {
      setShowLoader(false);
    }
  }, [buyAssetError]);

  const onBuy = () => {
    const formData = new FormData();
    formData.append('token_id', assetDetails.token_id);
    formData.append('serial_number', assetDetails.serial_number);
    buyAsset(formData);
    setShowLoader(true);
  };

  const renderCheckoutForm = (
    <div className="checkout-form">
      <div className="parameter">
        <div className="label">Your balance</div>
        <div className="value">
          {walletBalance?.balance} {walletBalance?.currency_code.toUpperCase()}
        </div>
      </div>
      <div className="parameter">
        <div className="label">You will pay</div>
        <div className="value">
          {assetPrice} {assetDetails?.blockchain?.currency_code.toUpperCase()}
        </div>
      </div>
      {transactionFee &&
        <div className="parameter">
          <div className="label">Transaction fee</div>
          <div className="value">
            ~{transactionFee}
          </div>
        </div>
      }

      {!isBalanceEnough &&
        <CommonMessage warning>
          Your balance is too low to buy...
        </CommonMessage>
      }
      <Button
        className="primary"
        onClick={onBuy}
        disabled={!isBalanceEnough}
      >
        Buy for {assetPrice} {assetDetails?.blockchain?.currency_code.toUpperCase()}
      </Button>
    </div>
  );

  const renderSuccesCheckout = (
    <div className="success-checkout-wrapper">
      <div className="icon" />
      <div className="success-text">Your payment was succesful!</div>
      <Button className="primary" onClick={onClose}>
        Ok, sounds good!
      </Button>
    </div>
  );

  return (
    <CommonModal
      className="asset-buy-modal__component"
      open={open}
      onClose={onClose}
      title={'Checkout'}
      onUnmount={() => {
        clearBuyAsset();
      }}
      size={'tiny'}
    >
      {/* Loader */}
      {showLoader &&
        <Dimmer active>
          <Loader>Please, wait... Checkout is in progress.</Loader>
        </Dimmer>
      }

      {/* Checkout Form */}
      {!buyAssetSuccess && renderCheckoutForm}

      {/* Success Message */}
      {buyAssetSuccess && renderSuccesCheckout}

      {/* Errors */}
      {buyAssetError &&
        <CommonMessage error errors={buyAssetError} />
      }
    </CommonModal>
  )
}

AssetBuyModal.propTypes = {
  buyAsset: PropTypes.func.isRequired,
  clearBuyAsset: PropTypes.func.isRequired,
  fetchAssetDetails: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  fetchWalletBalance: PropTypes.func.isRequired,
  open: PropTypes.bool,
  assetDetails: PropTypes.object.isRequired,
  buyAssetSuccess: PropTypes.object,
  buyAssetError: PropTypes.object,
  walletBalance: PropTypes.object,
  projectSettings: PropTypes.object,
};

AssetBuyModal.defaultProps = {
  open: null,
  buyAssetSuccess: null,
  buyAssetError: null,
  walletBalance: null,
  projectSettings: null,
};

const mapStateToProps = state => (
  {
    buyAssetSuccess: state.assetsReducer.buyAssetSuccess,
    buyAssetError: state.assetsReducer.buyAssetError,
    walletBalance: state.blockchainReducer.walletBalance,
    projectSettings: state.commonReducer.projectSettings,
  }
);

export default connect(
  mapStateToProps,
  {
    buyAsset,
    clearBuyAsset,
    fetchAssetDetails,
    fetchWalletBalance,
  },
)(AssetBuyModal);
