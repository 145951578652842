const validate = values => {
  const errors = {};

  if (!values.token_name) {
    errors.token_name = 'Name is required';
  }

  // if (!values.token_symbol) {
  //   errors.token_symbol = 'Symbol is required';
  // }

  return errors;
};

export default validate;
