import React from 'react';
import PropTypes from "prop-types";

import { Link } from 'react-router-dom';

import CommonModal from 'components/common/elements/modals/CommonModal';
import Button from 'components/common/elements/buttons/Button';

import './CreateAssetSuccessModal.css';

function CreateAssetSuccessModal(props) {
  const {
    item,
    open,
    onClose,
    onUnmount,
  } = props;

  if (!item) return null;

  return (
    <CommonModal
      className="create-asset-success-modal__component"
      open={open}
      onClose={onClose}
      onUnmount={onUnmount}
      size={'small'}
    >
      <div
        className="image"
        style={{ backgroundImage: item.nft_media_cover && `url(${item.nft_media_cover})` }}
      />
      <div className="undertext">
        Your <span>{item.token_name}</span> is successfully created.
      </div>
      <div className="buttons-block">
        <Link to={`/asset/${item.token_id}/${item.serial_number}`}>
          <Button className="primary">View NFT</Button>
        </Link>

        <Button
          className="secondary"
          onClick={onClose}
        >
          Create another
        </Button>
      </div>
    </CommonModal>
  );
}

CreateAssetSuccessModal.propTypes = {
  onUnmount: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  item: PropTypes.object.isRequired,
};

CreateAssetSuccessModal.defaultProps = {
  open: null,
};

export default CreateAssetSuccessModal;
