import React from 'react';
import PropTypes from 'prop-types';

import { Button as Btn } from 'semantic-ui-react';

import './Button.css';


const Button = ({
  children,
  onClick,
  disabled,
  className,
  loading,
}) => {
  const calculateClassName = () => {
    let value = 'button__component';
    if (className) { value += ` ${className}`; }
    if (disabled) { value += ' disabled'; }
    return value;
  };

  return (
    <Btn
      className={calculateClassName()}
      onClick={onClick}
      disabled={disabled}
      loading={loading}
    >
      {children}
    </Btn>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  onClick: null,
  disabled: null,
  className: null,
  loading: false,
};

export default Button;
