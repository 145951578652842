import restapi from 'utils/restapi';
import { baseErrorsHandlerDecorator } from 'redux/utils';

import {
  FETCH_MY_COLLECTIONS_LIST,
  FETCH_MY_COLLECTIONS_LIST_ERROR,
  CLEAR_FETCH_MY_COLLECTIONS_LIST,
  FETCH_COLLECTION_DETAILS,
  FETCH_COLLECTION_DETAILS_ERROR,
  CLEAR_FETCH_COLLECTION_DETAILS,
  CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_ERROR,
  CLEAR_CREATE_COLLECTION,
  UPDATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_ERROR,
  CLEAR_UPDATE_COLLECTION,
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_ERROR,
  CLEAR_DELETE_COLLECTION,
  VALIDATE_COLLECTION_TITLE_SUCCESS,
  VALIDATE_COLLECTION_TITLE_ERROR,
  CLEAR_VALIDATE_COLLECTION_TITLE,
  FETCH_EXPLORE_COLLECTIONS_LIST,
  FETCH_EXPLORE_COLLECTIONS_LIST_ERROR,
  CLEAR_FETCH_EXPLORE_COLLECTIONS_LIST,
  FETCH_PROFILE_COLLECTIONS_LIST,
  FETCH_PROFILE_COLLECTIONS_LIST_ERROR,
  CLEAR_FETCH_PROFILE_COLLECTIONS_LIST,
  FETCH_COLLECTION_ACTIVITIES_LIST,
  FETCH_COLLECTION_ACTIVITIES_LIST_ERROR,
  CLEAR_FETCH_COLLECTION_ACTIVITIES_LIST,
  FETCH_COLLECTION_CATEGORIES,
  FETCH_COLLECTION_CATEGORIES_ERROR,
  CLEAR_FETCH_COLLECTION_CATEGORIES,
} from 'redux/collections/types';

export const fetchMyCollectionsList = (pageNumber = 1) => baseErrorsHandlerDecorator(
  FETCH_MY_COLLECTIONS_LIST,
  FETCH_MY_COLLECTIONS_LIST_ERROR,
  () => restapi.get(`v1/token/collection/?page=${pageNumber}`),
  false,
);

export const clearFetchMyCollectionsList = () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_MY_COLLECTIONS_LIST,
  });
};

export const fetchCollectionDetails = slug => baseErrorsHandlerDecorator(
  FETCH_COLLECTION_DETAILS,
  FETCH_COLLECTION_DETAILS_ERROR,
  () => restapi.get(`v1/token/collection/slug/${slug}/`),
  false,
);

export const clearFetchCollectionDetails = () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_COLLECTION_DETAILS,
  });
};

export const createCollection = formData => baseErrorsHandlerDecorator(
  CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_ERROR,
  () => restapi.post('v1/token/collection/', formData),
  false,
  true /* actionSuccess */,
);

export const clearCreateCollection = () => dispatch => {
  dispatch({
    type: CLEAR_CREATE_COLLECTION,
  });
};

export const patchCollection = (slug, formData) => baseErrorsHandlerDecorator(
  UPDATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_ERROR,
  () => restapi.patch(`v1/token/collection/${slug}/`, formData),
  false,
  true /* actionSuccess */,
);

export const clearPatchCollection = () => dispatch => {
  dispatch({
    type: CLEAR_UPDATE_COLLECTION,
  });
};

export const deleteCollection = id => baseErrorsHandlerDecorator(
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_ERROR,
  () => restapi.delete(`v1/token/collection/${id}/`),
  false,
);

export const clearDeleteCollection = () => dispatch => {
  dispatch({
    type: CLEAR_DELETE_COLLECTION,
  });
};

export const validateCollectionTitle = formData => baseErrorsHandlerDecorator(
  VALIDATE_COLLECTION_TITLE_SUCCESS,
  VALIDATE_COLLECTION_TITLE_ERROR,
  () => restapi.post('v1/token/collection/validate/', formData),
  false,
  true /* actionSuccess */,
);

export const clearValidateCollectionTitle = () => dispatch => {
  dispatch({
    type: CLEAR_VALIDATE_COLLECTION_TITLE,
  });
};

export const fetchExploreCollectionsList = (pageNumber = 1) => baseErrorsHandlerDecorator(
  FETCH_EXPLORE_COLLECTIONS_LIST,
  FETCH_EXPLORE_COLLECTIONS_LIST_ERROR,
  () => restapi.get(`v1/token/collection/explore/?page=${pageNumber}`),
  false,
);

export const clearFetchExploreCollectionsList = () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_EXPLORE_COLLECTIONS_LIST,
  });
};

export const fetchProfileCollectionsList = (username, pageNumber = 1) => baseErrorsHandlerDecorator(
  FETCH_PROFILE_COLLECTIONS_LIST,
  FETCH_PROFILE_COLLECTIONS_LIST_ERROR,
  () => restapi.get(`v1/token/collection/user/${username}/?page=${pageNumber}`),
  false,
);

export const clearFetchProfileCollectionsList = () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_PROFILE_COLLECTIONS_LIST,
  });
};

export const fetchCollectionActivitiesList = slug => baseErrorsHandlerDecorator(
  FETCH_COLLECTION_ACTIVITIES_LIST,
  FETCH_COLLECTION_ACTIVITIES_LIST_ERROR,
  () => restapi.get(`v1/analytics/collection/activity/list/?collection_slug=${slug}`),
  false,
);

export const clearFetchCollectionActivitiesList = () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_COLLECTION_ACTIVITIES_LIST,
  });
};

export const fetchCollectionCategories = () => baseErrorsHandlerDecorator(
  FETCH_COLLECTION_CATEGORIES,
  FETCH_COLLECTION_CATEGORIES_ERROR,
  () => restapi.get('v1/token/category/'),
  false,
);

export const clearFetchCollectionCategories = () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_COLLECTION_CATEGORIES,
  });
};
