import React, { Fragment } from 'react';
import PropTypes from "prop-types";

import SegmentLoader from 'components/common/loaders/SegmentLoader';
import CommonMessage from 'components/common/messages/CommonMessage';
import NoItemsPlaceholder from 'components/common/placeholders/NoItemsPlaceholder';
import CommonPagination from 'components/common/navigation/CommonPagination';

import AssetListItem from 'components/main/assets/common/AssetListItem';

import './AssetList.css';


function AssetList(props) {
  const {
    handlePaginationPageClick,
    list,
    listError,
  } = props;

  return (
    <div className="asset-list__component">
      {/* Loader */}
      {!list && !listError && <SegmentLoader />}

      {/* Empty Placeholder */}
      {list?.results?.length === 0 &&
        <NoItemsPlaceholder title="No NFTs to display" />
      }

      {/* List Wrapper */}
      {list?.results?.length > 0 &&
        <Fragment>
          <div className="list-wrapper">
            {list.results.map(item => (
              <AssetListItem
                key={`asset-list-item-key-${item.id}`}
                item={item}
              />
            ))}
          </div>

          {/* Pagination */}
          {list.count > 30 &&
            <CommonPagination
              containerClassName={'pagination-wrapper'}
              activeClassName={'active'}
              pageCount={Math.ceil(list.count / 30)}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={handlePaginationPageClick}
            />
          }
        </Fragment>
      }

      {/* Errors */}
      {listError &&
        <CommonMessage error errors={listError} />
      }
    </div>
  );
}

AssetList.propTypes = {
  handlePaginationPageClick: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  listError: PropTypes.object,
};

AssetList.defaultProps = {
  listError: null,
};

export default AssetList;
