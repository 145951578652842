import React, { Fragment, useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchPublicProfileDetails,
  clearFetchPublicProfileDetails,
} from 'redux/users/actions';

import { Loader } from 'semantic-ui-react';

import ProfileView from 'components/main/profile/ProfileView';


function PublicProfilePageContent(props) {
  const {
    fetchPublicProfileDetails,
    clearFetchPublicProfileDetails,
    username,
    publicProfileDetails,
    publicProfileDetailsError,
  } = props;

  useEffect(() => {
    fetchPublicProfileDetails(username);

    return () => {
      clearFetchPublicProfileDetails();
    };
  }, []);

  return (
    <Fragment>
      {/* Loader */}
      {!publicProfileDetails && !publicProfileDetailsError &&
        <Loader active />
      }

      {/* Profile Details */}
      {publicProfileDetails &&
        <ProfileView
          publicUsername={username}
          profileData={publicProfileDetails}
        />
      }
    </Fragment>
  );
}

PublicProfilePageContent.propTypes = {
  fetchPublicProfileDetails: PropTypes.func.isRequired,
  clearFetchPublicProfileDetails: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  publicProfileDetails: PropTypes.object,
  publicProfileDetailsError: PropTypes.object,
};

PublicProfilePageContent.defaultProps = {
  publicProfileDetails: null,
  publicProfileDetailsError: null,
};

const mapStateToProps = state => (
  {
    publicProfileDetails: state.usersReducer.publicProfileDetails,
    publicProfileDetailsError: state.usersReducer.publicProfileDetailsError,
  }
);

export default connect(
  mapStateToProps,
  {
    fetchPublicProfileDetails,
    clearFetchPublicProfileDetails,
  },
)(PublicProfilePageContent);
