import React from 'react';
import PropTypes from "prop-types";

import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';

import './AssetListItem.css';


function AssetListItem(props) {
  const { item } = props;

  const price = item.price ? `${item.price.price_coin} ${item.blockchain?.currency_code.toUpperCase()}` : null;

  return (
    <Link
      className="asset-list-item__component"
      to={`/asset/${item.token_id}/${item.serial_number}`}
    >
      <div className="image-wrapper">
        <div
          className="image"
          style={{ backgroundImage: item.nft_media_cover && `url(${item.nft_media_cover})` }}
        />
      </div>

      <div className="info-wrapper">
        <Link
          className="collection"
          to={`/collection/${item.collection.slug}`}
        >
          {item.collection.title}
        </Link>

        <div className="asset-name">{item.token_name}</div>

        <div className="divider" />

        <div className="sale-info">
          <div className="label">
            {price ? 'Price' : ''}
          </div>
          <div className={`price${!price ? ' not-listed' : ''}`}>
            {price || 'Not Listed'}
          </div>
        </div>

      </div>
    </Link>
  );
}

AssetListItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default AssetListItem;
