import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  retrieveToken,
  clearLoginError,
} from 'redux/users/actions';

import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form } from 'semantic-ui-react';

import Button from 'components/common/elements/buttons/Button';
import InputTextField from 'components/redux-form-adapters/InputTextField';

import CommonLoader from 'components/common/loaders/CommonLoader';
import CommonMessage from 'components/common/messages/CommonMessage';

import { usePrevious } from 'utils/custom-hooks/usePrevious';

import validate from './validate';

import './LoginForm.css';

function LoginForm(props) {
  const {
    retrieveToken,
    clearLoginError,
    handleSubmit,
    valid,
    email,
    password,
    loginError,
    me,
  } = props;

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    return () => {
      setShowLoader(false);
      clearLoginError();
    };
  }, []);

  const prevMe = usePrevious(me);
  const navigate = useNavigate();
  const location = useLocation();
  const fromPage = location.state?.from?.pathname || '/explore';
  useEffect(() => {
    if (!prevMe && me) {
      navigate(fromPage);
    }
  }, [me]);

  const _handleSubmit = () => {
    setShowLoader(true);
    retrieveToken(email.toLowerCase(), password);
  };

  return (
    <div className="login-form__component">
      <CommonLoader active={!!showLoader && !loginError} />

      <h1>Sign In to your account</h1>

      {/* Form */}
      <div className="form-wrapper">
        <Form
          onSubmit={handleSubmit(_handleSubmit)}
          error={!valid}
        >
          <Field
            label="Email Address"
            component={InputTextField}
            name="email"
            type="email"
            required
          />

          <Field
            label="Enter your password"
            component={InputTextField}
            name="password"
            type="password"
            required
          />

          <Link
            className="forgot-password-link"
            to={'/password-recovery'}
          >
            Forgot Password?
          </Link>

          {loginError &&
            <CommonMessage
              error
              errors={loginError}
            />
          }

          <Button
            className="primary"
            submit
          >
            Log In
          </Button>
        </Form>

        <div className="underform-text">
          Don&prime;t have an account yet?&nbsp;&nbsp;&nbsp;<Link to="/signup">Sign Up</Link>
        </div>
      </div>
    </div>
  );
}

LoginForm.propTypes = {
  retrieveToken: PropTypes.func.isRequired,
  clearLoginError: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  email: PropTypes.string,
  password: PropTypes.string,
  loginError: PropTypes.object,
  history: PropTypes.object.isRequired,
  me: PropTypes.object,
};

LoginForm.defaultProps = {
  valid: false,
  email: null,
  password: null,
  loginError: null,
  me: null,
};

const mapStateToProps = (state) => {
  const selector = formValueSelector('LoginForm');
  return {
    email: selector(state, 'email'),
    password: selector(state, 'password'),
    accessToken: state.usersReducer.accessToken,
    loginError: state.usersReducer.loginError,
    me: state.usersReducer.me,
  };
};

export default connect(
  mapStateToProps,
  {
    retrieveToken,
    clearLoginError,
  },
)(reduxForm({
  form: 'LoginForm',
  validate,
})(LoginForm));
