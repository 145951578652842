import React from 'react';
import PropTypes from 'prop-types';

import './CommonTableHeader.css';

const CommonTableHeader = ({ children, className }) => {
  const calculateClassname = () => {
    let value = 'common-table-header__component';
    if (className) { value += ` ${className}`; }
    return value;
  };

  return (
    <div className={calculateClassname()}>
      {children}
    </div>
  );
};

CommonTableHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
};

CommonTableHeader.defaultProps = {
  className: null,
};

export default CommonTableHeader;
