import React from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import Button from 'components/common/elements/buttons/Button';
import CommonNFTSlider from 'components/common/CommonNFTSlider';

import './MainLandingPageContent.css';


function MainLandingPageContent({ me }) {
  return (
    <div className="main-landing-page-content__component">
      {/* Header Section */}
      <div className="section header">
        <div className="section-wrapper">

          <div className="text-container">
            <div className="wrapper">
              <div className="title-text">
                <div>Discover, Collect,</div>
                <div>Create & Sell NFTs</div>
              </div>
              <div className="undertext">
                <div>The only marketplace that allows you to mint NFTs (non-fungible tokens) for $3</div>
                <div>Created by award winning HeyLink.me team</div>
              </div>

              <div className="buttons-block">
                <Link to="/explore">
                  <Button className="primary">Explore Now</Button>
                </Link>
                {me &&
                  <Link to="/asset/create">
                    <Button className="secondary create">Create</Button>
                  </Link>
                }
              </div>
            </div>
          </div>

          <div className="image-container">
            <CommonNFTSlider/>
          </div>
        </div>
      </div>

      {/* Description Section */}
      <div className="section description">
        <div className="section-wrapper">
          <div className="section title">The amazing digital marketplace for NFTs of the world is here</div>

          <div className="section desc">
            <div className="title">
              <div className="icon wallet" />
              <div className="txt">Fast Transactions</div>
            </div>
            <div className="text">Buy & Sell NFTs in a matter of seconds</div>
          </div>

          <div className="section desc">
            <div className="title">
              <div className="icon graph" />
              <div className="txt">Lowest Fees</div>
            </div>
            <div className="text">Create & Mint NFTs paying no more than $3 USD</div>
          </div>
        </div>
      </div>
    </div>
  );
}

MainLandingPageContent.propTypes = {
  me: PropTypes.object,
};

MainLandingPageContent.defaultProps = {
  me: null,
};

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
  }
);

export default connect(
  mapStateToProps,
  {},
)(MainLandingPageContent);
