import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from "prop-types";

import { useDropzone } from 'react-dropzone';

import './UploadCollectionBanner.css';


function UploadCollectionBanner(props) {
  const {
    setCollectionBanner,
    initialBanner,
  } = props;

  const [currentBanner, setCurrentBanner] = useState(null);

  useEffect(() => {
    if (initialBanner) {
      setCurrentBanner({ preview: initialBanner });
    }
  }, []);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles) {
      setCollectionBanner(acceptedFiles[0]);
      setCurrentBanner(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
      }))[0]);
    }
  }, []);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/png',
    maxFiles: 1,
  });

  return (
    <div className="upload-collection-banner__component">
      <div className="title">Banner image</div>
      <div className="undertext">
        <div>
          Drag or choose your file to upload. This image will appear at the top of your collection page.
        </div>
      </div>
      <div className="dropzone-container">
        <div
          className="dropzone-area"
          style={{ backgroundImage: currentBanner && currentBanner.preview && `url(${currentBanner.preview})` }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          {!currentBanner &&
            <div className="info-block">
              <div className="icon" />
              <div className="text">
                <div>
                  We recommend a 1800x400px JPG or PNG (10MB max size)
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

UploadCollectionBanner.propTypes = {
  setCollectionBanner: PropTypes.func.isRequired,
  setCurrentBanner: PropTypes.func.isRequired,
  currentBanner: PropTypes.object,
  initialBanner: PropTypes.string,
};

UploadCollectionBanner.defaultProps = {
  currentBanner: null,
  initialBanner: null,
};

export default UploadCollectionBanner;
