import React, { useState } from 'react';

import CommonTabList from 'components/common/elements/tabs/CommonTabList';
import CommonTabItem from 'components/common/elements/tabs/CommonTabItem';

import ExploreAssetsList from 'components/main/explore/ExploreAssetsList';
import ExploreCollectionsList from 'components/main/explore/ExploreCollectionsList';

import './ExplorePageContent.css';

function ExplorePageContent() {
  const [activeTab, setActiveTab] = useState('assets');

  return (
    <div className="explore-page-content__component">
      <CommonTabList>
        <CommonTabItem
          active={activeTab === 'assets'}
          tabName="All NFTs"
          onClick={() => { setActiveTab('assets'); }}
        />
        <CommonTabItem
          active={activeTab === 'collections'}
          tabName="Collections"
          onClick={() => { setActiveTab('collections'); }}
        />
      </CommonTabList>

      {activeTab === 'assets' &&
        <ExploreAssetsList />
      }
      {activeTab === 'collections' &&
        <ExploreCollectionsList />
      }

    </div>
  );
}

export default ExplorePageContent;
