import React from 'react';

import MainPageLayout from 'components/common/layouts/MainPageLayout';
import SingleAssetPageContent from 'components/main/assets/single-content/SingleAssetPageContent';

const SingleAssetPage = () => (
  <MainPageLayout>
    <SingleAssetPageContent />
  </MainPageLayout>
);

export default SingleAssetPage;
