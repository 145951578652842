import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchMe,
  patchUser,
  clearPatchUser,
  validateUsername,
  clearValidateUsername,
} from 'redux/users/actions';

import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form } from 'semantic-ui-react';

import Button from 'components/common/elements/buttons/Button';
import TextField from 'components/redux-form-adapters/TextField';
import TextAreaField from 'components/redux-form-adapters/TextAreaField';
import CommonMessage from 'components/common/messages/CommonMessage';
import UpdateProfileAvatar from 'components/main/profile-settings/UpdateProfileAvatar';
import UpdateProfileBanner from 'components/main/profile-settings/UpdateProfileBanner';
import HederaAccountInfo from 'components/main/profile-settings/HederaAccountInfo';

import validate from './validate';
import setInitialData from './setInitialData';

import './ProfileSettingsForm.css';


function ProfileSettingsForm(props) {
  const {
    handleSubmit,
    fetchMe,
    patchUser,
    clearPatchUser,
    validateUsername,
    clearValidateUsername,
    valid,
    patchUserSuccess,
    patchUserError,
    username,
    me,
    walletBalance,
    validateUsernameSuccess,
  } = props;

  const [initialUsername, setInitialUsername] = useState(null);

  const titleValidationError = () => {
    if (validateUsernameSuccess && !validateUsernameSuccess.valid) {
      if (validateUsernameSuccess.suggested_usernames) {
        return `That username is taken. Try another. Available: ${validateUsernameSuccess.suggested_usernames.map(
          (item) => ` ${item}`
        )}`;
      }
      return 'Enter a valid username. This value may contain only letters, numbers and +.- characters.';
    }
    return null;
  }

  useEffect(() => {
    return () => {
      clearValidateUsername();
      clearPatchUser();
    }
  }, []);

  useEffect(() => {
    if (username && !initialUsername) {
      setInitialUsername(username);
    }
  }, [username]);

  useEffect(() => {
    if (patchUserSuccess) {
      fetchMe();
      clearPatchUser();
    }
  }, [patchUserSuccess]);

  const _handleSubmit = values => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });
    patchUser(formData);
  };

  return (
    <div className="profile-settings-form__component">
      <div className="form-wrapper">
        <HederaAccountInfo
          me={me}
          walletBalance={walletBalance}
        />

        {/* Form */}
        <Form
          onSubmit={handleSubmit(_handleSubmit)}
          error={!valid}
        >
          <Field
            label="USERNAME"
            component={TextField}
            placeholder="Enter your username"
            name="username"
            labelPosition="right corner"
            type="text"
            required
            onChange={() => {
              if (validateUsernameSuccess) {
                clearValidateUsername();
              }
            }}
            onBlur={() => {
              if (username && username !== initialUsername) {
                validateUsername(username);
              }
            }}
            serverError={titleValidationError()}
          />

          <Field
            label="BIO"
            component={TextAreaField}
            placeholder="About yourself in a few words"
            name="description"
            labelPosition="right corner"
          />

          <h3>Social Connections</h3>
          <Field
            component={TextField}
            label={<div>YOUR WEBSITE</div>}
            name="site_link"
            placeholder={'https://yoursite.io'}
            type="text"
          />

          <Field
            component={TextField}
            label={<div>INSTAGRAM</div>}
            name="instagram_link"
            prefix='https://www.instagram.com/'
            placeholder='YourInstagramHandle'
            labelPosition='left'
            type="text"  
          />
  
          {/* Errors */}
          {patchUserError &&
            <CommonMessage error errors={patchUserError} />
          }

          <div className="buttons-block">
            <Button
              className="primary"
              submit
              disabled={
                !valid ||
                (validateUsernameSuccess && !validateUsernameSuccess.valid)
              }
            >
              Update Profile
            </Button>
          </div>
        </Form>
      </div>

      <div className="upload-wrapper">
        <UpdateProfileAvatar
          patchUser={patchUser}
          me={me}
        />

        <UpdateProfileBanner
          patchUser={patchUser}
          me={me}
        />
      </div>

    </div>
  );
}

ProfileSettingsForm.propTypes = {
  fetchMe: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  patchUser: PropTypes.func.isRequired,
  clearPatchUser: PropTypes.func.isRequired,
  validateUsername: PropTypes.func.isRequired,
  clearValidateUsername: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  patchUserSuccess: PropTypes.object,
  patchUserError: PropTypes.object,
  me: PropTypes.object,
  walletBalance: PropTypes.object,
  validateUsernameSuccess: PropTypes.object,
};

ProfileSettingsForm.defaultProps = {
  valid: null,
  patchUserSuccess: null,
  patchUserError: null,
  me: null,
  walletBalance: null,
  validateUsernameSuccess: null,
};

const mapStateToProps = state => {
  const selector = formValueSelector('ProfileSettingsForm');
  return {
    username: selector(state, 'username'),
    initialValues: setInitialData(state.usersReducer.me),
    patchUserSuccess: state.usersReducer.patchUserSuccess,
    patchUserError: state.usersReducer.patchUserError,
    me: state.usersReducer.me,
    walletBalance: state.blockchainReducer.walletBalance,
    validateUsernameSuccess: state.usersReducer.validateUsernameSuccess,
  };
};

export default connect(mapStateToProps, {
  fetchMe,
  patchUser,
  clearPatchUser,
  validateUsername,
  clearValidateUsername,
})(reduxForm({
  form: 'ProfileSettingsForm',
  validate,
})(ProfileSettingsForm));
