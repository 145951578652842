import React from 'react';
import ic_check from './icons/ic_check_red.svg';

import './PricingPageContent.css';

function PricingPageContent() {
  return (
    <div className='pricing-page-content__component'>
      <div className="fees-items-wrapper">
        <div className="fees-item">
          <div className="item-title first">
            <div className='title-text '>Fee to Mint NFT</div>
          </div>
          <div className="dividing-line"></div>
          <div className="info-wrapper">
            <div className='first-item-text'>$3 USD </div>
            <div className='first-item-text-under'>per NFT</div>
          </div>
        </div>
        <div className="fees-item">
          <div className="item-title">
            <div className='title-text'>Marketplace Fees</div>
            <div className='title-under'>(for Crypto Transactions)</div>
          </div>
          <div className="dividing-line"></div>
          <div className="info-wrapper">
            <div className='list-item'>
              <div className="icon">
                <img src={ic_check}/>
              </div>
              <div className="text">1% Commission to buy NFT</div>
            </div>
            <div className='list-item'>
              <div className="icon">
                <img src={ic_check}/>
              </div>
              <div className="text">1% Commission to sell NFT*</div>
            </div>
          </div>
        </div>
        <div className="fees-item">
          <div className="item-title">
            <div className='title-text'>Marketplace Fees</div>
            <div className='title-under'>(for Fiat & Card Transactions)</div>
          </div>
          <div className="dividing-line"></div>
          <div className="info-wrapper">
            <div className='list-item'>
              <div className="icon">
                <img src={ic_check}/>
              </div>
              <div className="text">1% Commission to buy NFT</div>
            </div>
            <div className='list-item'>
              <div className="icon">
                <img src={ic_check}/>
              </div>
              <div className="text">
                5.9% + 30c Commission to sell NFT (includes transaction processing fees)*
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="info-text-wrapper">
        <div className="text-with-star">
          * In some cases seller might need to pay additional artist royalties fees, which are included in the price
        </div>
        <div className="text">
          No Hidden Fees
        </div>

      </div>
    </div>
  );
}

export default PricingPageContent;