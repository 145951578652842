import React from 'react';
import './PrivacyPageContent.css';

function PrivacyPageContent() {
  return (
    <div className='privacy-page-content__component'>
      <div>Persollo Pty Ltd (ACN: 607 490 611) (“Persollo” or “HeyMint.me” or “HeyMint” or “we” or “us” or “our”)  recognizes the need to protect the privacy of the personal data you provide to HeyMint.me with respect to your access and use of our website (https://heymint.me/), and related services (collectively, the “Platform”). Therefore, we have adopted this privacy policy (the “Privacy Policy”), which sets forth, among other things, the type of data that will be collected, the purpose and use of the collected data, and your rights with regard to the collected data. This Privacy Policy governs how we collect and use your personal data wherever you use our Platform. By accessing the Platform, you are consenting to the collection and the use of your data by us. Should you wish to revoke your consent, you may do so by contacting us, data on which is set out in the Privacy Policy below. HeyMint.me may make modifications, deletions and/or additions to this Privacy Policy (“Changes”) at any time with or without written notice.</div>

      <h3>Protection of Your data</h3>
      <div>When we collect or use your data, we will utilize commercially reasonable safeguards to ensure its protection. It should be noted that no security procedure is currently 100% effective. Should any breach of your Personal data occur, we will inform you as soon as reasonably possible, as required by applicable law.</div>

      <h3>Data Covered</h3>
      <div>We collect data at various points in the Platform to facilitate its use by our users. The data covered by this policy includes, but is not limited to, data collected via any advertising unit, widget, pixel tag, cookie, script or other data collection process. Specifically, two types of data are collected:</div>
      <ul>
        <li>Non-Personal data: Upon accessing the Platform, certain non-personal data will be automatically collected without your knowledge or consent, such as your IP address, location data and the referring website (“Non-Personal data”). We use Non-Personal data to examine our traffic and to view how our customers use the Platform. This type of data will not allow you to be personally identified although we might be able to associate it with your account.</li>
        <li>Identifying Personal data: If you initiate a transaction through the Platform, we may collect and store data about you, such as your name, phone number, address, email as well as any other data you provide to us (“Personal data“), in order to process your transaction, send communications about them to you, and populate forms for future transactions. This data may be shared with third parties for the same purposes.</li>
      </ul>
      
      <div>Additionally, Personal data may be used and disclosed to the extent HeyMint.me may deem reasonably necessary to enforce the terms of any agreement between you and HeyMint.me, or to protect the rights, property or safety of any person or entity.</div>

      <h3>Cookies and Similar Technologies</h3>
      <div>When you interact with the Platform, we try to make that experience easy, simple and meaningful. When you use our Platform, our web server sends a cookie to your computer or mobile device or tablet device. Cookies are small pieces of data which are issued to your computer or mobile device or tablet device (as the case may be) when you visit a website or access or use a mobile application and which store and sometimes track data about your use of a website or application (as the case may be). A number of cookies we use last only for the duration of your web session or Platform session and expire when you close your browser or exit the Platform. Other cookies are used to remember you when you return to the Platform and will last for longer.</div>

      <div>Some of the cookies used by the Platform is set by us, and some are set by third parties who are delivering services on our behalf. Most web, mobile and tablet device browsers automatically accept cookies but, if you prefer, you can change your browser to prevent that or to notify you each time a cookie is set. You can also learn more about cookies by visiting https://www.allaboutcookies.org/. It includes additional useful information on cookies and how to block cookies using different types of browser or mobile device. Please note, however, that by blocking or deleting cookies used on the Platform, you may not be able to take full advantage of the Platform.</div>

      <h3>Right to Examine data</h3>
      <div>You have the right to examine any of your Personal data that we collect. Should you wish to examine such data, please send us a written request to team@heymint.me. We reserve the right to charge you a reasonable administrative fee to access your data, as permitted by applicable law. In certain cases, we may not be able to provide you with access to all of your Personal data (example: if the data also pertains to the Personal data of another user).</div>

      <h3>Withdrawal of Consent</h3>
      <div>You may withdraw your consent to the collection of Personal data at any time by sending an email to us. Upon receiving notice that you have revoked your consent, we will stop using your Personal data within a reasonable time, which will vary depending on what data we have collected and for what purpose. Please note that we will send you an email confirmation upon receipt of your request. Therefore, if you do not receive a confirmation email, please contact us again with your request. If you do choose to withdraw such consent, your access to the Platform may be diminished, or your ability to choose some of the options on the Platform may be limited. Contact email: team@heymint.me.</div>

      <h3>Sharing data</h3>
      <div>We may share the collected data with other parties as follows:</div>
      <ul>
        <li>Businesses on HeyMint.me: we may share data from or about you (such as your age and gender and how you use the Platform) with businesses listed on the Platform.</li>
        <li>Where required by law: we may share the collected data where required by law, specifically in response to a demand from government authorities where such demand meets the legal requirements.</li>
        <li>Statistical Analysis: we may share Non-Personal data and aggregated data with third parties, including but not limited to for advertising or marketing purposes. No Personal data will be shared in this manner.</li>
        <li>Affiliated Service Providers: we have agreements with various affiliated service providers to facilitate the functioning of the Platform, with whom we may share the data we have collected.</li>
        <li>Transactions: in connection with, or during negotiations of, any merger, sale of company assets, financing or acquisition, or in any other situation where Personal data may be disclosed or transferred as one of our business assets.</li>
      </ul>
      
      <h3>Location-Based Services Consent</h3>
      <div>You hereby expressly consent to our use of location-based services and expressly waive and release HeyMint.me from any and all liability, claims, causes of action or damages arising from your use of HeyMint.me services, including but not limited to use of the Platform, or in any way relating to the use of the precise location and other location-based services.</div>

      <h3>External Links</h3>
      <div>The Platform contains links and references to other websites. We are not responsible for the collection, use and disclosure of data, or the privacy practices of such websites, and we expressly disclaim any liability relating thereto.</div>

      <h3>International Visitors</h3>
      <div>The Platform is hosted in Singapore. If you choose to use the Service from Australia, European Union, or other regions of the world with laws governing data collection and use that may differ from Singapore law, then please note that you are transferring your personal data outside of those regions to the Singapore for storage and processing. Also, we may transfer your data from the Singapore to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating the Platform. By providing any data, including personal data, on or to the Service, you consent to such transfer, storage, and processing.</div>

      <div>By using the Platform, participating in any of our services, and/or providing us with your Personal data, you consent to this transfer. In order to comply with the EU-US Privacy Shield and Swiss-US Privacy Shield frameworks, the Company commits to the resolution of complaints about your privacy and our collection or use of your personal data. In compliance with the US-EU and Swiss-US Privacy Shield Principles, the Company commits to resolve complaints about your privacy and our collection or use of your personal data. European Union or Swiss individuals with inquiries or complaints regarding this privacy policy should first contact HeyMint.me at: team@heymint.me. HeyMint.me has further committed to refer unresolved privacy complaints under the EU-US and Swiss-US Privacy Shield Principles to BBB EU PRIVACY SHIELD, a non-profit alternative dispute resolution provider located in the United States and operated by the Council of Better Business Bureaus. If you do not receive timely acknowledgement of your complaint, or if your complaint is not satisfactorily addressed, please visit https://www.bbb.org/EU-privacy-shield/for-eu-consumers for more information and to file a complaint. Finally, as a last resort and in limited situations, EU and Swiss individuals may seek redress from the Privacy Shield Panel, a binding arbitration mechanism.</div>

      <h3>California Privacy Rights</h3>
      <div>Pursuant to Section 1798.83 of the California Civil Code, if you are a California resident and have an established business relationship with HeyMint.me, you can request a notice disclosing the categories of personal data that HeyMint.me has shared with third parties, for the third parties’ direct marketing purposes, during the preceding calendar year. To request a notice, please submit your request to the address directly below.</div>

      <h3>International Transfer</h3>
      <div>Both Personal data and Non-Personal data you submit via the Platform is sent to our group companies and will processed in Singapore and stored on secure servers located in Singapore. Such data may also be transferred by us to our offices and third parties. The countries concerned may not have similar data protection laws to your country. Where we transfer your data out of your jurisdiction we will take reasonable steps to ensure that your privacy rights continue to be protected. By submitting your data to the Platform, you agree to this storing, processing and transfer.</div>

      <h3>Terms of Service</h3>
      <div>This Privacy Policy is incorporated into and forms part of the Terms of Service, which outlines the terms and conditions you agree to when accessing and using the Platform, and which can be found here: https://heymint.me/terms.</div>

      <h3>Children&apos;s Privacy</h3>
      <div>Protecting the privacy of young children is especially important. Our Platform is not directed to children under the age of 13, and we do not knowingly collect personal data from children under the age of 13 without obtaining parental consent. If you are under 13 years of age, then please do not use or access the Platform at any time or in any manner. If we learn that personally identifiable data has been collected on the Platform from persons under 13 years of age and without verifiable parental consent, then we will take the appropriate steps to delete this data. If you are a parent or guardian and discover that your child under 13 years of age has obtained an account on the Platform, then you may alert us at team@heymint.me and request that we delete that child′s personally identifiable data from our systems.</div>

      <h3>Other Rights</h3>
      <div>Whenever made possible, you can update or request deletion of your personal information. If you are unable to perform these actions yourself, please contact us for assistance using the contact details at the bottom of this Privacy Policy.</div>

      <h3>Our Contact information</h3>
      <div>Please contact us with any questions or comments about this Policy, your personal data, our use and disclosure practices by email at team@heymint.me. Alternatively, please use this page for more information: https://heymint.me/contact.</div>
    </div>
  );
}

export default PrivacyPageContent;