import React from 'react';
import { Helmet } from 'react-helmet';
import MainPageLayout from 'components/common/layouts/MainPageLayout';
import PricingPageContent from 'components/main/info/PricingPageContent';

const PricingPage = () => (
  <MainPageLayout header={'Simple & Fair Pricing'}>
    <Helmet>
      <title>Pricing | HeyMint.me</title>
      <meta property="og:title" content="Pricing | HeyMint.me" />
      <meta
        name="description"
        content="HeyMint.me simple & fair pricing provides full visibility on our fees. Sign up for free today."
      />
      <meta
        property="og:description"
        content="HeyMint.me simple & fair pricing provides full visibility on our fees. Sign up for free today."
      />
    </Helmet>
    <PricingPageContent />
  </MainPageLayout>
);

export default PricingPage;
