import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import './Breadcrambs.css';

function Breadcrambs({ data }) {
  if (!data) return null;

  return (
    <div className="breadcrambs__component">
      {data.map((item, i) => (
        <Fragment key={`breadcrambs-item-key-${i}`}>
          <div className="arrow-block">
            <div className="arrow" />
          </div>
          <Link
            className="link-item"
            to={item.link}
          >
            {item.text}
          </Link>
        </Fragment>
      ))}
    </div>
  );
}

Breadcrambs.propTypes = {
  data: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Breadcrambs;
