import React, { useState } from 'react';
import PropTypes from "prop-types";

import Button from 'components/common/elements/buttons/Button';
import AssetBuyModal from 'components/main/assets/single-content/actions/AssetBuyModal';

import './AssetPriceInfo.css';

function AssetPriceInfo(props) {
  const {
    assetDetails,
    isOwner,
  } = props;

  const [showBuyModal, setShowBuyModal] = useState(false);

  if (!assetDetails) return null;

  const price = assetDetails.price ?
    `${assetDetails.price.price_coin} ${assetDetails.blockchain?.currency_code.toUpperCase()}` : null;

  return (
    <div className="asset-price-info__component">
      {/* Price Block */}
      <div className="price-container">
        Current price:&nbsp;&nbsp;&nbsp;
        <span className={`price${!price ? ' not-listed' : ''}`}>{price || 'Not Listed'}</span>
      </div>

      {/* Buttons Block */}
      <div className="buttons-block">
        {!!assetDetails.price &&
          assetDetails.is_token_listed &&
          <Button
            className="primary"
            disabled={isOwner}
            onClick={() => setShowBuyModal(true)}
          >
            Buy Now
          </Button>
        }

        {/*<Button*/}
        {/*  className="secondary"*/}
        {/*  disabled={isOwner}*/}
        {/*>*/}
        {/*  Make Offer*/}
        {/*</Button>*/}
      </div>

      <AssetBuyModal
        open={showBuyModal}
        onClose={() => setShowBuyModal(false)}
        assetDetails={assetDetails}
      />

    </div>
  );
}

AssetPriceInfo.propTypes = {
  assetDetails: PropTypes.object.isRequired,
  isOwner: PropTypes.bool,
};

AssetPriceInfo.propTypes = {
  isOwner: false,
};

export default AssetPriceInfo;
