import React, { useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchCollectionActivitiesList,
  clearFetchCollectionActivitiesList,
} from 'redux/collections/actions';

import { Link } from 'react-router-dom';

import CommonTable from 'components/common/elements/tables/common-table/CommonTable';
import SegmentLoader from 'components/common/loaders/SegmentLoader';
import CommonMessage from 'components/common/messages/CommonMessage';
import NoItemsPlaceholder from 'components/common/placeholders/NoItemsPlaceholder';

import './CollectionActivitiesList.css';


function CollectionActivitiesList(props) {
  const {
    fetchCollectionActivitiesList,
    clearFetchCollectionActivitiesList,
    collectionDetails,
    collectionActivitiesList,
    collectionActivitiesListError,
  } = props;

  useEffect(() => {
    if (!collectionActivitiesList) {
      fetchCollectionActivitiesList(collectionDetails.slug);
    }
    return () => {
      clearFetchCollectionActivitiesList();
    }
  }, []);

  const renderActivitiesTable = (
    <CommonTable className="mobile-768">
      <CommonTable.Header>
        <CommonTable.Cell></CommonTable.Cell>
        <CommonTable.Cell>Item</CommonTable.Cell>
        <CommonTable.Cell>Quantity</CommonTable.Cell>
        <CommonTable.Cell>Price</CommonTable.Cell>
        <CommonTable.Cell>From</CommonTable.Cell>
        <CommonTable.Cell>To</CommonTable.Cell>
        <CommonTable.Cell>Date</CommonTable.Cell>
      </CommonTable.Header>

      {collectionActivitiesList?.results.map(item => (
        <CommonTable.Row key={`collection-activity-key-${item.id}`}>
          <CommonTable.Cell>
            <div className="parameter"></div>
            <div className="value">{item?.type}</div>
          </CommonTable.Cell>
          <CommonTable.Cell>
            <div className="parameter">Item</div>
            <div className="value">
              {item.item &&
                <Link to={`/asset/${item.item.token_id}/${item.item.serial_number}`}>
                  {item.item.name}
                </Link>
              }
            </div>
          </CommonTable.Cell>
          <CommonTable.Cell>
            <div className="parameter">Quantity</div>
            <div className="value">{item.quantity}</div>
          </CommonTable.Cell>
          <CommonTable.Cell>
            <div className="parameter">Price</div>
            <div className="value">
              {item?.price}&nbsp;
              {item?.currency.toUpperCase()}
            </div>
          </CommonTable.Cell>
          <CommonTable.Cell>
            <div className="parameter">From</div>
            <div className="value">
              {item.sender &&
                <Link to={`/profile/${item.sender}`}>
                  {item.sender}
                </Link>
              }
            </div>
          </CommonTable.Cell>
          <CommonTable.Cell>
            <div className="parameter">To</div>
            <div className="value">
              {item.receiver &&
                <Link to={`/profile/${item.receiver}`}>
                  {item.receiver}
                </Link>
              }
            </div>
          </CommonTable.Cell>
          <CommonTable.Cell>
            <div className="parameter">Date</div>
            <div className="value">{item?.date}</div>
          </CommonTable.Cell>
        </CommonTable.Row>
      ))}

    </CommonTable>
  );

  return (
    <div className="collection-activities-list__component">
      {/* Loader */}
      {!collectionActivitiesList && !collectionActivitiesListError &&
        <SegmentLoader />
      }

      {/* Empty Placeholder */}
      {collectionActivitiesList?.results?.length === 0 &&
        <NoItemsPlaceholder title="No data to display" />
      }

      {/* Activities List */}
      {collectionActivitiesList?.results?.length > 0 &&
        renderActivitiesTable
      }

      {/* Errors */}
      {collectionActivitiesListError &&
        <CommonMessage error errors={collectionActivitiesListError} />
      }
    </div>
  );
}

CollectionActivitiesList.propTypes = {
  fetchCollectionActivitiesList: PropTypes.func.isRequired,
  clearFetchCollectionActivitiesList: PropTypes.func.isRequired,
  collectionDetails: PropTypes.object,
  collectionActivitiesList: PropTypes.arrayOf(PropTypes.element),
  collectionActivitiesListError: PropTypes.object,
};

CollectionActivitiesList.defaultProps = {
  collectionDetails: null,
  collectionActivitiesList: null,
  collectionActivitiesListError: null,
};

const mapStateToProps = state => (
  {
    collectionActivitiesList: state.collectionsReducer.collectionActivitiesList,
    collectionActivitiesListError: state.collectionsReducer.collectionActivitiesListError,
  }
);

export default connect(mapStateToProps, {
  fetchCollectionActivitiesList,
  clearFetchCollectionActivitiesList,
})(CollectionActivitiesList);
