import React from 'react';
import PropTypes from "prop-types";

import { Link } from 'react-router-dom';

import CommonLogoContainer from 'components/common/logos/CommonLogoContainer';

import './CollectionListItem.css';


const CollectionListItem = ({ item }) => (
  <Link
    className="collection-list-item__component"
    to={`/collection/${item.slug}`}
  >
    <div className="image-container">
      <div
        className="banner"
        style={{ backgroundImage: item.banner && `url(${item.banner})` }}
      />
      <CommonLogoContainer
        logoUrl={item.logo}
        type={'collection'}
      />
    </div>

    <div className="info-container">
      <div className="title">
        {item.title}
      </div>
      <div className="divider" />

      <div className="statistics">
        Collection of {item.items} items
      </div>
    </div>
  </Link>
);

CollectionListItem.propTypes = {
  item: PropTypes.object,
};

export default CollectionListItem;
