import React from 'react';
import MainPageLayout from 'components/common/layouts/MainPageLayout';
import { Helmet } from 'react-helmet';
import TermsPageContent from 'components/main/info/TermsPageContent';

const TermsPage = () => (
  <MainPageLayout header={'Terms & Conditions'}>
    <Helmet>
      <title>Terms and Conditions | HeyMint.me</title>
      <meta property="og:title" content="Terms and Conditions | HeyMint.me" />
      <meta
        name="description"
        content="HeyMint.me Terms & Conditions page. Learn more about our terms, conditions and policies."
      />
      <meta
        property="og:description"
        content="HeyMint.me Terms & Conditions page. Learn more about our terms, conditions and policies."
      />
    </Helmet>
    <TermsPageContent/>
  </MainPageLayout>
);

export default TermsPage;
