import React from 'react';
import PropTypes from "prop-types";

import { Dropdown } from 'semantic-ui-react';

import './CommonDropdown.css';

function CommonDropdown(props) {
  const {
    onChange,
    options,
    placeholder,
    clearable,
    multiple,
    selection,
  } = props;

  return (
    <Dropdown
      className="common-dropdown__component"
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      clearable={clearable}
      multiple={multiple}
      selection={selection}
    />
  );
}

CommonDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.element).isRequired,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  multiple: PropTypes.bool,
  selection: PropTypes.bool,
};

CommonDropdown.defaultProps = {
  placeholder: null,
  clearable: null,
  multiple: null,
  selection: null,
};

export default CommonDropdown;
