import React from 'react';
import PropTypes from 'prop-types';

import { connect } from "react-redux";


const RequireUserAccess = ({ children, me, id, type }) => {
  if (type === 'display' && me && me.id === id) {
    return children;
  }

  return null;
};

RequireUserAccess.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
  me: PropTypes.object,
  type: PropTypes.string.isRequired,
};

RequireUserAccess.defaultProps = {
  me: null,
};

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
  }
);

export default connect(
  mapStateToProps,
  null,
)(RequireUserAccess);
