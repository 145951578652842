const setInitialData = data => {
  if (!data) return null;

  return {
    title: data.title,
    description: data.description,
    site_link: data.site_link,
    instagram_link: data.instagram_link.replace('https://instagram.com/', ''),
    discord_link: data.discord_link.replace('https://discord.gg/', ''),
    telegram_link: data.telegram_link.replace('https://t.me/', ''),
    medium_link: data.medium_link.replace('https://medium.com/', ''),
  };
};

export default setInitialData;
