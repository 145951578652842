import React from 'react';
import PropTypes from 'prop-types';

import MainLogo from 'components/common/logos/MainLogo';
import CommonNFTSlider from 'components/common/CommonNFTSlider';

// import { Link } from 'react-router-dom';

import './AuthLayout.css';


function AuthLayout(props) {
  const { children } = props;

  return (
    <div className="auth-layout__component">

      <div className="left-block">
        <div className="header-menu">
          <MainLogo mode="white" />
          <div className="divider">|</div>
          <div className="text">Start creating and selling NFTs</div>
        </div>
        <div className="image-block">
          <CommonNFTSlider/>
        </div>
      </div>

      <div className="right-block">
        <div className="form-container">
          {children}
        </div>
      </div>
    </div>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
  form: PropTypes.string.isRequired,
};

export default AuthLayout;
