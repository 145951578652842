const validate = values => {
  const errors = {};

  if(values && values.site_link && values.site_link.length > 0 && values.site_link.length < 3 ) {
    errors.site_link = 'Your website must be more then 3 symbols';
  }

  if(values && values.instagram_link && values.instagram_link.length > 30) {
    errors.instagram_link = 'Your instagram handle must be less then 30 symbols';
  }

  return errors;
};

export default validate;
