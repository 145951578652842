import {
  CREATE_STRIPE_PAYMENT_INTENT_SUCCESS,
  CREATE_STRIPE_PAYMENT_INTENT_ERROR,
  CLEAR_CREATE_STRIPE_PAYMENT_INTENT,
  CONFIRM_STRIPE_PAYMENT_INTENT_SUCCESS,
  CONFIRM_STRIPE_PAYMENT_INTENT_ERROR,
  CLEAR_CONFIRM_STRIPE_PAYMENT_INTENT,
  FETCH_MOONPAY_URL_SUCCESS,
  FETCH_MOONPAY_URL_ERROR,
  CLEAR_FETCH_MOONPAY_URL,
} from 'redux/billing/types';

const initialState = {
  createPaymentIntentSuccess: null,
  createPaymentIntentError: null,
  confirmPaymentIntentSuccess: null,
  confirmPaymentIntentError: null,
  moonpayUrl: null,
  moonpayUrlError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_STRIPE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        createPaymentIntentSuccess: action.payload.data,
        createPaymentIntentError: null,
      };
    case CREATE_STRIPE_PAYMENT_INTENT_ERROR:
      return {
        ...state,
        createPaymentIntentSuccess: null,
        createPaymentIntentError: action.payload.data,
      };
    case CLEAR_CREATE_STRIPE_PAYMENT_INTENT:
      return {
        ...state,
        createPaymentIntentSuccess: null,
        createPaymentIntentError: null,
      };
    case CONFIRM_STRIPE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        confirmPaymentIntentSuccess: action.payload.data,
        confirmPaymentIntentError: null,
      };
    case CONFIRM_STRIPE_PAYMENT_INTENT_ERROR:
      return {
        ...state,
        confirmPaymentIntentSuccess: null,
        confirmPaymentIntentError: action.payload.data,
      };
    case CLEAR_CONFIRM_STRIPE_PAYMENT_INTENT:
      return {
        ...state,
        confirmPaymentIntentSuccess: null,
        confirmPaymentIntentError: null,
      };
    case FETCH_MOONPAY_URL_SUCCESS:
      return {
        ...state,
        moonpayUrl: action.payload.data,
        moonpayUrlError: null,
      };
    case FETCH_MOONPAY_URL_ERROR:
      return {
        ...state,
        moonpayUrl: null,
        moonpayUrlError: action.payload.data,
      };
    case CLEAR_FETCH_MOONPAY_URL:
      return {
        ...state,
        moonpayUrl: null,
        moonpayUrlError: null,
      };
    default:
      return state;
  }
};
