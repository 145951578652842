import React from 'react';
import PropTypes from "prop-types";

import { connect } from "react-redux";

import ProfileView from 'components/main/profile/ProfileView';


function ProfilePageContent(props) {
  const { me } = props;

  if (!me) return null;

  return (
    <ProfileView profileData={me} />
  );
}

ProfilePageContent.propTypes = {
  me: PropTypes.object,
};

ProfilePageContent.defaultProps = {
  me: null,
};

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
  }
);

export default connect(
  mapStateToProps,
  {},
)(ProfilePageContent);
