import restapi from 'utils/restapi';
import { baseErrorsHandlerDecorator } from 'redux/utils';
import { calculateFiltersPath } from 'redux/assets/utils';

import {
  FETCH_PROFILE_ASSETS_LIST,
  FETCH_PROFILE_ASSETS_LIST_ERROR,
  CLEAR_FETCH_PROFILE_ASSETS_LIST,
  CREATE_ASSET_SUCCESS,
  CREATE_ASSET_ERROR,
  CLEAR_CREATE_ASSET,
  FETCH_ASSET_DETAILS,
  FETCH_ASSET_DETAILS_ERROR,
  CLEAR_FETCH_ASSET_DETAILS,
  FETCH_COLLECTION_ASSETS_LIST,
  FETCH_COLLECTION_ASSETS_LIST_ERROR,
  CLEAR_FETCH_COLLECTION_ASSETS_LIST,
  FETCH_EXPLORE_ASSETS_LIST,
  FETCH_EXPLORE_ASSETS_LIST_ERROR,
  CLEAR_FETCH_EXPLORE_ASSETS_LIST,
  ASSET_LISTING_SUCCESS,
  ASSET_LISTING_ERROR,
  CLEAR_ASSET_LISTING,
  CANCEL_ASSET_LISTING_SUCCESS,
  CANCEL_ASSET_LISTING_ERROR,
  CLEAR_CANCEL_ASSET_LISTING,
  FETCH_ASSET_ACTIVITIES_LIST,
  FETCH_ASSET_ACTIVITIES_LIST_ERROR,
  CLEAR_FETCH_ASSET_ACTIVITIES_LIST,
  BUY_ASSET_SUCCESS,
  BUY_ASSET_ERROR,
  CLEAR_BUY_ASSET,
  HIDE_ASSET_SUCCESS,
  HIDE_ASSET_ERROR,
  CLEAR_HIDE_ASSET,
} from 'redux/assets/types';


export const fetchProfileAssetsList = (
  type,
  username = null,
  pageNumber = 1,
  filters = null,
) => baseErrorsHandlerDecorator(
  FETCH_PROFILE_ASSETS_LIST,
  FETCH_PROFILE_ASSETS_LIST_ERROR,
  () => {
    let path = 'v1/token/';
    if (username) {
      path += `public/${username}/`;
    }
    path += `?page=${pageNumber}`;

    if (type === 'on-sale') {
      path += '&on_sale=true';
    }
    if (type === 'owned') {
      path += '&owner=true';
    }
    if (type === 'hidden') {
      path += '&hidden=true';
    }

    return restapi.get(calculateFiltersPath(path, filters));
  },
  false,
);

export const clearFetchProfileAssetsList= () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_PROFILE_ASSETS_LIST,
  });
};

export const createAsset = formData => baseErrorsHandlerDecorator(
  CREATE_ASSET_SUCCESS,
  CREATE_ASSET_ERROR,
  () => restapi.post('v1/token/', formData),
  false,
  true /* actionSuccess */,
);

export const clearCreateAsset = () => dispatch => {
  dispatch({
    type: CLEAR_CREATE_ASSET,
  });
};

export const fetchAssetDetails = (tokenId, serialNumber) => baseErrorsHandlerDecorator(
  FETCH_ASSET_DETAILS,
  FETCH_ASSET_DETAILS_ERROR,
  () => restapi.get(`v1/token/nft/${tokenId}/${serialNumber}/`),
  false,
);

export const clearFetchAssetDetails = () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_ASSET_DETAILS,
  });
};

export const fetchCollectionAssetsList = (collectionSlug, pageNumber = 1, filters = null) => baseErrorsHandlerDecorator(
  FETCH_COLLECTION_ASSETS_LIST,
  FETCH_COLLECTION_ASSETS_LIST_ERROR,
  () => restapi.get(calculateFiltersPath(
    `v1/token/collection/slug/${collectionSlug}/tokens/?page=${pageNumber}`,
    filters,
  )),
  false,
);

export const clearFetchCollectionAssetsList = () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_COLLECTION_ASSETS_LIST,
  });
};

export const fetchExploreAssetsList = (pageNumber = 1, filters = null) => baseErrorsHandlerDecorator(
  FETCH_EXPLORE_ASSETS_LIST,
  FETCH_EXPLORE_ASSETS_LIST_ERROR,
  () => restapi.get(calculateFiltersPath(
    `v1/token/explore/?page=${pageNumber}`,
    filters,
  )),
  false,
);

export const clearFetchExploreAssetsList = () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_EXPLORE_ASSETS_LIST,
  });
};

export const setAssetListing = (assetId, serialNumber, formData) => baseErrorsHandlerDecorator(
  ASSET_LISTING_SUCCESS,
  ASSET_LISTING_ERROR,
  () => restapi.post(`v1/token/nft/${assetId}/${serialNumber}/listing/`, formData),
  false,
  true /* actionSuccess */,
);

export const clearSetAssetListing = () => dispatch => {
  dispatch({
    type: CLEAR_ASSET_LISTING,
  });
};

export const cancelAssetListing = (assetId, serialNumber) => baseErrorsHandlerDecorator(
  CANCEL_ASSET_LISTING_SUCCESS,
  CANCEL_ASSET_LISTING_ERROR,
  () => restapi.post(`v1/token/nft/${assetId}/${serialNumber}/unlisting/`),
  false,
  true /* actionSuccess */,
);

export const clearCancelAssetListing = () => dispatch => {
  dispatch({
    type: CLEAR_CANCEL_ASSET_LISTING,
  });
};

export const fetchAssetActivitiesList = (
  tokenId,
  serialNumber,
  timestamp = null,
) => baseErrorsHandlerDecorator(
  FETCH_ASSET_ACTIVITIES_LIST,
  FETCH_ASSET_ACTIVITIES_LIST_ERROR,
  () => {
    let path = `v1/token/nft/history/?token_id=${tokenId}&serial_number=${serialNumber}`;
    if (timestamp) {
      path += `&timestamp=${timestamp}`;
    }
    return restapi.get(path);
  },
  false,
);

export const clearFetchAssetActivitiesList= () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_ASSET_ACTIVITIES_LIST,
  });
};

export const buyAsset = formData => baseErrorsHandlerDecorator(
  BUY_ASSET_SUCCESS,
  BUY_ASSET_ERROR,
  () => restapi.post('v1/token/nft/buy/', formData),
  false,
  true /* actionSuccess */,
);

export const clearBuyAsset = () => dispatch => {
  dispatch({
    type: CLEAR_BUY_ASSET,
  });
};

export const hideAsset = (tokenId, serialNumber, isPublished) => baseErrorsHandlerDecorator(
  HIDE_ASSET_SUCCESS,
  HIDE_ASSET_ERROR,
  () => restapi.post(`v1/token/nft/${tokenId}/${serialNumber}/hide/`, { is_published: isPublished }),
  false,
  true /* actionSuccess */,
);

export const clearHideAsset = () => dispatch => {
  dispatch({
    type: CLEAR_HIDE_ASSET,
  });
};
