import React from 'react';

import { useParams } from "react-router-dom";

import PublicProfilePageContent from 'components/main/profile/PublicProfilePageContent';

const PublicProfilePage = () => {
  const { username } = useParams();

  return (
    <PublicProfilePageContent username={username} />
  );
};

export default PublicProfilePage;
