import React from 'react';

import { Helmet } from 'react-helmet';

import AuthLayout from 'components/common/layouts/AuthLayout';
import SignupForm from 'components/authentication/signup/SignupForm';
import RegistrationSuccess from 'components/authentication/signup/RegistrationSuccess';

const SignupPage = () => (
  <AuthLayout form="signup">
    <Helmet>
      <title>First Hedera NFT Marketplace | HeyMint.me</title>
      <meta property="og:title" content="First Hedera NFT Marketplace | HeyMint.me" />
      <meta
        name="description"
        content="HeyLink.me Sign Up page. The amazing digital marketplace for NFTs of the world is here."
      />
      <meta
        property="og:description"
        content="HeyLink.me Sign Up page. The amazing digital marketplace for NFTs of the world is here."
      />
    </Helmet>
    <SignupForm />
    <RegistrationSuccess />
  </AuthLayout>
);

export default SignupPage;
