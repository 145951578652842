const setInitialData = data => {
  if (!data) return null;

  return {
    username: data.username,
    description: data.description,
    email: data.email,
    site_link: data.site_link,
    instagram_link: data.instagram_link.replace('https://instagram.com/', ''),
  };
};

export default setInitialData;
