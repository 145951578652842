import {
  FETCH_GLOBAL_SEARCH_DATA,
  FETCH_GLOBAL_SEARCH_DATA_ERROR,
  CLEAR_GLOBAL_SEARCH_DATA,
  SEND_CONTACT_MESSAGE_SUCCESS,
  SEND_CONTACT_MESSAGE_ERROR,
  CLEAR_SEND_CONTACT_MESSAGE,
} from 'redux/tools/types';

const initialState = {
  globalSearchData: null,
  globalSearchDataError: null,
  sendContactMessageSuccess: null,
  sendContactMessageError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GLOBAL_SEARCH_DATA:
      return {
        ...state,
        globalSearchData: action.payload.data,
        globalSearchDataError: null,
      };
    case FETCH_GLOBAL_SEARCH_DATA_ERROR:
      return {
        ...state,
        globalSearchData: null,
        globalSearchDataError: action.payload.data,
      };
    case CLEAR_GLOBAL_SEARCH_DATA:
      return {
        ...state,
        globalSearchData: null,
        globalSearchDataError: null,
      };
    case SEND_CONTACT_MESSAGE_SUCCESS:
      return {
        ...state,
        sendContactMessageSuccess: action.payload.data,
        sendContactMessageError: null,
      };
    case SEND_CONTACT_MESSAGE_ERROR:
      return {
        ...state,
        sendContactMessageSuccess: null,
        sendContactMessageError: action.payload.data,
      };
    case CLEAR_SEND_CONTACT_MESSAGE:
      return {
        ...state,
        sendContactMessageSuccess: null,
        sendContactMessageError: null,
      };
    default:
      return state;
  }
};
