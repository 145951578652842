import React, { useState } from 'react';
import PropTypes from "prop-types";

import { Link } from 'react-router-dom';

import CommonTabList from 'components/common/elements/tabs/CommonTabList';
import CommonTabItem from 'components/common/elements/tabs/CommonTabItem';

import CommonLogoContainer from 'components/common/logos/CommonLogoContainer';
import CollectionAssetsList from 'components/main/collections/single-content/CollectionAssetsList';
import CollectionActivitiesList from 'components/main/collections/single-content/CollectionActivitiesList';

import { modifyPrice } from 'utils/common';
import ic_site from './icons/icon_web.svg';
import ic_discord from './icons/icon_discord.svg';
import ic_instagram from './icons/icon_instagram.svg';
import ic_telegram from './icons/icon_telegram.svg';
import ic_medium from './icons/icon_medium.svg';

import './CollectionDetails.css';


function CollectionDetails(props) {
  const { collectionDetails } = props;

  const [activeTab, setActiveTab] = useState('items');

  const calculateFloorPrice = () => {
    const price = collectionDetails.stats.floor_price;
    if (!price) return '-';
    return `${modifyPrice(Number(price).toFixed(2))}`;
  };

  const calculateVolume = () => {
    const volume = collectionDetails.stats.volume_traded;
    if (!volume) return '-';
    return `${modifyPrice(Number(volume).toFixed(2))}`;
  };

  const socialIconsArray = [
    {
      available: collectionDetails?.site_link,
      icon: ic_site,
    },
    {
      available: collectionDetails?.discord_link,
      icon: ic_discord,
    },
    {
      available: collectionDetails?.instagram_link,
      icon: ic_instagram,
    },
    {
      available: collectionDetails?.telegram_link,
      icon: ic_telegram,
    },
    {
      available: collectionDetails?.medium_link,
      icon: ic_medium,
    },
  ];

  const renderSocialIcons = () => (
    <div className="social-icons-wrapper">
      {socialIconsArray.map((item, index) => (
        item.available && 
        <a 
          key={`collection-details-social-icon-${index}`}
          href={item.available} 
          className='social-icon'
          target='_blank' 
          rel='noreferrer'
        >
          <img src={item.icon}/>
        </a>
      ))}
    </div>
  );

  return (
    <div className="collection-details__component">
      {/* Header */}
      <div
        className="header-block"
        style={{ backgroundImage: collectionDetails.banner && `url(${collectionDetails.banner})` }}
      >
        <div className="logo-container">
          <CommonLogoContainer
            logoUrl={collectionDetails.logo}
            type={'collection'}
          />
        </div>
      </div>

      {/* Info */}
      <div className="info-block">
        <div className="title">{collectionDetails.title}</div>
        <div className="token-id">
          {collectionDetails?.token_id}
        </div>
        <div className="created">
          Created by&nbsp;
          <Link to={`/profile/${collectionDetails.created_by}`}>
            {collectionDetails.created_by}
          </Link>
        </div>
        {renderSocialIcons()}
        <div className="stats">
          <div className="stats-item">
            <div className="value">
              {collectionDetails.stats.items}
            </div>
            <div className="text">items</div>
          </div>
          <div className="stats-item">
            <div className="value">
              {collectionDetails.stats.owners}
            </div>
            <div className="text">owners</div>
          </div>
          <div className="stats-item">
            <div className="value">
              {calculateFloorPrice()}
            </div>
            <div className="text">floor price</div>
          </div>
          <div className="stats-item">
            <div className="value">
              {calculateVolume()}
            </div>
            <div className="text">volume</div>
          </div>
        </div>
        {collectionDetails.description &&
          <div className="description">
            {collectionDetails.description}
          </div>
        }
      </div>

      {/* Tabs */}
      <div className="tabs-container">
        <CommonTabList center>
          <CommonTabItem
            active={activeTab === 'items'}
            tabName="Items"
            onClick={() => { setActiveTab('items'); }}
          />
          <CommonTabItem
            active={activeTab === 'activity'}
            tabName="Activity"
            onClick={() => { setActiveTab('activity'); }}
          />
        </CommonTabList>
      </div>

      {/* Content Block */}
      <div className="content-block">
        {activeTab === 'items' &&
          <CollectionAssetsList collectionDetails={collectionDetails} />
        }

        {activeTab === 'activity' &&
          <CollectionActivitiesList collectionDetails={collectionDetails} />
        }
      </div>

    </div>
  );
}

CollectionDetails.propTypes = {
  collectionDetails: PropTypes.object,
};

CollectionDetails.defaultProps = {
  collectionDetails: null,
};

export default CollectionDetails;
