import React, { useState } from 'react';
import PropTypes from "prop-types";

import { CopyToClipboard } from 'react-copy-to-clipboard';

import FixedNotification
  from 'components/common/elements/notifications/FixedNotification';

import './ProfileWallet.css';


function ProfileWallet(props) {
  const { profileData } = props;

  const [showFixed, setShowFixed] = useState(false);

  return (
    <CopyToClipboard
      text={profileData.wallet}
      onCopy={() => setShowFixed(true)}
    >
      <div className="profile-wallet__component">
        <div className="text">{profileData.wallet}</div>
        <div className="copy-icon" />
        {showFixed &&
          <FixedNotification
            onFadeComplete={() => setShowFixed(false)}
          >
            Account id has been copied!
          </FixedNotification>
        }
      </div>
    </CopyToClipboard>
  );
}

ProfileWallet.propTypes = {
  profileData: PropTypes.object.isRequired,
};

export default ProfileWallet;
