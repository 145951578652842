import React from 'react';

import { Helmet } from 'react-helmet';

import AuthLayout from 'components/common/layouts/AuthLayout';
import ResetPasswordForm from 'components/authentication/password-recovery/ResetPasswordForm';


const ResetPasswordPage = () => (
  <AuthLayout form="reset-password">
    <Helmet>
      <title>First Hedera NFT Marketplace | HeyMint.me</title>
      <meta property="og:title" content="First Hedera NFT Marketplace | HeyMint.me" />
      <meta
        name="description"
        content="HeyLink.me Password Recovery page. The amazing digital marketplace for NFTs of the world is here."
      />
      <meta
        property="og:description"
        content="HeyLink.me Password Recovery page. The amazing digital marketplace for NFTs of the world is here."
      />
    </Helmet>
    <ResetPasswordForm />
  </AuthLayout>
);

export default ResetPasswordPage;
