import React, { useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchExploreCollectionsList,
  clearFetchExploreCollectionsList,
} from 'redux/collections/actions';

import CollectionsList from 'components/main/collections/common/CollectionsList';


function ExploreCollectionsList(props) {
  const {
    fetchExploreCollectionsList,
    clearFetchExploreCollectionsList,
    exploreCollectionsList,
    exploreCollectionsListError,
  } = props;

  useEffect(() => {
    fetchExploreCollectionsList();
    return () => {
      clearFetchExploreCollectionsList();
    }
  }, []);

  return (
    <div className="explore-collections-list__component">
      {/* List */}
      <CollectionsList
        currentList={exploreCollectionsList}
        currentListError={exploreCollectionsListError}
        loadFunction={pageNumber => fetchExploreCollectionsList(pageNumber)}
      />
    </div>
  );
}

ExploreCollectionsList.propTypes = {
  fetchExploreCollectionsList: PropTypes.func.isRequired,
  clearFetchExploreCollectionsList: PropTypes.func.isRequired,
  exploreCollectionsList: PropTypes.arrayOf(PropTypes.element),
  exploreCollectionsListError: PropTypes.object,
};

ExploreCollectionsList.defaultProps = {
  exploreCollectionsList: null,
  exploreCollectionsListError: null,
};

const mapStateToProps = state => (
  {
    exploreCollectionsList: state.collectionsReducer.exploreCollectionsList,
    exploreCollectionsListError: state.collectionsReducer.exploreCollectionsListError,
  }
);

export default connect(mapStateToProps, {
  fetchExploreCollectionsList,
  clearFetchExploreCollectionsList,
})(ExploreCollectionsList);
