import React from 'react';
import {Carousel} from '3d-react-carousal';

import img1 from './images/1-pict.webp';
import img2 from './images/2-pict.webp';
import img3 from './images/3-pict.webp';


function CommonNFTSlider() {
  const arrNFT = [
    <img src={img1} className="image" key={`nft-image-1`}/>,
    <img src={img2} className="image" key={`nft-image-2`}/>,
    <img src={img3} className="image" key={`nft-image-3`}/>,
  ];

  return (
    <div className="common-nft-slider__component">
      <Carousel slides={arrNFT} autoplay={true} arrows={false}/>
    </div>
  );
}

export default CommonNFTSlider;
