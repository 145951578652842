import React from 'react';
import PropTypes from 'prop-types';

import { connect } from "react-redux";

import { useLocation, Navigate } from 'react-router-dom';

import { getCookie } from 'utils/cookies';

const RequireAuth = ({ children, me, meError }) => {
  const location = useLocation();

  if ((!me && !getCookie('accessToken')) || meError) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

RequireAuth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
  me: PropTypes.object,
  meError: PropTypes.object,
};

RequireAuth.defaultProps = {
  me: null,
  meError: null,
};

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
    meError: state.usersReducer.meError,
  }
);

export default connect(
  mapStateToProps,
  null,
)(RequireAuth);
