import React, { useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import { clearCreateCollection } from 'redux/collections/actions';

import { Link, useNavigate } from "react-router-dom";

import CreateCollectionForm from 'components/main/collections/create-content/CreateCollectionForm';
import CommonMessage from 'components/common/messages/CommonMessage';

import './CreateCollectionPageContent.css';


function CreateCollectionPageContent(props) {
  const {
    clearCreateCollection,
    createCollectionSuccess,
    me,
  } = props;

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      clearCreateCollection();
    };
  }, []);

  useEffect(() => {
    if (createCollectionSuccess) {
      navigate(`/collection/${createCollectionSuccess.slug}`);
    }
  }, [createCollectionSuccess]);

  if (me && !me.wallet) {
    return (
      <CommonMessage warning>
        In order to create collection, you need to create a wallet in&nbsp;
        <Link to={'/profile/settings'}>Profile settings</Link>
      </CommonMessage>
    );
  }

  return (
    <div className="create-collection-page-content__component">
      <CreateCollectionForm />
    </div>
  );
}

CreateCollectionPageContent.propTypes = {
  clearCreateCollection: PropTypes.func.isRequired,
  createCollectionSuccess: PropTypes.object,
  me: PropTypes.object,
};

CreateCollectionPageContent.defaultProps = {
  me: null,
};

const mapStateToProps = state => (
  {
    createCollectionSuccess: state.collectionsReducer.createCollectionSuccess,
    me: state.usersReducer.me,
  }
);

export default connect(mapStateToProps, {
  clearCreateCollection,
})(CreateCollectionPageContent);
