import React, { useState } from 'react';
import PropTypes from "prop-types";

import CommonSection from 'components/common/sections/CommonSection';
import Button from 'components/common/elements/buttons/Button';
import CommonMessage from 'components/common/messages/CommonMessage';
import ConnectHederaAccountModal from 'components/main/profile-settings/ConnectHederaAccountModal';
import MoonpayUpdateBalanceModal from 'components/main/profile-settings/moonpay/MoonpayUpdateBalanceModal';

import './HederaAccountInfo.css';

function HederaAccountInfo(props) {
  const {
    me,
    walletBalance,
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [showMoonpayModal, setShowMoonpayModal] = useState(false);

  const isConnected = me.wallet;

  return (
    <CommonSection className="hedera-account-info__component">
      {!isConnected &&
        <CommonMessage warning>
          You need a wallet to store your NFTs
        </CommonMessage>
      }

      <div className="title-block">
        <div className="icon" />
        <div className="title">
          <div>Hedera Wallet</div>
          {isConnected &&
            <div className="wallet">{me.wallet}</div>
          }
        </div>
      </div>

      {isConnected && walletBalance &&
        <div className="wallet-balance">
          Balance:&nbsp;&nbsp;&nbsp;
          <span>{Number(walletBalance?.balance)} {walletBalance?.currency_code.toUpperCase()}</span>
        </div>
      }

      {isConnected ?
        <div className="status connected">
          <div className="icon" />
          <div className="status-text">Connected</div>
        </div>
        :
        <div className="status not-connected">
          <div className="icon" />
          <div className="status-text">Not Connected</div>
        </div>
      }

      {!isConnected &&
        <Button
          className="primary small"
          onClick={() => setShowModal(true)}
        >
          Connect
        </Button>
      }

      {isConnected &&
        <Button
          className="secondary small"
          onClick={() => setShowMoonpayModal(true)}
        >
          Add HBAR with card
        </Button>
      }

      <ConnectHederaAccountModal
        open={showModal}
        onClose={() => setShowModal(false)}
        size={'tiny'}
      />

      <MoonpayUpdateBalanceModal
        open={showMoonpayModal}
        onClose={() => setShowMoonpayModal(false)}
      />

    </CommonSection>
  );
}

HederaAccountInfo.propTypes = {
  me: PropTypes.object.isRequired,
  walletBalance: PropTypes.object,
};

HederaAccountInfo.defaultProps = {
  walletBalance: null,
};

export default HederaAccountInfo;

