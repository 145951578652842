import React, { Component } from 'react';
import PropTypes from 'prop-types';

class OutsideHandler extends Component {
  static propTypes = {
    handleClickOutside: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
  };

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.props.handleClickOutside();
    }
  }

  render() {
    return (
      <div ref={this.wrapperRef}>
        {this.props.children}
      </div>
    );
  }
}

export default OutsideHandler;
