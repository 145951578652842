import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  registrateUser,
  retrieveToken,
  clearRegistrateUser,
  validateUsername,
  clearValidateUsername,
  validateEmail,
  clearValidateEmail,
} from 'redux/users/actions';

import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form } from 'semantic-ui-react';

import { Link, Navigate } from 'react-router-dom';

import Button from 'components/common/elements/buttons/Button';
import InputTextField from 'components/redux-form-adapters/InputTextField';
import CommonMessage from 'components/common/messages/CommonMessage';
import CommonLoader from 'components/common/loaders/CommonLoader';

import validate from './validate';

import './SignupForm.css';


function SignupForm(props) {
  const {
    registrateUser,
    retrieveToken,
    clearRegistrateUser,
    validateUsername,
    clearValidateUsername,
    validateEmail,
    clearValidateEmail,
    handleSubmit,
    valid,
    username,
    email,
    password,
    registrateUserSuccess,
    registrateUserError,
    validateUsernameSuccess,
    validateEmailSuccess,
    me,
  } = props;

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    return () => {
      clearRegistrateUser();
      clearValidateUsername();
      clearValidateEmail();
      setShowLoader(false);
    };
  }, []);

  useEffect(() => {
    if (email && validateEmailSuccess) {
      validateEmail(email);
    }
  }, [email]);

  useEffect(() => {
    if (username && validateUsernameSuccess) {
      validateUsername(username);
    }
  }, [username]);

  useEffect(() => {
    if (registrateUserSuccess) {
      retrieveToken(email.toLowerCase(), password);
    }
  }, [registrateUserSuccess]);


  if (me && !registrateUserSuccess) {
    return <Navigate to="/explore" />
  }

  const _handleSubmit = () => {
    if (
      (validateEmailSuccess && !validateEmailSuccess.valid) ||
      (validateUsernameSuccess && !validateUsernameSuccess.valid)
    ) {
      return;
    }
    registrateUser(email, username, password);
    clearValidateUsername();
    clearValidateEmail();
  };

  if (registrateUserSuccess) return null;

  return (
    <div className="signup-form__component">
      <CommonLoader active={!!showLoader} text={'Account registration...'} />

      <h1>Create Account</h1>

      {/* Form */}
      <div className="form-wrapper">
        <Form
          onSubmit={handleSubmit(_handleSubmit)}
          error={!valid}
        >

          <Field
            label="Email Address"
            component={InputTextField}
            name="email"
            type="email"
            required
            showCheckIcon
            onBlur={() => validateEmail(email)}
            serverError={
              validateEmailSuccess &&
              !validateEmailSuccess.valid &&
              'Oops... user with this email address already exists'
            }
          />

          <Field
            label="Username"
            component={InputTextField}
            name="username"
            type="text"
            required
            showCheckIcon
            onBlur={() => validateUsername(username)}
            serverError={
              validateUsernameSuccess &&
              !validateUsernameSuccess.valid &&
              validateUsernameSuccess.suggested_usernames &&
              `That username is taken. Try another. Available: ${validateUsernameSuccess.suggested_usernames.map(
                (item) => ` ${item}`
              )}`
            }
            prefix='heymint.me/'
          />

          <Field
            label="Password"
            component={InputTextField}
            name="password1"
            type="password"
            required
            showCheckIcon
          />

          <Button className="primary" submit>
            Sign Up
          </Button>
        </Form>

        <div className="underform-text">
          Already have an account yet?&nbsp;&nbsp;&nbsp;<Link to="/login">Log In</Link>
        </div>

        {registrateUserError &&
          <CommonMessage error errors={registrateUserError} />
        }

        <div className="disclaimer">
          By signing up you agree to HeyMint.me <a href='/terms'>Terms</a> & <a href='/privacy'>Privacy</a>
        </div>
      </div>
    </div>
  );
}

SignupForm.propTypes = {
  retrieveToken: PropTypes.func.isRequired,
  registrateUser: PropTypes.func.isRequired,
  clearRegistrateUser: PropTypes.func.isRequired,
  clearValidateUsername: PropTypes.func.isRequired,
  validateEmail: PropTypes.func.isRequired,
  clearValidateEmail: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  username: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  registrateUserSuccess: PropTypes.object,
  registrateUserError: PropTypes.object,
  validateUsername: PropTypes.func.isRequired,
  validateUsernameSuccess: PropTypes.object,
  validateEmailSuccess: PropTypes.object,
  me: PropTypes.object,
};

SignupForm.defaultProps = {
  valid: false,
  username: null,
  email: null,
  password: null,
  registrateUserSuccess: null,
  registrateUserError: null,
  validateUsernameSuccess: null,
  validateEmailSuccess: null,
  me: null,
};

const mapStateToProps = (state) => {
  const selector = formValueSelector('SignupForm');
  return {
    email: selector(state, 'email'),
    username: selector(state, 'username'),
    password: selector(state, 'password1'),
    registrateUserSuccess: state.usersReducer.registrateUserSuccess,
    registrateUserError: state.usersReducer.registrateUserError,
    validateUsernameSuccess: state.usersReducer.validateUsernameSuccess,
    validateEmailSuccess: state.usersReducer.validateEmailSuccess,
    me: state.usersReducer.me,
  };
};

export default connect(
  mapStateToProps,
  {
    registrateUser,
    retrieveToken,
    clearRegistrateUser,
    validateUsername,
    clearValidateUsername,
    validateEmail,
    clearValidateEmail,
  },
)(reduxForm({
  form: 'SignupForm',
  validate,
})(SignupForm));
