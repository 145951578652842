const validate = values => {
  const errors = {};
  
  if (!values.title) {
    errors.title = 'Title is required';
  }

  if (!values.max_supply) {
    errors.max_supply = 'Max supply is required';
  } else if (isNaN(values.max_supply)) {
    errors.max_supply = 'Max supply must be a number';
  } else if (Number(values.max_supply) < 0 || Number(values.max_supply) === 0) {
    errors.max_supply = 'Max supply must be more than 0';
  } else if (!Number.isInteger(Number(values.max_supply))) {
    errors.max_supply = 'Max supply must be integer';
  }

  if(values && values.site_link && values.site_link.length > 0 && values.site_link.length < 3 ) {
    errors.site_link = 'Your website must be more then 3 symbols';
  }

  if(values && values.instagram_link && values.instagram_link.length > 30) {
    errors.instagram_link = 'Your instagram handle must be less then 30 symbols';
  }

  return errors;
};

export default validate;
