import React from 'react';
import PropTypes from 'prop-types';

import { Input, Form } from 'semantic-ui-react';

import './TextField.css';


const TextField = props => {
  const {
    input,
    label,
    type,
    placeholder,
    disabled,
    labelPosition,
    prefix,
    serverError,
    meta: { touched, error },
  } = props;

  if (!('id' in input)) {
    input.id = `id-${input.name}`;
  }

  return (
    <Form.Field
      className="text-field__component"
      error={Boolean(touched && (error || serverError))}
    >
      {label &&
        <div className="label">{label}</div>
      }

      <Input
        {...input}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        labelPosition={labelPosition}
        label={prefix}
      />

      {touched && (error || serverError) &&
        <div className="errors">
          {error || serverError}
        </div>
      }
    </Form.Field>
  );
};

TextField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  labelPosition: PropTypes.string,
  prefix: PropTypes.string,
  serverError: PropTypes.string,
};

TextField.defaultProps = {
  label: null,
  placeholder: null,
  disabled: false,
  serverError: null,
};

export default TextField;
