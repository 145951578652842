import React from 'react';

import RequireAuth from 'hoc/RequireAuth';
import MainPageLayout from 'components/common/layouts/MainPageLayout';
import CreateCollectionPageContent from 'components/main/collections/create-content/CreateCollectionPageContent';

const breadcrambs = [
  {
    text: 'My Collections',
    link: "/collections",
  },
  {
    text: 'Mint New Collection',
    link: "#",
  },
];

const CreateCollectionPage = () => (
  <RequireAuth>
    <MainPageLayout
      breadcrambs={breadcrambs}
      header="Mint New Collection"
    >
      <CreateCollectionPageContent />
    </MainPageLayout>
  </RequireAuth>
);

export default CreateCollectionPage;
