import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { resetPassword, clearResetPassword } from 'redux/users/actions';

import { Link, useParams } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';

import Button from 'components/common/elements/buttons/Button';
import InputTextField from 'components/redux-form-adapters/InputTextField';
import CommonMessage from 'components/common/messages/CommonMessage';

import validate from './validate';

import './ResetPasswordForm.css';


function ResetPasswordForm(props) {
  const {
    handleSubmit,
    resetPassword,
    clearResetPassword,
    resetPasswordSuccess,
    resetPasswordError,
    valid,
  } = props;

  const params = useParams();
  const confirmationKey = params.key;

  useEffect(() => {
    return () => {
      clearResetPassword();
    };
  }, []);

  const _handleSubmit = values => {
    resetPassword(
      confirmationKey,
      values.password1,
      values.password2,
    );
  };

  if (!confirmationKey) return null;

  return (
    <div className="reset-password-form__component">
      <h1>Reset Password</h1>

      {!resetPasswordSuccess &&
        <Form
          onSubmit={handleSubmit(_handleSubmit)}
          error={!valid}
        >
          {/* Password 1 */}
          <Field
            label="New Password"
            component={InputTextField}
            name="password1"
            type="password"
            required
          />

          {/* Password 2 */}
          <Field
            label="Confirm Password"
            component={InputTextField}
            name="password2"
            type="password"
            required
          />

          <Button
            className="primary"
            submit
            disabled={!valid}
          >
            Reset Password
          </Button>
        </Form>
      }

      {resetPasswordSuccess &&
        <CommonMessage success>
          <div>Password has been changed!</div>
          <Link to="/login">Login</Link>
        </CommonMessage>
      }

      {resetPasswordError &&
        <CommonMessage
          error
          errors={resetPasswordError}
        />
      }
    </div>
  );
}

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  clearResetPassword: PropTypes.func.isRequired,
  resetPasswordSuccess: PropTypes.object,
  resetPasswordError: PropTypes.object,
  valid: PropTypes.bool,
};

ResetPasswordForm.defaultProps = {
  resetPasswordSuccess: null,
  resetPasswordError: null,
  valid: false,
};

const mapStateToProps = state => (
  {
    resetPasswordSuccess: state.usersReducer.resetPasswordSuccess,
    resetPasswordError: state.usersReducer.resetPasswordError,
  }
);

export default connect(
  mapStateToProps,
  {
    resetPassword,
    clearResetPassword,
  },
)(reduxForm({
  form: 'ResetPasswordForm',
  validate,
})(ResetPasswordForm));
