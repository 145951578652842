import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { fetchProjectSettings } from 'redux/common/actions';
import { fetchWalletBalance } from 'redux/blockchain/actions';

function MainAppContainer(props) {
  const {
    fetchProjectSettings,
    fetchWalletBalance,
    children,
    me,
  } = props;

  useEffect(() => {
    if (me?.wallet) {
      fetchWalletBalance();
      fetchProjectSettings();
    }
  }, [me]);

  return children;
}

MainAppContainer.propTypes = {
  fetchProjectSettings: PropTypes.func.isRequired,
  fetchWalletBalance: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
  me: PropTypes.object,
};

MainAppContainer.defaultProps = {
  me: null,
};

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
  }
);

export default connect(mapStateToProps, {
  fetchProjectSettings,
  fetchWalletBalance,
})(MainAppContainer);
