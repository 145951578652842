import React from 'react';
import PropTypes from "prop-types";

import './MoonpayFrame.css';

function MoonpayFrame(props) {
  const { frameUrl } = props;

  return (
    <div className="moonpay-frame__component">
      <iframe
        src={frameUrl}
        frameBorder={0}
      />
    </div>
  );
}

MoonpayFrame.propTypes = {
  frameUrl: PropTypes.string.isRequired,
};

export default MoonpayFrame;
