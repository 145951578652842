import restapi from 'utils/restapi';
import { baseErrorsHandlerDecorator } from 'redux/utils';

import {
  FETCH_BLOCKCHAINS_LIST,
  FETCH_BLOCKCHAINS_LIST_ERROR,
  CREATE_HEDERA_ACCOUNT_SUCCESS,
  CREATE_HEDERA_ACCOUNT_ERROR,
  CLEAR_CREATE_HEDERA_ACCOUNT,
  FETCH_WALLET_BALANCE,
  FETCH_WALLET_BALANCE_ERROR,
  CLEAR_FETCH_WALLET_BALANCE,
  GENERATE_MNEMONIC_SUCCESS,
  GENERATE_MNEMONIC_ERROR,
  CLEAR_GENERATE_MNEMONIC,
} from 'redux/blockchain/types';


export const fetchBlockchainsList = () => baseErrorsHandlerDecorator(
  FETCH_BLOCKCHAINS_LIST,
  FETCH_BLOCKCHAINS_LIST_ERROR,
  () => restapi.get('v1/blockchain/'),
  false,
);

export const generateHederaMnemonic = blockchain => baseErrorsHandlerDecorator(
  GENERATE_MNEMONIC_SUCCESS,
  GENERATE_MNEMONIC_ERROR,
  () => restapi.post('v1/blockchain/wallet/generate-mnemonic/', { blockchain }),
  false,
  true /* actionSuccess */,
);

export const clearGenerateHederaMnemonic = () => dispatch => {
  dispatch({
    type: CLEAR_GENERATE_MNEMONIC,
  });
};

export const createHederaAccount = (blockchain, mnemonic_words = null) => baseErrorsHandlerDecorator(
  CREATE_HEDERA_ACCOUNT_SUCCESS,
  CREATE_HEDERA_ACCOUNT_ERROR,
  () => restapi.post('v1/blockchain/wallet/', { blockchain, mnemonic_words }),
  false,
  true /* actionSuccess */,
);

export const clearCreateHederaAccount = () => dispatch => {
  dispatch({
    type: CLEAR_CREATE_HEDERA_ACCOUNT,
  });
};

export const fetchWalletBalance = () => baseErrorsHandlerDecorator(
  FETCH_WALLET_BALANCE,
  FETCH_WALLET_BALANCE_ERROR,
  () => restapi.get('v1/blockchain/wallet/balance/'),
  false,
);

export const clearFetchWalletBalance = () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_WALLET_BALANCE,
  });
};
