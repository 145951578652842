const validate = values => {
  const errors = {};

  if (!values.price) {
    errors.price = 'Amount is required';
  } else if (isNaN(values.price)) {
    errors.price = 'Amount must be a number';
  } else if (Number(values.price) < 0 || Number(values.price) === 0) {
    errors.price = 'Amount must be more than 0';
  }

  return errors;
};

export default validate;
