import React from 'react';
import PropTypes from "prop-types";

import { Loader, Segment } from 'semantic-ui-react';

import './SegmentLoader.css';

function SegmentLoader(props) {
  const { text } = props;

  return (
    <Segment className="segment-loader__component">
      <Loader active>
        {text}
      </Loader>
    </Segment>
  );
}

SegmentLoader.propTypes = {
  text: PropTypes.string,
};

SegmentLoader.defaultProps = {
  text: null,
};

export default SegmentLoader;
