import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { logout } from 'redux/users/actions';

import { Link } from 'react-router-dom';

import CommonLogoContainer from 'components/common/logos/CommonLogoContainer';
import OutsideHandler from 'components/common/handlers/OutsideHandler';

import ProfileWallet from 'components/main/profile/ProfileWallet';

import './MyAccount.css';


function MyAccount(props) {
  const {
    logout,
    me,
    walletBalance,
  } = props;

  const [showPopup, setShowPopup] = useState(false);

  const menuItems = [
    {
      link: '/profile',
      icon: 'profile',
      text: 'My profile',
      onClick: () => setShowPopup(false),
    },
    {
      link: '/collections',
      icon: 'collections',
      text: 'My collections',
      onClick: () => setShowPopup(false),
    },
    {
      link: '/profile/settings',
      icon: 'settings',
      text: 'Settings',
      onClick: () => setShowPopup(false),
    },
    {
      link: '#',
      icon: 'logout',
      text: 'Disconnect',
      onClick: logout,
    },
  ];

  return (
    <div className="top-menu-my-account__component">
      <div
        className="user-avatar"
        onClick={() => setShowPopup(true)}
        role="button"
        tabIndex={-1}
      >
        <CommonLogoContainer logoUrl={me.avatar} />
      </div>

      {showPopup &&
        <OutsideHandler handleClickOutside={() => setShowPopup(false)}>
          <div className="my-account-popup">
            <div className="account-info">
              <div className="username">{me.username}</div>
              {me.wallet &&
                <div className="blockchain-wallet">
                  <ProfileWallet profileData={me} />
                </div>
              }
              {me.wallet && walletBalance &&
                <div className="wallet-balance">
                  Balance:&nbsp;&nbsp;
                  <span>{Number(walletBalance?.balance)} {walletBalance?.currency_code.toUpperCase()}</span>
                </div>
              }
            </div>

            <div className="nav-links">
              {menuItems.map(item => (
                <Link
                  key={`key-${item.link}`}
                  className="link-item"
                  to={item.link}
                  onClick={item.onClick}
                >
                  <div className={`icon ${item.icon}`}></div>
                  <div className="text">{item.text}</div>
                </Link>
              ))}
            </div>

          </div>
        </OutsideHandler>
      }
    </div>
  );
}

MyAccount.propTypes = {
  logout: PropTypes.object.isRequired,
  me: PropTypes.object,
  walletBalance: PropTypes.object,
};

MyAccount.defaultProps = {
  me: null,
  walletBalance: null,
};

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
    walletBalance: state.blockchainReducer.walletBalance,
  }
);

export default connect(
  mapStateToProps,
  {
    logout,
  },
)(MyAccount);
