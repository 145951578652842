import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Breadcrambs from 'components/common/navigation/Breadcrambs';

import './MainPageLayout.css';

function MainPageLayout(props) {
  const {
    children,
    fullscreen,
    breadcrambs,
    header,
  } = props;

  return (
    <div className="main-page-layout__component">
      {breadcrambs &&
        <div className="breadcrambs-block">
          <Breadcrambs data={breadcrambs} />
        </div>
      }
      <div className={`content-block${fullscreen ? ' fullscreen' : ''}`}>
        <Fragment>
          {header && <h1>{header}</h1>}
          {children}
        </Fragment>
      </div>
    </div>
  );
}

MainPageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
  breadcrambs: PropTypes.string,
  fullscreen: PropTypes.bool,
  header: PropTypes.string,
};

MainPageLayout.defaultProps = {
  breadcrambs: null,
  fullscreen: null,
  header: null,
};

export default MainPageLayout;