import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import MainLogo from 'components/common/logos/MainLogo';
import Button from 'components/common/elements/buttons/Button';
import MyAccount from 'components/common/navigation/MyAccount';
import MobileMenu from 'components/common/navigation/MobileMenu';
import GlobalSearch from 'components/common/searches/GlobalSearch';

import './TopMenu.css';


function TopMenu(props) {
  const { me } = props;

  return (
    <div className="top-menu__component">
      {/* Logo Container */}
      <div className="logo-container">
        <MainLogo />
      </div>

      {/* Global Search */}
      <div className="search-container">
        <GlobalSearch />
      </div>

      {/* Navigation Container */}
      <div className="nav-container">
        <Link className="nav-item" to="/explore">
          Explore
        </Link>

        <Link className="nav-item" to="/how-it-works">
          How it works
        </Link>

        <Link className="nav-item" to="/pricing">
          Pricing
        </Link>

        <Link className="nav-item" to="/contact">
          Contact
        </Link>

        {!me &&
          <Fragment>
            <Link className="nav-item signup" to="/signup">
              <Button className="primary small">Sign Up</Button>
            </Link>
            <Link className="nav-item" to="/login">
              <Button className="secondary small">Log In</Button>
            </Link>
          </Fragment>
        }

        {me &&
          <Fragment>
            <Link
              className="nav-item"
              to="/asset/create"
            >
              Create
            </Link>
            <MyAccount />
          </Fragment>
        }
      </div>

      <div className="mobile-menu-icon">
        <MobileMenu />
      </div>
    </div>
  );
}

TopMenu.propTypes = {
  me: PropTypes.object,
};

TopMenu.defaultProps = {
  me: null,
};

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
  }
);

export default connect(
  mapStateToProps,
  {},
)(TopMenu);
