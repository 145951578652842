import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchExploreAssetsList,
  clearFetchExploreAssetsList,
} from 'redux/assets/actions';

import AssetsFiltersBar from 'components/main/assets/filters-sorting/AssetsFiltersBar';

import AssetList from 'components/main/assets/common/AssetList';

// import AssetList from 'components/main/assets/common/_internal/AssetList';

import { usePrevious } from 'utils/custom-hooks/usePrevious';

import './ExploreAssetsList.css';


function ExploreAssetsList(props) {
  const {
    fetchExploreAssetsList,
    clearFetchExploreAssetsList,
    exploreAssetsList,
    exploreAssetsListError,
  } = props;

  const [currentFilters, setCurrentFilters] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const prevFilters = usePrevious(currentFilters);

  useEffect(() => {
    return () => {
      clearFetchExploreAssetsList();
    }
  }, []);

  useEffect(() => {
    if (prevFilters !== currentFilters) {
      fetchExploreAssetsList(1, currentFilters);
    } else {
      fetchExploreAssetsList(currentPage, currentFilters);
    }
  }, [currentPage, currentFilters]);

  const handlePaginationPageClick = data => {
    setCurrentPage(data.selected + 1);
  };

  // useEffect(() => {
  //   fetchExploreAssetsList(1, currentFilters);
  // }, [currentFilters]);


  return (
    <div className="explore-assets-list__component">
      {/* Filters */}
      <AssetsFiltersBar
        currentFilters={currentFilters}
        setCurrentFilters={setCurrentFilters}
      />

      {/* Assets List */}
      <AssetList
        list={exploreAssetsList}
        listError={exploreAssetsListError}
        handlePaginationPageClick={handlePaginationPageClick}
      />

      {/*<AssetList*/}
      {/*  currentList={exploreAssetsList}*/}
      {/*  currentListError={exploreAssetsListError}*/}
      {/*  loadFunction={pageNumber => fetchExploreAssetsList(pageNumber, currentFilters)}*/}
      {/*/>*/}
    </div>
  );
}

ExploreAssetsList.propTypes = {
  fetchExploreAssetsList: PropTypes.func.isRequired,
  clearFetchExploreAssetsList: PropTypes.func.isRequired,
  exploreAssetsList: PropTypes.arrayOf(PropTypes.element),
  exploreAssetsListError: PropTypes.object,
};

ExploreAssetsList.defaultProps = {
  exploreAssetsList: null,
  exploreAssetsListError: null,
};

const mapStateToProps = state => (
  {
    exploreAssetsList: state.assetsReducer.exploreAssetsList,
    exploreAssetsListError: state.assetsReducer.exploreAssetsListError,
  }
);

export default connect(mapStateToProps, {
  fetchExploreAssetsList,
  clearFetchExploreAssetsList,
})(ExploreAssetsList);
