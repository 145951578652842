import React, { useState } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import Button from 'components/common/elements/buttons/Button';
import FixedNotification from 'components/common/elements/notifications/FixedNotification';

import './GetInTouchSection.css';


function GetInTouchSection() {
  const [showFixed, setShowFixed] = useState(false);

  return (
    <div className="get-in-touch-section__component">
      <div className="logo" />
      <h2>Get in touch</h2>
      <div className="undertext">
        Whether you&prime;d like to get more info on what, why
        and how we do what we do, need help with your sales,
        would like to partner up or just say hi! - we&prime;d love to hear from you.
      </div>
      <div className="buttons-block">
        <a href="mailto:team@heymint.me">
          <Button className="primary">
            Email Us
          </Button>
        </a>
        <CopyToClipboard
          text={'team@heymint.me'}
          onCopy={() => setShowFixed(true)}
        >
          <Button className="secondary">
            <div>team@heymint.me</div>
            <div className="icon" />
          </Button>
        </CopyToClipboard>
      </div>

      {showFixed &&
        <FixedNotification
          onFadeComplete={() => setShowFixed(false)}
        >
          Email has been copied!
        </FixedNotification>
      }
    </div>
  );
}

export default GetInTouchSection;
