import React, { useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchCollectionDetails,
  clearFetchCollectionDetails,
  clearPatchCollection,
} from 'redux/collections/actions';

import { useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { useNavigate } from "react-router-dom";

import EditCollectionForm from 'components/main/collections/edit-content/EditCollectionForm';
import CommonMessage from 'components/common/messages/CommonMessage';

import './EditCollectionPageContent.css';


function EditCollectionPageContent(props) {
  const {
    fetchCollectionDetails,
    clearFetchCollectionDetails,
    clearPatchCollection,
    collectionDetails,
    collectionDetailsError,
    patchCollectionSuccess,
    me,
  } = props;

  const { slug } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    fetchCollectionDetails(slug);

    return () => {
      clearFetchCollectionDetails();
    };
  }, []);

  useEffect(() => {
    if (patchCollectionSuccess && patchCollectionSuccess.slug) {
      navigate(`/collection/${patchCollectionSuccess.slug}`);
      clearPatchCollection();
    }
  }, [patchCollectionSuccess]);

  return (
    <div className="edit-collection-page-content__component">
      {!collectionDetails && !collectionDetailsError &&
        <Loader active />
      }

      {me && collectionDetails && collectionDetails.user === me.id &&
        <EditCollectionForm />
      }

      {me && collectionDetails && collectionDetails.user !== me.id &&
        <CommonMessage warning>
          It seems you have no access to this page. Please verify URL
        </CommonMessage>
      }

      {collectionDetailsError &&
        <CommonMessage error errors={collectionDetailsError} />
      }

    </div>
  );
}

EditCollectionPageContent.propTypes = {
  fetchCollectionDetails: PropTypes.func.isRequired,
  clearFetchCollectionDetails: PropTypes.func.isRequired,
  patchCollection: PropTypes.func.isRequired,
  clearPatchCollection: PropTypes.func.isRequired,
  collectionDetails: PropTypes.object,
  collectionDetailsError: PropTypes.object,
  patchCollectionSuccess: PropTypes.object,
  me: PropTypes.object,
};

EditCollectionPageContent.defaultProps = {
  collectionDetails: null,
  collectionDetailsError: null,
  patchCollectionSuccess: null,
  me: null,
};

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
    collectionDetails: state.collectionsReducer.collectionDetails,
    patchCollectionSuccess: state.collectionsReducer.patchCollectionSuccess,
    patchCollectionError: state.collectionsReducer.patchCollectionError,
  }
);

export default connect(mapStateToProps, {
  fetchCollectionDetails,
  clearFetchCollectionDetails,
  clearPatchCollection,
})(EditCollectionPageContent);
