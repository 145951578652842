import React from 'react';

import RequireAuth from 'hoc/RequireAuth';
import ProfilePageContent from 'components/main/profile/ProfilePageContent';

const ProfilePage = () => (
  <RequireAuth>
    <ProfilePageContent />
  </RequireAuth>
);

export default ProfilePage;
