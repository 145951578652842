import {
  FETCH_BLOCKCHAINS_LIST,
  FETCH_BLOCKCHAINS_LIST_ERROR,
  CREATE_HEDERA_ACCOUNT_SUCCESS,
  CREATE_HEDERA_ACCOUNT_ERROR,
  CLEAR_CREATE_HEDERA_ACCOUNT,
  FETCH_WALLET_BALANCE,
  FETCH_WALLET_BALANCE_ERROR,
  CLEAR_FETCH_WALLET_BALANCE,
  GENERATE_MNEMONIC_SUCCESS,
  GENERATE_MNEMONIC_ERROR,
  CLEAR_GENERATE_MNEMONIC,
} from 'redux/blockchain/types';

const initialState = {
  blockchainsList: null,
  blockchainsListError: null,
  createHederaAccountSuccess: null,
  createHederaAccountError: null,
  walletBalance: null,
  walletBalanceError: null,
  generateMnemonicSuccess: null,
  generateMnemonicError: null,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BLOCKCHAINS_LIST:
      return {
        ...state,
        blockchainsList: action.payload.data,
        blockchainsListError: null,
      };
    case FETCH_BLOCKCHAINS_LIST_ERROR:
      return {
        ...state,
        blockchainsList: null,
        blockchainsListError: action.payload.data,
      };
    case CREATE_HEDERA_ACCOUNT_SUCCESS:
      return {
        ...state,
        createHederaAccountSuccess: action.payload.data,
        createHederaAccountError: null,
      };
    case CREATE_HEDERA_ACCOUNT_ERROR:
      return {
        ...state,
        createHederaAccountSuccess: null,
        createHederaAccountError: action.payload.data,
      };
    case CLEAR_CREATE_HEDERA_ACCOUNT:
      return {
        ...state,
        createHederaAccountSuccess: null,
        createHederaAccountError: null,
      };
    case FETCH_WALLET_BALANCE:
      return {
        ...state,
        walletBalance: action.payload.data,
        walletBalanceError: null,
      };
    case FETCH_WALLET_BALANCE_ERROR:
      return {
        ...state,
        walletBalance: null,
        walletBalanceError: action.payload.data,
      };
    case CLEAR_FETCH_WALLET_BALANCE:
      return {
        ...state,
        walletBalance: null,
        walletBalanceError: null,
      };
    case GENERATE_MNEMONIC_SUCCESS:
      return {
        ...state,
        generateMnemonicSuccess: action.payload.data,
        generateMnemonicError: null,
      };
    case GENERATE_MNEMONIC_ERROR:
      return {
        ...state,
        generateMnemonicSuccess: null,
        generateMnemonicError: action.payload.data,
      };
    case CLEAR_GENERATE_MNEMONIC:
      return {
        ...state,
        generateMnemonicSuccess: null,
        generateMnemonicError: null,
      };
    default:
      return state;
  }
};
