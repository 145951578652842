import React from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import MainLogo from 'components/common/logos/MainLogo';

import './CommonFooter.css';


function CommonFooter({ me }) {
  const date = new Date();

  return (
    <footer className="common-footer__component">
      <div className="navigation">
        <div className="section logo">
          <MainLogo />
          <div className="underlogo">The New Creative Economy</div>
        </div>

        <div className="section">
          <div className="section-title">Marketplace</div>
          <Link className="nav-item" to={'/explore'}>Explore All NFTs</Link>
          {!me &&
            <>
              <Link className="nav-item" to={'/login'}>Log In</Link>
              <Link className="nav-item" to={'/signup'}>Sign Up</Link>
            </>
          }
        </div>

        <div className="section">
          <div className="section-title">Services & Help</div>
          <Link className="nav-item" to={'/how-it-works'}>How it works</Link>
          <Link className="nav-item" to={'/contact'}>Contact</Link>
          <Link className="nav-item" to={'/pricing'}>Pricing</Link>
        </div>

        {me &&
          <div className="section">
            <div className="section-title">My Account</div>
            <Link className="nav-item" to={'/profile'}>My profile</Link>
            <Link className="nav-item" to={'/collections'}>My collections</Link>
            <Link className="nav-item" to={'/asset/create'}>Create item</Link>
          </div>
        }
      </div>

      <div className="under">
        <div className='terms-privacy-wrapper'>
          <Link className='terms' to={'/terms'}>Terms</Link>
          <Link className='privacy' to={'/privacy'}>Privacy</Link>
        </div>
        <div className="powered">
          <div className="text">Powered by</div>
          <a href="https://heylink.me" className="logo" />
          <a
            href="https://heylink.me"
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            HeyLink.me
          </a>
        </div>
        <div>@ HeyMint.me {date.getFullYear()}. All rights reserved</div>       
      </div>
    </footer>
  );
}

CommonFooter.propTypes = {
  me: PropTypes.object,
};

CommonFooter.defaultProps = {
  me: null,
};

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
  }
);

export default connect(
  mapStateToProps,
  {},
)(CommonFooter);
