import React from 'react';
import PropTypes from "prop-types";

import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';

import CommonLogoContainer from 'components/common/logos/CommonLogoContainer';

import './AssetDetailsInfo.css';


function AssetDetailsInfo(props) {
  const { assetDetails } = props;

  const owner = assetDetails?.owner?.username;
  const ownerAvatar = assetDetails?.owner?.avatar;
  const collectionLogo = assetDetails.collection?.logo;

  return (
    <div className="asset-details-info__component">

      <div className="token-name">
        {assetDetails.token_name}
      </div>

      <div className="info-section">
        <CommonLogoContainer logoUrl={ownerAvatar} />
        <div className="info-block">
          <div className="label">Owner:</div>
          <Link
            className="link"
            to={`/profile/${owner}`}
          >
            {owner}
          </Link>
        </div>
      </div>

      <div className="info-section">
        <CommonLogoContainer
          logoUrl={collectionLogo}
          type={'collection'}
        />
        <div className="info-block">
          <div className="label">Collection:</div>
          <Link
            className="link"
            to={`/collection/${assetDetails.collection.slug}`}
          >
            {assetDetails.collection.title}
          </Link>
        </div>
      </div>

    </div>
  );
}

AssetDetailsInfo.propTypes = {
  assetDetails: PropTypes.object.isRequired,
};

export default AssetDetailsInfo;
