import React, { Fragment } from 'react';

import { Helmet } from 'react-helmet';
import MainLandingPageContent from 'components/main/landing/MainLandingPageContent';

const MainLandingPage = () => (
  <Fragment>
    <Helmet>
      <title>HeyMint.me | Fast NFT Transactions & Lowest Fees</title>
      <meta property="og:title" content="HeyMint.me | Fast NFT Transactions & Lowest Fees" />
      <meta
        name="description"
        content="The amazing digital marketplace for NFTs of the world is here. Buy & Sell NFTs in a matter of seconds. Lowest Fees to mint NFT"
      />
      <meta
        property="og:description"
        content="The amazing digital marketplace for NFTs of the world is here. Buy & Sell NFTs in a matter of seconds. Lowest Fees to mint NFT"
      />
    </Helmet>
    <MainLandingPageContent />
  </Fragment>
);

export default MainLandingPage;
