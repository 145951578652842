import restapi from 'utils/restapi';
import { baseErrorsHandlerDecorator } from 'redux/utils';

import {
  RESET_NOT_FOUND,
  RESET_NETWORK_ERROR,
  CLEAR_ACTION_NOTIFICATION,
  FETCH_PROJECT_SETTINGS,
  FETCH_PROJECT_SETTINGS_ERROR,
} from 'redux/common/types';


export const resetNotFound = () => dispatch => {
  dispatch({
    type: RESET_NOT_FOUND,
  });
};

export const resetNetworkError = () => dispatch => {
  dispatch({
    type: RESET_NETWORK_ERROR,
  });
};

export const clearActionNotification = () => dispatch => {
  dispatch({
    type: CLEAR_ACTION_NOTIFICATION,
  });
};

export const fetchProjectSettings = () => baseErrorsHandlerDecorator(
  FETCH_PROJECT_SETTINGS,
  FETCH_PROJECT_SETTINGS_ERROR,
  () => restapi.get('v1/tools/project-settings/'),
  false,
);
