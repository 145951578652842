import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchProfileAssetsList,
  clearFetchProfileAssetsList,
} from 'redux/assets/actions';

import AssetsFiltersBar from 'components/main/assets/filters-sorting/AssetsFiltersBar';

import AssetList from 'components/main/assets/common/AssetList';

import { usePrevious } from 'utils/custom-hooks/usePrevious';

import './ProfileAssetsList.css';


function ProfileAssetsList(props) {
  const {
    fetchProfileAssetsList,
    clearFetchProfileAssetsList,
    profileAssetsList,
    profileAssetsListError,
    type,
    publicUsername,
  } = props;

  const [currentFilters, setCurrentFilters] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const prevFilters = usePrevious(currentFilters);

  useEffect(() => {
    return () => {
      clearFetchProfileAssetsList();
    }
  }, []);

  useEffect(() => {
    if (prevFilters !== currentFilters) {
      fetchProfileAssetsList(type, publicUsername || null, 1, currentFilters);
    } else {
      fetchProfileAssetsList(type, publicUsername || null, currentPage, currentFilters);
    }
  }, [currentPage, currentFilters]);

  const handlePaginationPageClick = data => {
    setCurrentPage(data.selected + 1);
  };

  return (
    <div className="profile-assets-list__component">
      {/* Filters */}
      <AssetsFiltersBar
        currentFilters={currentFilters}
        setCurrentFilters={setCurrentFilters}
      />

      {/* Assets List */}
      <AssetList
        list={profileAssetsList}
        listError={profileAssetsListError}
        handlePaginationPageClick={handlePaginationPageClick}
      />
    </div>
  );
}

ProfileAssetsList.propTypes = {
  fetchProfileAssetsList: PropTypes.func.isRequired,
  clearFetchProfileAssetsList: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  profileAssetsList: PropTypes.arrayOf(PropTypes.object),
  profileAssetsListError: PropTypes.object,
  publicUsername: PropTypes.string,
};

ProfileAssetsList.defaultProps = {
  profileAssetsList: null,
  profileAssetsListError: null,
  publicUsername: null,
};

const mapStateToProps = state => (
  {
    profileAssetsList: state.assetsReducer.profileAssetsList,
    profileAssetsListError: state.assetsReducer.profileAssetsListError,
  }
);

export default connect(mapStateToProps, {
  fetchProfileAssetsList,
  clearFetchProfileAssetsList,
})(ProfileAssetsList);
