import React from 'react';
import PropTypes from "prop-types";

import ProfileWallet from 'components/main/profile/ProfileWallet';

import './ProfileInfo.css';


function ProfileInfo(props) {
  const { profileData } = props;

  return (
    <div className="profile-info__component">
      <div className="username">
        {profileData.username}
      </div>

      {profileData.wallet &&
        <ProfileWallet profileData={profileData} />
      }

      {profileData.description &&
        <div className="description">
          {profileData.description}
        </div>
      }
    </div>
  );
}

ProfileInfo.propTypes = {
  profileData: PropTypes.object.isRequired,
};

export default ProfileInfo;
