import React from 'react';

import { Routes, Route } from 'react-router-dom';

// Authentication
import LoginPage from 'pages/authentication/LoginPage';
import SignupPage from 'pages/authentication/SignupPage';
import EmailVerificationPage from 'pages/authentication/EmailVerificationPage';
import PasswordRecoveryPage from 'pages/authentication/PasswordRecoveryPage';
import ResetPasswordPage from 'pages/authentication/ResetPasswordPage';

// Main Pages
import CommonPageLayout from 'components/common/layouts/CommonPageLayout';
import MainLandingPage from 'pages/main/landing/MainLandingPage';
import ExplorePage from 'pages/main/explore/ExplorePage';
import HowItWorksPage from 'pages/main/common/HowItWorksPage';
import PrivacyPage from 'pages/main/common/PrivacyPage';
import TermsPage from 'pages/main/common/TermsPage';
import ContactPage from 'pages/main/common/ContactPage';
import PricingPage from 'pages/main/common/PricingPage';

import ProfilePage from 'pages/main/profile/ProfilePage';
import ProfileSettingsPage from 'pages/main/profile/ProfileSettingsPage';
import PublicProfilePage from 'pages/main/profile/PublicProfilePage';

import MyCollectionsPage from 'pages/main/collections/MyCollectionsPage';
import CreateCollectionPage from 'pages/main/collections/CreateCollectionPage';
import SingleCollectionPage from 'pages/main/collections/SingleCollectionPage';
import EditCollectionPage from 'pages/main/collections/EditCollectionPage';

import CreateAssetPage from 'pages/main/assets/CreateAssetPage';
import SingleAssetPage from 'pages/main/assets/SingleAssetPage';
// import EditAssetPage from 'pages/main/assets/EditAssetPage';

// Not Found
import NotFoundPage from 'pages/errors/NotFoundPage';


export default (
  <Routes>

    {/* Main Pages */}
    <Route path="/" element={<CommonPageLayout />}>
      <Route index path="/" element={<MainLandingPage landing />} />
      <Route index path="explore" element={<ExplorePage />} />
      <Route index path="how-it-works" element={<HowItWorksPage />} />
      <Route index path="terms" element={<TermsPage/>}/>
      <Route index path="privacy" element={<PrivacyPage/>}/>
      <Route index path="contact" element={<ContactPage/>}/>
      <Route index path="pricing" element={<PricingPage />} />

      {/* Profile */}
      <Route index path="profile" element={<ProfilePage />} />
      <Route index path="profile/settings" element={<ProfileSettingsPage />} />
      <Route index path="profile/:username" element={<PublicProfilePage />} />

      {/* Collections */}
      <Route index path="collections" element={<MyCollectionsPage />} />
      <Route index path="collection/create" element={<CreateCollectionPage />} />
      <Route index path="collection/:slug" element={<SingleCollectionPage />} />
      <Route index path="collection/:slug/edit" element={<EditCollectionPage />} />

      {/* Assets */}
      <Route index path="asset/create" element={<CreateAssetPage />} />
      <Route index path="asset/create/:collectionSlug" element={<CreateAssetPage />} />
      <Route index path="asset/:tokenId/:serialNumber" element={<SingleAssetPage />} />
      {/*<Route index path="asset/:tokenId/edit" element={<EditAssetPage />} />*/}
    </Route>

    {/* Authentication */}
    <Route path="/login" element={<LoginPage />} />
    <Route path="/signup" element={<SignupPage />} />
    <Route path="/signup/email-verification/:verificationKey" element={<EmailVerificationPage />} />
    <Route path="/password-recovery" element={<PasswordRecoveryPage />} />
    <Route path="/password-recovery/reset/:key" element={<ResetPasswordPage />} />

    <Route path="/*" element={<NotFoundPage />} />
  </Routes>
);
