export const SITE_LOADING = 'COMMON/SITE_LOADING';

export const NOT_FOUND = 'COMMON/NOT_FOUND';
export const RESET_NOT_FOUND = 'COMMON/RESET_NOT_FOUND';

export const NETWORK_ERROR = 'COMMON/NETWORK_ERROR';
export const RESET_NETWORK_ERROR = 'COMMON/RESET_NETWORK_ERROR';

export const ACTION_SUCCESS_NOTIFICATION = 'COMMON/ACTION_SUCCESS_NOTIFICATION';
export const ACTION_ERROR_NOTIFICATION = 'COMMON/ACTION_ERROR_NOTIFICATION';
export const CLEAR_ACTION_NOTIFICATION = 'COMMON/CLEAR_ACTION_NOTIFICATION';

export const FETCH_PROJECT_SETTINGS = 'COMMON/FETCH_PROJECT_SETTINGS';
export const FETCH_PROJECT_SETTINGS_ERROR = 'COMMON/FETCH_PROJECT_SETTINGS_ERROR';
