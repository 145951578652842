import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import './CommonTabItem.css';

const CommonTabItem = ({ className, url, tabName, active, onClick }) => {
  const _calculateClassName = () => {
    let value = 'common-tab-item__component';
    if (className) { value += ` ${className}`; }
    if (active) { value += ' active'; }
    return value;
  };

  return (
    <Link
      className={_calculateClassName()}
      to={url ? url : '#'}
      onClick={!url && onClick}
    >
      {tabName}
    </Link>
  );
};

CommonTabItem.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  tabName: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

CommonTabItem.defaultProps = {
  onClick: null,
  className: null,
  active: null,
};

export default CommonTabItem;
