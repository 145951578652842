import {
  SITE_LOADING,
  NOT_FOUND,
  RESET_NOT_FOUND,
  NETWORK_ERROR,
  RESET_NETWORK_ERROR,
  ACTION_SUCCESS_NOTIFICATION,
  ACTION_ERROR_NOTIFICATION,
  CLEAR_ACTION_NOTIFICATION,
  FETCH_PROJECT_SETTINGS,
  FETCH_PROJECT_SETTINGS_ERROR,
} from 'redux/common/types';

const initialState = {
  siteLoading: false,
  notFound: false,
  networkError: false,
  actionSuccessNotification: null,
  actionErrorNotification: null,
  projectSettings: null,
  projectSettingsError: null,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case SITE_LOADING:
      return {
        ...state,
        siteLoading: action.payload,
      };
    case NOT_FOUND:
      return {
        ...state,
        notFound: true,
      };
    case RESET_NOT_FOUND:
      return {
        ...state,
        notFound: false,
      };
    case NETWORK_ERROR:
      return {
        ...state,
        networkError: true,
      };
    case RESET_NETWORK_ERROR:
      return {
        ...state,
        networkError: false,
      };
    case ACTION_SUCCESS_NOTIFICATION:
      return {
        ...state,
        actionSuccessNotification: action.payload,
      };
    case ACTION_ERROR_NOTIFICATION:
      return {
        ...state,
        actionErrorNotification: action.payload,
      };
    case CLEAR_ACTION_NOTIFICATION:
      return {
        ...state,
        actionSuccessNotification: null,
        actionErrorNotification: null,
      };
    case FETCH_PROJECT_SETTINGS:
      return {
        ...state,
        projectSettings: action.payload.data,
        projectSettingsError: null,
      };
    case FETCH_PROJECT_SETTINGS_ERROR:
      return {
        ...state,
        projectSettings: null,
        projectSettingsError: action.payload.data,
      };
    default:
      return state;
  }
};
