import React from 'react';

import RequireAuth from 'hoc/RequireAuth';
import MainPageLayout from 'components/common/layouts/MainPageLayout';
import EditCollectionPageContent from 'components/main/collections/edit-content/EditCollectionPageContent';

const breadcrambs = [
  {
    text: 'My Collections',
    link: "/collections",
  },
  {
    text: 'Edit Collection',
    link: "#",
  },
];

const EditCollectionPage = () => (
  <RequireAuth>
    <MainPageLayout
      header="Edit Collection"
      breadcrambs={breadcrambs}
    >
      <EditCollectionPageContent />
    </MainPageLayout>
  </RequireAuth>
);

export default EditCollectionPage;
