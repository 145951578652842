import React from 'react';
import PropTypes from "prop-types";

import CommonDropdown from 'components/common/elements/dropdowns/CommonDropdown';


function AssetsSortingSelector(props) {
  const {
    currentFilters,
    setCurrentFilters,
  }  = props;

  const dropdownOptions = [
    { key: '-price', value: '-price', text: 'Price: Low to High' },
    { key: 'price', value: 'price', text: 'Price: High to Low' },
    { key: '-created_at', value: '-created_at', text: 'Recently Created' },
    { key: '-listed_at', value: '-listed_at', text: 'Recently Listed' },
  ];

  return (
    <div className="assets-sorting-selector__component">
      <CommonDropdown
        selection
        options={dropdownOptions}
        placeholder='Sorting'
        onChange={(e, data) => {
          setCurrentFilters({ ...currentFilters, sorting: data.value});
        }}
      />
    </div>
  );
}

AssetsSortingSelector.propTypes = {
  setCurrentFilters: PropTypes.func.isRequired,
  currentFilters: PropTypes.object,
};

AssetsSortingSelector.defaultProps = {
  currentFilters: null,
};

export default AssetsSortingSelector;
