import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  setAssetListing,
} from 'redux/assets/actions';

import { Field, reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';

import Button from 'components/common/elements/buttons/Button';
import TextField from 'components/redux-form-adapters/TextField';
import CommonMessage from 'components/common/messages/CommonMessage';

import validate from './validate';

import './AssetListingForm.css';


function AssetListingForm(props) {
  const {
    setAssetListing,
    handleSubmit,
    reset,
    valid,
    assetDetails,
    assetListingError,
  } = props;

  const _handleSubmit = values => {
    setAssetListing(
      assetDetails?.collection?.token_id,
      assetDetails.serial_number,
      { price: values.price },
    );
    reset();
  };

  return (
    <div className="asset-listing-form__component">
      <Form
        onSubmit={handleSubmit(_handleSubmit)}
        error={!valid}
      >
        <Field
          component={TextField}
          label={'PRICE PER UNIT'}
          placeholder="Amount"
          name="price"
          type="text"
          labelPosition="right corner"
          required
        />

        {assetListingError &&
          <CommonMessage
            error
            errors={assetListingError}
          />
        }

        <Button
          className="primary"
          submit
          disabled={!valid}
        >
          Complete listing
        </Button>
      </Form>
    </div>
  );
}

AssetListingForm.propTypes = {
  setAssetListing: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  assetDetails: PropTypes.object.isRequired,
  assetListingError: PropTypes.object,
};

AssetListingForm.defaultProps = {
  valid: false,
  assetListingError: null,
};

const mapStateToProps = state => (
  {
    assetDetails: state.assetsReducer.assetDetails,
    assetListingError: state.assetsReducer.assetListingError,
  }
);

export default connect(
  mapStateToProps,
  {
    setAssetListing,
  },
)(reduxForm({
  form: 'AssetListingForm',
  validate,
})(AssetListingForm));
