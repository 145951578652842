import React, { useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchAssetDetails,
  clearFetchAssetDetails,
} from 'redux/assets/actions';
import {
  fetchWalletBalance,
  clearFetchWalletBalance,
} from 'redux/blockchain/actions';

import { useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import AssetDetails from 'components/main/assets/single-content/AssetDetails';
import CommonMessage from 'components/common/messages/CommonMessage';

import './SingleAssetPageContent.css';


function SingleAssetPageContent(props) {
  const {
    fetchAssetDetails,
    clearFetchAssetDetails,
    fetchWalletBalance,
    clearFetchWalletBalance,
    assetDetails,
    assetDetailsError,
    me,
  } = props;

  const { tokenId, serialNumber } = useParams();

  useEffect(() => {
    fetchAssetDetails(tokenId, serialNumber);
    fetchWalletBalance();

    return () => {
      clearFetchAssetDetails();
      clearFetchWalletBalance();
    };
  }, []);

  return (
    <div className="single-asset-page-content__component">
      {/* Loader */}
      {!assetDetails && !assetDetailsError &&
        <Loader active />
      }

      {/* Details */}
      {assetDetails &&
        <AssetDetails
          assetDetails={assetDetails}
          me={me}
        />
      }

      {/* Errors */}
      {assetDetailsError &&
        <CommonMessage error errors={assetDetailsError} />
      }
    </div>
  );
}

SingleAssetPageContent.propTypes = {
  fetchAssetDetails: PropTypes.func.isRequired,
  clearFetchAssetDetails: PropTypes.func.isRequired,
  fetchWalletBalance: PropTypes.func.isRequired,
  clearFetchWalletBalance: PropTypes.func.isRequired,
  assetDetails: PropTypes.object,
  assetDetailsError: PropTypes.object,
  me: PropTypes.object,
};

SingleAssetPageContent.defaultProps = {
  assetDetails: null,
  assetDetailsError: null,
  me: null,
};

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
    assetDetails: state.assetsReducer.assetDetails,
    assetDetailsError: state.assetsReducer.assetDetailsError,
  }
);

export default connect(mapStateToProps, {
  fetchAssetDetails,
  clearFetchAssetDetails,
  fetchWalletBalance,
  clearFetchWalletBalance,
})(SingleAssetPageContent);
