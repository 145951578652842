import React from 'react';
import PropTypes from "prop-types";

import { Link } from 'react-router-dom';

import RequireUserAccess from 'hoc/RequireUserAccess';
import Button from 'components/common/elements/buttons/Button';

import './AddEditCollectionButtons.css';

const AddEditCollectionButtons = ({ collectionDetails }) => {
  return (
    <RequireUserAccess
      type="display"
      id={collectionDetails.user}
    >
      <div className="add-edit-collection-buttons__component">
        <Link to={`/collection/${collectionDetails.slug}/edit`}>
          <Button className="secondary small">
            Edit collection
          </Button>
        </Link>
        <Link to={`/asset/create/${collectionDetails.slug}`}>
          <Button className="primary small">
            Add item +
          </Button>
        </Link>
      </div>
    </RequireUserAccess>
  );
};

AddEditCollectionButtons.propTypes = {
  collectionDetails: PropTypes.object.isRequired,
};

export default AddEditCollectionButtons;
