import React  from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';

import ProfileSettingsForm from 'components/main/profile-settings/ProfileSettingsForm';

import './ProfileSettingsPageContent.css';


function ProfileSettingsPageContent(props) {
  const { me } = props;

  if (!me) return null;

  return (
    <div className="profile-settings-page-content__component">
      <div className="text">
        You can set preferred display name,
        create <span>your profile URL</span> and manage other personal settings.
      </div>

      <ProfileSettingsForm />
    </div>
  );
}

ProfileSettingsPageContent.propTypes = {
  me: PropTypes.object,
};

ProfileSettingsPageContent.defaultProps = {
  me: null,
};

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
  }
);

export default connect(
  mapStateToProps,
  {},
)(ProfileSettingsPageContent);
