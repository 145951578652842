import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import './MainLogo.css';

const MainLogo = ({ mode }) => (
  <Link
    className={`main-logo__component${mode ? ` ${mode}` : ''}`}
    to="/"
  />
);

MainLogo.propTypes = {
  mode: PropTypes.string,
};

MainLogo.defaultProps = {
  mode: null,
};

export default MainLogo;
