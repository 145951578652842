import React from 'react';

import AuthLayout from 'components/common/layouts/AuthLayout';
import EmailVerificationContent from 'components/authentication/verification/EmailVerificationContent';

const EmailVerificationPage = () => (
  <AuthLayout form="email-verification">
    <EmailVerificationContent />
  </AuthLayout>
);

export default EmailVerificationPage;
