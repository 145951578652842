import React from 'react';

import RequireAuth from 'hoc/RequireAuth';
import MainPageLayout from 'components/common/layouts/MainPageLayout';
import MyCollectionsPageContent from 'components/main/collections/my-collections-content/MyCollectionsPageContent';

const breadcrambs = [
  {
    text: 'Home',
    link: "/",
  },
  {
    text: 'My Collections',
    link: "#",
  },
];

const MyCollectionsPage = () => (
  <RequireAuth>
    <MainPageLayout breadcrambs={breadcrambs}>
      <MyCollectionsPageContent />
    </MainPageLayout>
  </RequireAuth>
);

export default MyCollectionsPage;
