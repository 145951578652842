import React, { Fragment, useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchCollectionDetails,
  clearFetchCollectionDetails,
} from 'redux/collections/actions';

import { useParams } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import AddEditCollectionButtons from 'components/main/collections/single-content/AddEditCollectionButtons';
import CollectionDetails from 'components/main/collections/single-content/CollectionDetails';
import CommonMessage from 'components/common/messages/CommonMessage';

import './SingleCollectionPageContent.css';


function SingleCollectionPageContent(props) {
  const {
    fetchCollectionDetails,
    clearFetchCollectionDetails,
    collectionDetails,
    collectionDetailsError,
  } = props;

  const { slug } = useParams();

  useEffect(() => {
    fetchCollectionDetails(slug);

    return () => {
      clearFetchCollectionDetails();
    };
  }, []);

  return (
    <div className="single-collection-page-content__component">
      {/* Loader */}
      {!collectionDetails && !collectionDetailsError &&
        <Loader active />
      }

      {/* Details */}
      {collectionDetails &&
        <Fragment>
          <AddEditCollectionButtons collectionDetails={collectionDetails} />
          <CollectionDetails collectionDetails={collectionDetails} />
        </Fragment>
      }

      {/* Errors */}
      {collectionDetailsError &&
        <CommonMessage error errors={collectionDetailsError} />
      }
    </div>
  );
}

SingleCollectionPageContent.propTypes = {
  fetchCollectionDetails: PropTypes.func.isRequired,
  clearFetchCollectionDetails: PropTypes.func.isRequired,
  collectionDetails: PropTypes.object,
  collectionDetailsError: PropTypes.object,
};

SingleCollectionPageContent.defaultProps = {
  collectionDetails: null,
  collectionDetailsError: null,
};

const mapStateToProps = state => (
  {
    collectionDetails: state.collectionsReducer.collectionDetails,
    collectionDetailsError: state.collectionsReducer.collectionDetailsError,
  }
);

export default connect(mapStateToProps, {
  fetchCollectionDetails,
  clearFetchCollectionDetails,
})(SingleCollectionPageContent);
