const validate = values => {
  const errors = {};

  if (!values.username) {
    errors.username = 'Username is required';
  } else if (values.username.length > 30) {
    errors.username = "Username must has no more than 30 symbols";
  }

  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (values && values.site_link) {
    if(values.site_link.length > 0 && values.site_link.length < 3 ) {
      errors.site_link = 'Your website must be more then 3 symbols';
    } 
  } 

  if(values && values.instagram_link && values.instagram_link.length > 30) {
    errors.instagram_link = 'Your instagram handle must be less then 30 symbols';
  }

  return errors;
};

export default validate;
