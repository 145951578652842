import React from 'react';
import PropTypes from 'prop-types';

import './CommonTableCell.css';

const CommonTableCell = ({ children, className }) => {
  const calculateClassname = () => {
    let value = 'common-table-cell__component';
    if (className) { value += ` ${className}`; }
    return value;
  };

  return (
    <div className={calculateClassname()}>
      {children}
    </div>
  );
};

CommonTableCell.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
};

CommonTableCell.defaultProps = {
  className: null,
};

export default CommonTableCell;
