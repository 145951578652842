import {
  FETCH_MY_COLLECTIONS_LIST,
  FETCH_MY_COLLECTIONS_LIST_ERROR,
  CLEAR_FETCH_MY_COLLECTIONS_LIST,
  FETCH_COLLECTION_DETAILS,
  FETCH_COLLECTION_DETAILS_ERROR,
  CLEAR_FETCH_COLLECTION_DETAILS,
  CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_ERROR,
  CLEAR_CREATE_COLLECTION,
  UPDATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_ERROR,
  CLEAR_UPDATE_COLLECTION,
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_ERROR,
  CLEAR_DELETE_COLLECTION,
  VALIDATE_COLLECTION_TITLE_SUCCESS,
  VALIDATE_COLLECTION_TITLE_ERROR,
  CLEAR_VALIDATE_COLLECTION_TITLE,
  FETCH_EXPLORE_COLLECTIONS_LIST,
  FETCH_EXPLORE_COLLECTIONS_LIST_ERROR,
  CLEAR_FETCH_EXPLORE_COLLECTIONS_LIST,
  FETCH_PROFILE_COLLECTIONS_LIST,
  FETCH_PROFILE_COLLECTIONS_LIST_ERROR,
  CLEAR_FETCH_PROFILE_COLLECTIONS_LIST,
  FETCH_COLLECTION_ACTIVITIES_LIST,
  FETCH_COLLECTION_ACTIVITIES_LIST_ERROR,
  CLEAR_FETCH_COLLECTION_ACTIVITIES_LIST,
  FETCH_COLLECTION_CATEGORIES,
  FETCH_COLLECTION_CATEGORIES_ERROR,
  CLEAR_FETCH_COLLECTION_CATEGORIES,
} from 'redux/collections/types';

const initialState = {
  myCollectionsList: null,
  myCollectionsListError: null,
  collectionDetails: null,
  collectionDetailsError: null,
  createCollectionSuccess: null,
  createCollectionError: null,
  patchCollectionSuccess: null,
  patchCollectionError: null,
  deleteCollectionSuccess: null,
  deleteCollectionError: null,
  validateCollectionTitleSuccess: null,
  validateCollectionTitleError: null,
  exploreCollectionsList: null,
  exploreCollectionsListError: null,
  profileCollectionsList: null,
  profileCollectionsListError: null,
  collectionActivitiesList: null,
  collectionActivitiesListError: null,
  collectionCategories: null,
  collectionCategoriesError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MY_COLLECTIONS_LIST:
      return {
        ...state,
        myCollectionsList: action.payload.data,
        myCollectionsListError: null,
      };
    case FETCH_MY_COLLECTIONS_LIST_ERROR:
      return {
        ...state,
        myCollectionsList: null,
        myCollectionsListError: action.payload.data,
      };
    case CLEAR_FETCH_MY_COLLECTIONS_LIST:
      return {
        ...state,
        myCollectionsList: null,
        myCollectionsListError: null,
      };
    case FETCH_COLLECTION_DETAILS:
      return {
        ...state,
        collectionDetails: action.payload.data,
        collectionDetailsError: null,
      };
    case FETCH_COLLECTION_DETAILS_ERROR:
      return {
        ...state,
        collectionDetails: null,
        collectionDetailsError: action.payload.data,
      };
    case CLEAR_FETCH_COLLECTION_DETAILS:
      return {
        ...state,
        collectionDetails: null,
        collectionDetailsError: null,
      };
    case CREATE_COLLECTION_SUCCESS:
      return {
        ...state,
        createCollectionSuccess: action.payload.data,
        createCollectionError: null,
      };
    case CREATE_COLLECTION_ERROR:
      return {
        ...state,
        createCollectionSuccess: null,
        createCollectionError: action.payload.data,
      };
    case CLEAR_CREATE_COLLECTION:
      return {
        ...state,
        createCollectionSuccess: null,
        createCollectionError: null,
      };
    case UPDATE_COLLECTION_SUCCESS:
      return {
        ...state,
        patchCollectionSuccess: action.payload.data,
        patchCollectionError: null,
      };
    case UPDATE_COLLECTION_ERROR:
      return {
        ...state,
        patchCollectionSuccess: null,
        patchCollectionError: action.payload.data,
      };
    case CLEAR_UPDATE_COLLECTION:
      return {
        ...state,
        patchCollectionSuccess: null,
        patchCollectionError: null,
      };
    case DELETE_COLLECTION_SUCCESS:
      return {
        ...state,
        deleteCollectionSuccess: action.payload.data,
        deleteCollectionError: null,
      };
    case DELETE_COLLECTION_ERROR:
      return {
        ...state,
        deleteCollectionSuccess: null,
        deleteCollectionError: action.payload.data,
      };
    case CLEAR_DELETE_COLLECTION:
      return {
        ...state,
        deleteCollectionSuccess: null,
        deleteCollectionError: null,
      };
    case VALIDATE_COLLECTION_TITLE_SUCCESS:
      return {
        ...state,
        validateCollectionTitleSuccess: action.payload.data,
        validateCollectionTitleError: null,
      };
    case VALIDATE_COLLECTION_TITLE_ERROR:
      return {
        ...state,
        validateCollectionTitleSuccess: null,
        validateCollectionTitleError: action.payload.data,
      };
    case CLEAR_VALIDATE_COLLECTION_TITLE:
      return {
        ...state,
        validateCollectionTitleSuccess: null,
        validateCollectionTitleError: null,
      };
    case FETCH_EXPLORE_COLLECTIONS_LIST:
      return {
        ...state,
        exploreCollectionsList: action.payload.data,
        exploreCollectionsListError: null,
      };
    case FETCH_EXPLORE_COLLECTIONS_LIST_ERROR:
      return {
        ...state,
        exploreCollectionsList: null,
        exploreCollectionsListError: action.payload.data,
      };
    case CLEAR_FETCH_EXPLORE_COLLECTIONS_LIST:
      return {
        ...state,
        exploreCollectionsList: null,
        exploreCollectionsListError: null,
      };
    case FETCH_PROFILE_COLLECTIONS_LIST:
      return {
        ...state,
        profileCollectionsList: action.payload.data,
        profileCollectionsListError: null,
      };
    case FETCH_PROFILE_COLLECTIONS_LIST_ERROR:
      return {
        ...state,
        profileCollectionsList: null,
        profileCollectionsListError: action.payload.data,
      };
    case CLEAR_FETCH_PROFILE_COLLECTIONS_LIST:
      return {
        ...state,
        profileCollectionsList: null,
        profileCollectionsListError: null,
      };
    case FETCH_COLLECTION_ACTIVITIES_LIST:
      return {
        ...state,
        collectionActivitiesList: action.payload.data,
        collectionActivitiesListError: null,
      };
    case FETCH_COLLECTION_ACTIVITIES_LIST_ERROR:
      return {
        ...state,
        collectionActivitiesList: null,
        collectionActivitiesListError: action.payload.data,
      };
    case CLEAR_FETCH_COLLECTION_ACTIVITIES_LIST:
      return {
        ...state,
        collectionActivitiesList: null,
        collectionActivitiesListError: null,
      };
    case FETCH_COLLECTION_CATEGORIES:
      return {
        ...state,
        collectionCategories: action.payload.data,
        collectionCategoriesError: null,
      };
    case FETCH_COLLECTION_CATEGORIES_ERROR:
      return {
        ...state,
        collectionCategories: null,
        collectionCategoriesError: action.payload.data,
      };
    case CLEAR_FETCH_COLLECTION_CATEGORIES:
      return {
        ...state,
        collectionCategories: null,
        collectionCategoriesError: null,
      };
    default:
      return state;
  }
};
