import React from 'react';
import './TermsPageContent.css';

function TermsPageContent() {
  return (
    <div className='terms-page-content__component'>
      <h3>Introduction</h3>
      <div>By using HeyLink.me and the <a href='/'>HeyMint.me</a> website (Service), a service of Persollo Pty Ltd (ACN: 607 490 611) (“Persollo” or “HeyMint.me“ or “HeyMint“ or “we” or “us” or “our”), you are agreeing to be bound to the following terms and conditions (Terms and Conditions).</div>

      <div>HeyMint.me reserves the right to update and change these Terms and Conditions at its discretion without notice. Any new features in excess to the current Service shall be subject to these Terms and Conditions.</div>

      <div>Continued use of the Service after any such changes shall constitute your consent to such changes. You can review the most current version of the Terms and Conditions at any time at: heymint.me/terms.</div>

      <div>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service. Violation of any of the terms below may result in immediate termination of your Service account.</div>

      <div>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service. You warrant that you are at least 18-years-old and you are legally capable of entering into binding contracts. If you are under 18-years-old, you warrant that you have obtained consent from your parent or guardian and they agree to be bound by these Terms on your behalf.</div>

      <h3>Account Terms</h3>
      <div>Accounts registered by automated methods are not permitted and will be deleted immediately without notice.</div>

      <div>You must provide your legal full name, a valid email address, and any other pertinent information requested in order to complete the sign-up process.</div>

      <div>You are responsible for maintaining the security of your account and password. HeyMint.me cannot and will not be liable for any loss of information or security breaches that result from your failure to comply with this security obligation.You are hereby considered fully responsible for all content posted and any and all activity that occurs under your account.</div>

      <div>You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.</div>

      <div>You may not use the Service for any illegal activities. You must not, in your use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws). Should you engage in any illegal activities through the Service, you agree to hold HeyMint.me blameless and not liable for any damages or crimes that may occur as a result. You will also have your account immediately shut down, and all data therein deleted.</div>

      <div>You may not use the Service to sell or distribute offensive or illegal content (goods, software, products or material). You agree HeyMint.me holds the right to determine what is considered “offensive“ as pertaining to this agreement. Offensive and illegal content includes: things that are sexually-oriented or pornographic, drugs, gambling, things that promote hate or violence towards others. You will have your account immediately shut down, and all data there-in deleted should you sell or distribute illegal or offensive content.</div>

      <div>You understand that the Service uses a third-party payment processor Stripe (stripe.com) to handle payment transactions, and that all monetary transactions made through your use of the Service are subject to any fees Stripe may charge.</div>

      <div>Additionally, service supports Google&apos;s initiative to enable a free and open web. Thus, we will have your account immediately shut down, and all data there-in deleted should you breach any of the below violations.</div>

      <div>
        Illegal content violation
        Service does not allow content that is illegal, promotes illegal activity or infringes on the legal rights of others.
      </div>

      <h3>Intellectual property violation</h3>
      <div>Service does not allow content that infringes copyright; sells or promotes the sale of counterfeit products; counterfeit goods contain a trademark or logo that is identical to or substantially indistinguishable from the trademark of another; they mimic the brand features of the product in an attempt to pass themselves off as a genuine product of the brand owner.</div>

      <h3>Endangered or threatened species violation</h3>
      <div>Service does not allow content that promotes the sale of products obtained from endangered or threatened species.</div>

      <h3>Dangerous or derogatory content violation</h3>
      <div>Service does not allow content that incites hatred against, promotes discrimination of or disparages an individual or group on the basis of their race or ethnic origin, religion, disability, age, nationality, veteran status, sexual orientation, gender, gender identity or other characteristic that is associated with systemic discrimination or marginalisation. Service does not allow content that harasses, intimidates or bullies an individual or group of individuals. Service does not allow content that threatens or advocates physical or mental harm to oneself or others. Service does not allow content that relates to a current, major health crisis and contradicts authoritative, scientific consensus. Service does not allow content that exploits others through extortion.</div>

      <h3>Enabling dishonest behaviour violation</h3>
      <div>Service does not allow content that promotes any form of hacking or cracking and/or provides users with instructions, equipment or software that tampers with or provides unauthorised access to devices, software, servers or websites. Service does not allow content that enables a user, or promotes products and services that enable a user, to track or monitor another person or their activities without their authorisation. This does not include private investigation services; products or services designed for parents to track or monitor their underage children.</div>

      <h3>Misrepresentative content violation</h3>
      <div>Service does not allow content that misrepresents, misstates or conceals information about you, your content or the primary purpose of your web destination. Service does not allow content that entices users to engage with content under false or unclear pretences; engages in “phishing“ for users information; promotes content, products or services using false, dishonest or deceptive claims; makes claims that are demonstrably false and could significantly undermine participation or trust in an electoral or democratic process; falsely implies having an affiliation with, or endorsement by, another individual, organisation, product or service; deceives users through manipulated media related to politics, social issues or matters of public concern.</div>

      <h3>Malicious or unwanted software violation</h3>
      <div>Service does not allow content that contains malicious software or “malware“ that may harm or gain unauthorised access to a computer, device or network.</div>

      <h3>Sexually explicit content violation</h3>
      <div>Service does not allow content that includes graphic sexual text, image, audio, video or games; contains non-consensual sexual themes, whether simulated or real. Service does not allow content that: may be interpreted as promoting a sexual act in exchange for compensation.</div>

      <h3>Child sexual abuse and exploitation violation</h3>
      <div>Service does not allow content that sexually exploits or abuses children, or content that promotes the sexual exploitation or abuse of children; endangers children.</div>

      <h3>Content</h3>
      <div>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material (“Content“). You are responsible for the Content that you post to the Service, including its legality, reliability, and appropriateness.</div>

      <div>By posting Content to the Service, you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You retain any and all of your rights to any Content you submit, post or display on or through the Service and you are responsible for protecting those rights. You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms.</div>

      <div>You represent and warrant that: (i) the Content is yours (you own it) or you have the right to use it and grant us the rights and license as provided in these Terms, and (ii) the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person. Further, you warrant that: (i) the Content will not cause you or us to breach any law, regulation, rule, code or other legal obligation; (ii) the Content will not or could not be reasonably considered to be obscene, inappropriate, defamatory, disparaging, indecent, seditious, offensive, pornographic, threatening, abusive, liable to incite racial hatred, discriminatory, blasphemous, in breach of confidence or in breach of privacy; (iii) the Content will not be unsolicited, undisclosed or unauthorised advertising; (iv) the Content does not contain software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software, hardware or telecommunications equipment; and (v): the Content does not bring us or the Service into disrepute.</div>

      <div>You agree to keep all records necessary to establish that your Content does not violate any of the requirements of this clause and make such records available upon our reasonable request.</div>

      <div>We are under no obligation to regularly monitor the accuracy or reliability of your Content incorporated into the Service. We reserve the right to modify or remove any Content at any time.</div>

      <div>You acknowledge and agree that all Content you provide on the Service will be publicly available information and you bear the risks involved with such public disclosures.</div>

      <h3>Intellectual Property</h3>
      <div>The Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Persollo Pty Ltd and its licensors. The Service is protected by copyright, trademark, and other laws of both Australia and foreign countries. You may not duplicate, copy, or reuse any portion of the code (HTML/CSS/JavaScript) or visual design elements. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Persollo Pty Ltd. Nothing in these Terms constitutes a transfer of any Intellectual Property rights from us to you.</div>

      <div>You are permitted to use the Service only as authorised by us. As a user, you are granted a limited, non-exclusive, revocable, non-transferable right to use the Service to create, display, use, play, and download Content subject to these Terms.</div>

      <div>Our Intellectual Property must not be used in connection with a product or service that is not affiliated with us or in any way brings us in disrepute.</div>

      <div>You must not modify the physical or digital copies of any Content you print off or download in any way, and you must not use any illustrations, photographs, video or audio, or any graphics separately from any accompanying text.</div>

      <div>Any opinions, advice, statements, services, offers, or other information or content expressed or made available by any other users are those of the respective authors or distributors and not of us.</div>

      <h3>Cancellation and Termination</h3>
      <div>It is your responsibility to ensure that your account has been properly cancelled. An email requesting an account to be cancelled is not considered a cancellation. All of your Content will be immediately removed from the Service should you initiate cancellation. This information cannot be recovered once your account is cancelled.</div>

      <div>Your cancellation will be effective immediately once initiated. You will not be charged again from that point forward. HeyMint.me may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. HeyMint.me, in its sole discretion, reserves the right to refuse service to anyone for any reason at any time.</div>

      <div>Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.</div>

      <div>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</div>

      <div>We shall not be liable to you or any third party for any claims or damages arising out of any termination or suspension or any other actions taken by us in connection therewith.</div>

      <div>If applicable law requires us to provide notice of termination or cancellation, we may give prior or subsequent notice by posting it on the Service or by sending a communication to any address (email or otherwise) that we have for you in our records.</div>

      <h3>Modifications to the Service</h3>
      <div>HeyMint.me reserves the right at any time, with or without notice, to modify or discontinue the Service, temporarily or permanently.</div>

      <div>It is your sole responsibility to periodically check these Terms for any changes. If you do not agree with any of the changes to these Terms, it is your sole responsibility to stop using the Service. Your continued use of the Service will be deemed as your acceptance thereof.</div>

      <h3>Limitation of Liability</h3>
      <div>To the fullest extent permitted by law, HeyMint.me excludes all terms, conditions, warranties, and guarantees which might be implied into these terms and conditions.</div>

      <div>HeyMint.me’s liability to you in respect of any claim made by you arising out of or in connection to the Service is limited, at the option of HeyMint.me, to the provision of the Service again or paying to provide the Service again.</div>

      <div>You expressly understand and agree that HeyMint.me shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if Persollo has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the service; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the service; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) or any other matter relating to the service.</div>

      <div> You have sole responsibility for adequate security protection and backup of data and/or equipment used in connection with your usage of the Service and will not make a claim against for lost data, re-run time, inaccurate instruction, work delays or lost profits resulting from the use of the Service. You must not assign or otherwise dispose of your account to any other person.</div>

      <div>Without limiting the foregoing, in no event will our aggregate liability to you exceed, in total, the amounts paid by you to us.</div>

      <h3>Indemnification</h3>

      <div> By agreeing to these Terms and accessing the Service, you agree, to the fullest extent permitted by applicable law, to indemnify, defend, and hold harmless HeyMint, and our respective past, present, and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors, and assigns (individually and collectively, the “HeyMint Parties”), from and against all actual or alleged claims, damages, awards, judgments, losses, liabilities, obligations, penalties, interest, fees, expenses (including, without limitation, attorneys’ fees and expenses), and costs (including, without limitation, court costs, costs of settlement, and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract, or otherwise (collectively, “Claims”), including, but not limited to, damages to property or personal injury, that are caused by, arise out of or are related to (a) your use or misuse of the Service, content, NFTs, or content linked to or associated with any NFTs (b) any Feedback you provide, (c) your violation or breach of any term of these Terms or applicable law, and (d) your violation of the rights of or obligations to a third party, including another user or third-party, and (e) your negligence or wilful misconduct. You agree to promptly notify HeyMint of any Claims and cooperate with the HeyMint Parties in defending such Claims. You further agree that the HeyMint Parties shall have control of the defence or settlement of any Claims. THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND HeyMint.</div>

      <h3>Applicable Law and Jurisdiction</h3>
      <div>These terms and conditions are governed by and construed in accordance with the laws applicable in New South Wales, Australia.</div>

      <div>You and HeyMint.me agree to submit to the exclusive jurisdiction of the courts of New South Wales, Australia.</div>

      <div>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</div>

      <h3>Links To Other Websites</h3>
      <div>Our Service may contain links to third-party web sites or services that are not owned or controlled by Persollo Pty Ltd.</div>

      <div>Persollo Pty Ltd has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Persollo Pty Ltd shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</div>

      <div>We only provide links to external websites as a convenience, and the inclusion of such a link to external websites do not imply our endorsement of those websites. You acknowledge and agree that when you access other websites on the Internet, you do so at your own risk.</div>

      <div>We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.</div>

      <h3>General Conditions</h3>
      <ul>
        <li>Your use of the Service is at your own risk. The service is provided on an “as-is“ and “as-available“ basis Technical support is available to account holders and is available via email (team@heymint.me).</li>
        <li>Persollo Pty Ltd its subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet your requirements.</li>
        <li>You acknowledge and understand that HeyMint.me uses third party vendors to provide the necessary hardware, software, networking, storage, and related technology required to run the Service.</li>
        <li>You must not modify, adapt or hack the Service or modify another website so as to falsely imply that it is associated with the Service or HeyMint.me.</li>
        <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service.</li>
        <li>Verbal, physical, written or other abuse (including threats of abuse or retribution) of any HeyMint.me employee, member, or officer will result in immediate account termination and potential criminal charges.</li>
        <li>HeyMint.me does not warrant that (i) the service will meet your specific requirements, (ii) the service will be uninterrupted, timely, or error-free, (iii) the quality of any products, services, information, or other material purchased or obtained by you through the service will meet your expectations.</li>
        <li>You expressly understand and agree that HeyMint.me shall not be liable for any payments and monetary transactions that occur through your use of the Service. You expressly understand and agree that all payments and monetary transactions are handled by the third-party payment platform the Service uses: Stripe (stripe.com). You agree HeyMint.me shall not be liable for any issues regarding financial and monetary transactions between you and any other party.</li>
        <li>The failure of HeyMint.me to aggressively enforce any right or provision of the Terms of Service shall not be construed as a waiver of such right or provision. The Terms of Service outlines the entire agreement between you and HeyMint.me and supersedes any prior agreements between you and HeyMint.me including prior iterations of the Terms and Conditions.</li>
        <li>Part or all of any provision of this agreement that is illegal or unenforceable may be severed from this agreement and the remaining provisions of this agreement will continue in force.</li>
      </ul>
      <h3>Exclusions</h3>
      <div>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability for consequential or incidental damages, so the limitations above may not apply to you.</div>

      <h3>Customer Data</h3>
      <div>The Customer owns the rights to its data as data controller, and the service acts as data processor on the Customer&apos;s behalf. All processing by the service of the personal data and other data provided by the Customer shall be in accordance with the applicable laws. The service&apos;s processing of personal data on behalf of the Customer shall therefore only be done in order to provide the Product and shall be subject to the Customer&apos;s written instructions.</div>

      <div>The Customer is obligated to keep user logins and passwords to the Product secret from any unauthorized users or third parties. The Customer is obligated to ensure that the personal data provided by the Customer and used in the Product is processed by the Customer in accordance with all applicable laws. The Customer is obligated to ensure that the Customer&apos;s data provided in the Product, including personal data, do not violate any third party intellectual property rights and/or any applicable legislation.</div>

      <div>The service is entitled to delete any data that in the sole discretion of the service constitutes a breach of the aforesaid undertaking by the Customer, and the Customer will not be entitled to any compensation in that respect.</div>

      <h3>Facebook Pixel Integration Feature</h3>
      <div>When you utilize the Facebook Pixel Integration feature of HeyMint.me, you are placing your Facebook Pixel on your profile and you therefore become the controller of your Facebook data, and you are providing this data to Facebook Inc. By using this feature you agree to Facebook Inc’s terms and agree that you now are in a data controller / data processor relationship with Facebook Inc.</div>

      <h3>Data Location</h3>
      <div>You agree that by using this service, all data held as outlined in the privacy policy and here within, as necessary for the product to function is stored and processed in Singapore and is not stored and processed in The United States of America and is not stored within the European Union.</div>

      <h3>Other Rights</h3>
      <div>Whenever made possible, you can update or request deletion of your personal information. If you are unable to perform these actions yourself, please contact us for assistance using the contact details at the bottom of this Privacy Policy.</div>

      <h3>HeyMint.me Contact information</h3>
      <div>Please contact us with any questions or comments about this Policy, your personal data, our use and disclosure practices by email. Alternatively, please use this page for more information: https://heymint.me/contact.</div>

      <h2>HeyMint Fee Changes</h2>

      <h3>Fee Changes</h3>
      
      <div>HeyMint, in its sole discretion and at any time, may modify the fees for the service. </div>
      <div>HeyMint will provide you with a reasonable prior notice of any change in fees to give you an opportunity to terminate your account before such change becomes effective.</div>
      <div>Fee guide is located here: https://heymint.me/pricing.</div>

      <div>If you do not agree to the fee change you must cancel your account in order to avoid future charges. If you use the Services after a fee increase you will be deemed to have accepted the fee change. HeyMint.me shall not be liable to you or to any third party for any modification, fee change, suspension or discontinuance of the Service.</div>

      <h3>Refunds</h3>
      <div>Except when required by law, fees are non-refundable.</div>

      <h3>Disclaimers</h3>

      <div>
        YOUR ACCESS TO AND USE OF THE SERVICE IS AT YOUR OWN RISK. YOU UNDERSTAND AND AGREE THAT THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND HEYMINT EXPRESSLY DISCLAIMS WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. HEYMINT (AND ITS SUPPLIERS) MAKE NO WARRANTY OR REPRESENTATION AND DISCLAIM ALL RESPONSIBILITY FOR WHETHER THE SERVICE: (A) WILL MEET YOUR REQUIREMENTS; (B) WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS; OR (C) WILL BE ACCURATE, RELIABLE, COMPLETE, LEGAL, OR SAFE. HeyMint DISCLAIMS ALL OTHER WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. HeyMint WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION TAKEN OR TAKEN IN RELIANCE ON MATERIAL OR INFORMATION, CONTAINED ON THE SERVICE. WHILE HeyMint ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF THE SERVICE SAFE, HeyMint CANNOT AND DOES NOT REPRESENT OR WARRANT THAT THE SERVICE, CONTENT, CONTENT LINKED TO OR ASSOCIATED WITH ANY NFTS, OR ANY NFTS YOU INTERACT WITH USING OUR SERVICE OR OUR SERVICE PROVIDERS’ SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY DATA THAT YOU DISCLOSE ONLINE. NO ADVICE OR INFORMATION, WHETHER ORAL OR OBTAINED FROM THE HeyMint PARTIES OR THROUGH THE SERVICE, WILL CREATE ANY WARRANTY OR REPRESENTATION NOT EXPRESSLY MADE HEREIN. YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD HeyMint RESPONSIBLE FOR ANY BREACH OF SECURITY.</div>
        <div>WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKE NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR, ANY USE OF NFTS, CONTENT, AND/OR CONTENT LINKED TO OR ASSOCIATED WITH NFTS, INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES, OR CLAIMS ARISING FROM: (A) USER ERROR, INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED ADDRESSES; (B) SERVER FAILURE OR DATA LOSS; (C) UNAUTHORISED ACCESS OR USE; (D) ANY UNAUTHORISED THIRD-PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE SERVICE OR NFTS.</div>
        <div>NFTS EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE ASSOCIATED BLOCKCHAIN (E.G., ETHEREUM NETWORK). ANY TRANSFERS OR SALES OCCUR ON THE ASSOCIATED BLOCKCHAIN (E.G., ETHEREUM). HeyMint AND/OR ANY OTHER HeyMint PARTY CANNOT EFFECT OR OTHERWISE CONTROL THE TRANSFER OF TITLE OR RIGHT IN ANY NFTS OR UNDERLYING OR ASSOCIATED CONTENT OR ITEMS.</div>
        <div>NO HeyMint PARTY IS RESPONSIBLE OR LIABLE FOR ANY SUSTAINED LOSSES OR INJURY DUE TO VULNERABILITY OR ANY KIND OF FAILURE, ABNORMAL BEHAVIOUR OF SOFTWARE (E.G., WALLET, SMART CONTRACT), BLOCKCHAINS OR ANY OTHER FEATURES OF THE NFTS. NO HeyMint PARTY IS RESPONSIBLE FOR LOSSES OR INJURY DUE TO LATE REPORTS BY DEVELOPERS OR REPRESENTATIVES (OR NO REPORT AT ALL) OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE NFTS, INCLUDING FORKS, TECHNICAL NODE ISSUES OR ANY OTHER ISSUES HAVING LOSSES OR INJURY AS A RESULT.</div>
        <div>Some jurisdictions do not allow the exclusion of implied warranties in contracts with consumers, so the above exclusion may not apply to you.</div>
      
      
      <h3>Assumption of Risk</h3>
      
      <div>You accept and acknowledge:</div>
        <ul>
          <li>The value of an NFTs is subjective. Prices of NFTs are subject to volatility and fluctuations in the price of cryptocurrency can also materially and adversely affect NFT prices. You acknowledge that you fully understand this subjectivity and volatility and that you may lose money.</li>
          <li>A lack of use or public interest in the creation and development of distributed ecosystems could negatively impact the development of those ecosystems and related applications, and could therefore also negatively impact the potential utility of NFTs.</li>
          <li>The regulatory regime governing blockchain technologies, non-fungible tokens, cryptocurrency, and other crypto-based items is uncertain, and new regulations or policies may materially adversely affect the development of the Service and the utility of NFTs.</li>
          <li>You are solely responsible for determining what, if any, taxes apply to your transactions. HeyMint is not responsible for determining the taxes that apply to your NFTs.</li>
          <li>There are risks associated with purchasing items associated with content created by third parties through peer-to-peer transactions, including but not limited to, the risk of purchasing counterfeit items, mislabeled items, items that are vulnerable to metadata decay, items on smart contracts with bugs, and items that may become untransferable. You represent and warrant that you have done sufficient research before making any decisions to sell, obtain, transfer, or otherwise interact with any NFTs or accounts/collections.</li>
          <li>We do not control the public blockchains that you are interacting with and we do not control certain smart contracts and protocols that may be integral to your ability to complete transactions on these public blockchains. Additionally, blockchain transactions are irreversible and HeyMint has no ability to reverse any transactions on the blockchain.</li>
          <li>There are risks associated with using Internet and blockchain based products, including, but not limited to, the risk associated with hardware, software, and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorised access to your third-party wallet or Account. You accept and acknowledge that HeyMint will not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using the Service or any Blockchain network, however caused.</li>
          <li>The Service relies on third-party platforms and/or vendors. If we are unable to maintain a good relationship with such platform providers and/or vendors; if the terms and conditions or pricing of such platform providers and/or vendors change; if we violate or cannot comply with the terms and conditions of such platforms and/or vendors; or if any of such platforms and/or vendors loses market share or falls out of favour or is unavailable for a prolonged period of time, access to and use of the Service will suffer.</li>
          <li>HeyMint reserves the right to hide collections, contracts, and items affected by any of these issues or by other issues. Items you purchase may become inaccessible on HeyMint. Under no circumstances shall the inability to view items on HeyMint or an inability to use the Service in conjunction with the purchase, sale, or transfer of items available on any blockchains serve as grounds for a claim against HeyMint.</li>
          <li>If you have a dispute with one or more users, YOU RELEASE US FROM CLAIMS, DEMANDS, AND DAMAGES OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES. IN ENTERING INTO THIS RELEASE YOU EXPRESSLY WAIVE ANY PROTECTIONS (WHETHER STATUTORY OR OTHERWISE) THAT WOULD OTHERWISE LIMIT THE COVERAGE OF THIS RELEASE TO INCLUDE THOSE CLAIMS WHICH YOU MAY KNOW OR SUSPECT TO EXIST IN YOUR FAVOUR AT THE TIME OF AGREEING TO THIS RELEASE.</li>
        </ul>
    </div>
  );
}

export default TermsPageContent;