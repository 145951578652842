import React from 'react';

import RequireAuth from 'hoc/RequireAuth';
import MainPageLayout from 'components/common/layouts/MainPageLayout';
import ProfileSettingsPageContent from 'components/main/profile-settings/ProfileSettingsPageContent';

const breadcrambs = [
  {
    text: 'Home',
    link: "/",
  },
  {
    text: 'Edit Profile',
    link: "#",
  },
];

const ProfileSettingsPage = () => (
  <RequireAuth>
    <MainPageLayout
      breadcrambs={breadcrambs}
      header="Edit Profile"
    >
      <ProfileSettingsPageContent />
    </MainPageLayout>
  </RequireAuth>
);

export default ProfileSettingsPage;
