import React, { useState } from 'react';
import PropTypes from "prop-types";

import CommonTabList from 'components/common/elements/tabs/CommonTabList';
import CommonTabItem from 'components/common/elements/tabs/CommonTabItem';

import SingleAssetInfoTabContent
  from 'components/main/assets/single-content/tabs-content/SingleAssetInfoTabContent';
import AssetActivitiesTabContent
  from 'components/main/assets/single-content/tabs-content/AssetActivitiesTabContent';

import './SingleAssetTabsContent.css';


function SingleAssetTabsContent({ assetDetails }) {
  const [activeTab, setActiveTab] = useState('nft-info');

  return (
    <div className="single-asset-tabs-content__component">
      <CommonTabList>
        <CommonTabItem
          active={activeTab === 'nft-info'}
          tabName="NFT info"
          onClick={() => { setActiveTab('nft-info'); }}
        />
        <CommonTabItem
          active={activeTab === 'activity'}
          tabName="Activity"
          onClick={() => { setActiveTab('activity'); }}
        />
        {/*<CommonTabItem*/}
        {/*  active={activeTab === 'offers'}*/}
        {/*  tabName="Offers"*/}
        {/*  onClick={() => { setActiveTab('offers'); }}*/}
        {/*/>*/}
      </CommonTabList>

      <div className="tab-content">
        {activeTab === 'nft-info' &&
          <SingleAssetInfoTabContent assetDetails={assetDetails} />
        }
        {activeTab === 'activity' &&
          <AssetActivitiesTabContent assetDetails={assetDetails} />
        }
        {activeTab === 'offers' &&
          <div>TBD...</div>
        }
      </div>
    </div>
  );
}

SingleAssetTabsContent.propTypes = {
  assetDetails: PropTypes.object.isRequired,
};

export default SingleAssetTabsContent;
