import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { verifyRegistrationEmail } from 'redux/users/actions';

import CommonMessage from 'components/common/messages/CommonMessage';
import CommonLoader from 'components/common/loaders/CommonLoader';

import { Link, useNavigate, useParams } from 'react-router-dom';

import './EmailVerificationContent.css';


function EmailVerificationContent(props) {
  const {
    verifyRegistrationEmail,
    verifyRegistrationEmailSuccess,
    verifyRegistrationEmailError,
  } = props;
  const { verificationKey } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    verifyRegistrationEmail(verificationKey);
  }, []);

  if (verifyRegistrationEmailSuccess) {
    navigate('/');
  }

  return (
    <div className="email-verification-content__component">
      <CommonLoader active={!verifyRegistrationEmailSuccess && !verifyRegistrationEmailError} />

      <h1>Email Verification Results</h1>

      {verifyRegistrationEmailError &&
        <CommonMessage error>
          <div>
            <p>Oops… We can&prime;t verify your email. Maybe it was already verified.
              If the issue remains, please send us an email: team@heymint.me</p>
            <p>You can log in <Link to="/login">here</Link></p>
          </div>
        </CommonMessage>
      }
    </div>
  );
}

EmailVerificationContent.propTypes = {
  verifyRegistrationEmail: PropTypes.func.isRequired,
  verificationKey: PropTypes.string.isRequired,
  verifyRegistrationEmailSuccess: PropTypes.object,
  verifyRegistrationEmailError: PropTypes.object,
};

EmailVerificationContent.defaultProps = {
  verifyRegistrationEmailSuccess: false,
  verifyRegistrationEmailError: null,
};

const mapStateToProps = state => (
  {
    verifyRegistrationEmailSuccess: state.usersReducer.verifyRegistrationEmailSuccess,
    verifyRegistrationEmailError: state.usersReducer.verifyRegistrationEmailError,
  }
);

export default connect(
  mapStateToProps,
  {
    verifyRegistrationEmail,
  },
)(EmailVerificationContent);
