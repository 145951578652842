import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { resetNotFound } from 'redux/common/actions';

import { useNavigate, Link } from 'react-router-dom';

import './NotFound.css';

const NotFound = ({ resetNotFound }) => {
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      resetNotFound();
    };
  }, []);

  const buttonHandler = () => {
    resetNotFound();
    navigate('/');
  };

  return (
    <div className="not-found__component">
      <div className="image-container">
        <div className="image" />
      </div>

      <div className="text-container">
        <div className="title">404</div>
        <div className="info-text">
          <div>Oops! This Page</div>
          <div>Does Not Exist.</div>
        </div>
        <div className="undertext">
          <div>We are sorry for the inconvenience.</div>
          <div>We will fix it soon.</div>
          <div>You might find these links useful!</div>
        </div>
        <div className="links-block">
          <Link to="/">Back to Home</Link>
          <Link to="/explore">Explore</Link>
        </div>
      </div>
    </div>
  );
};

NotFound.propTypes = {
  resetNotFound: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(null, { resetNotFound })(NotFound);
