import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  createCollection,
  validateCollectionTitle,
  clearValidateCollectionTitle,
} from 'redux/collections/actions';
import { fetchWalletBalance } from 'redux/blockchain/actions';

import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form, Dimmer, Loader, Checkbox } from 'semantic-ui-react';

import Button from 'components/common/elements/buttons/Button';
import TextField from 'components/redux-form-adapters/TextField';
import TextAreaField from 'components/redux-form-adapters/TextAreaField';
import SelectField from 'components/redux-form-adapters/SelectField';
import CommonMessage from 'components/common/messages/CommonMessage';

import UploadCollectionLogo from 'components/main/collections/common/UploadCollectionLogo';
import UploadCollectionBanner from 'components/main/collections/common/UploadCollectionBanner';

import MoonpayUpdateBalanceModal from 'components/main/profile-settings/moonpay/MoonpayUpdateBalanceModal';

import validate from './validate';

import './CreateCollectionForm.css';


function CreateCollectionForm(props) {
  const {
    handleSubmit,
    createCollection,
    validateCollectionTitle,
    clearValidateCollectionTitle,
    fetchWalletBalance,
    valid,
    title,
    createCollectionSuccess,
    createCollectionError,
    validateCollectionTitleError,
    change,
    walletBalance,
    me,
    projectSettings,
  } = props;

  const [collectionLogo, setCollectionLogo] = useState(null);
  const [collectionBanner, setCollectionBanner] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showMoonpayModal, setShowMoonpayModal] = useState(false);
  const [isAgree, setIsAgree] = useState(false);

  const creationFee = projectSettings?.platform_fees?.token_create_fee_coin;
  const isBalanceEnough = (Number(walletBalance?.balance)) > Number(creationFee) ? true : false;

  const checkboxText = `I understand the current marketplace fee is
    ~$${projectSettings?.platform_fees?.token_create_fee_usd} USD
     (${projectSettings?.platform_fees?.token_create_fee_coin} HBAR)
      and I agree to the Terms of Service.`;

  useEffect(() => {
    change('max_supply', 10);

    if (me?.wallet && !walletBalance) {
      fetchWalletBalance();
    }

    return () => {
      clearValidateCollectionTitle();
    };
  }, []);

  useEffect(() => {
    if (createCollectionSuccess) {
      fetchWalletBalance();
      setShowLoader(false);
    }
  }, [createCollectionSuccess]);

  useEffect(() => {
    if (createCollectionError) {
      setShowLoader(false);
    }
  }, [createCollectionError]);

  const _handleSubmit = values => {
    setShowLoader(true);
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });
    if (collectionLogo) {
      formData.append('logo', collectionLogo);
    }
    if (collectionBanner) {
      formData.append('banner', collectionBanner);
    }
    createCollection(formData);
  };

  const royaltyOptions = [
    {
      key: '1',
      text: '1%',
      value: '1',
    },
    {
      key: '5',
      text: '5%',
      value: '5',
    },
    {
      key: '10',
      text: '10%',
      value: '10',
    },
    {
      key: '15',
      text: '15%',
      value: '15',
    },
    {
      key: '25',
      text: '25%',
      value: '25',
    },
    {
      key: '30',
      text: '30%',
      value: '30',
    },
    {
      key: '35',
      text: '35%',
      value: '35',
    },
    {
      key: '40',
      text: '40%',
      value: '40',
    },
    {
      key: '45',
      text: '45%',
      value: '45',
    },
    {
      key: '50',
      text: '50%',
      value: '50',
    },
  ];

  return (
    <div className="create-collection-form__component">
      {/* Loader */}
      {showLoader &&
        <Dimmer active>
          <Loader>Please, wait... Collection creation is in progress.</Loader>
        </Dimmer>
      }

      <div className="form-wrapper">
        <Form
          onSubmit={handleSubmit(_handleSubmit)}
          error={!valid}
        >
          <Field
            label={<div>DISPLAY NAME <span>(required)</span></div>}
            component={TextField}
            placeholder="Enter collection name"
            name="title"
            type="text"
            labelPosition="right corner"
            required
            onBlur={() => {
              if (title) {
                validateCollectionTitle({ title });
              }
            }}
            serverError={validateCollectionTitleError?.error}
          />

          <Field
            label="DESCRIPTION"
            component={TextAreaField}
            placeholder="Spread some words about your token collection"
            name="description"
          />

          <Field
            label={<div>MAX SUPPLY <span>(required)</span></div>}
            component={TextField}
            placeholder="Select max supply"
            name="max_supply"
            type="text"
            labelPosition="right corner"
            required
          />

          <Field
            component={SelectField}
            label={<div>COLLECTION ROYALTY RATE <span>(required)</span></div>}
            name="royalty_rate"
            options={royaltyOptions}
            placeholder={'Select royalty rate'}
            onBlur={(e) => e.preventDefault()}
            required
            firstSelected
          />

          <h3>Social Connections</h3>

          <Field
            component={TextField}
            label={<div>YOUR WEBSITE</div>}
            name="site_link"
            placeholder={'https://yoursite.io'}
            type="text"
          />

          <Field
            component={TextField}
            label={<div>INSTAGRAM</div>}
            name="instagram_link"
            prefix='https://www.instagram.com/'
            placeholder='YourInstagramHandle'
            labelPosition="left"
            type="text"
          />

          <Field
            component={TextField}
            label={<div>DISCORD</div>}
            name="discord_link"
            prefix='https://www.discord.gg/'
            placeholder='abcdef'
            labelPosition="left"
            type="text"
          />

          <Field
            component={TextField}
            label={<div>TELEGRAM</div>}
            name="telegram_link"
            prefix='https://t.me/'
            placeholder='abcdef'
            labelPosition="left"
            type="text"
          />

          <Field
            component={TextField}
            label={<div>MEDIUM</div>}
            name="medium_link"
            prefix='https://www.medium.com/'
            placeholder='@YourMediumHandle'
            labelPosition="left"
            type="text"
          />

          <div className="checkbox-container">
            <Checkbox
              label={checkboxText}
              onChange={() => setIsAgree(!isAgree)}
            />
          </div>

          {!isBalanceEnough &&
            <CommonMessage warning>
              <div>
                You don&prime;t have enough <strong>HBAR</strong> to mint Collection.
              </div>
              <div
                className="add-hbar-button"
                onClick={() => setShowMoonpayModal(true)}
              >
                Add HBAR with card
              </div>
            </CommonMessage>
          }

          {/* Errors */}
          {createCollectionError &&
            <CommonMessage error errors={createCollectionError} />
          }

          <Button
            className="primary"
            submit
            disabled={
              !valid ||
              validateCollectionTitleError ||
              !isBalanceEnough ||
              !isAgree
            }
          >
            Create Collection
          </Button>

        </Form>
      </div>

      <div className="upload-wrapper">
        <UploadCollectionLogo setCollectionLogo={setCollectionLogo} />
        <UploadCollectionBanner setCollectionBanner={setCollectionBanner} />
      </div>

      <MoonpayUpdateBalanceModal
        open={showMoonpayModal}
        onClose={() => setShowMoonpayModal(false)}
      />
    </div>
  );
}

CreateCollectionForm.propTypes = {
  me: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  createCollection: PropTypes.func.isRequired,
  validateCollectionTitle: PropTypes.func.isRequired,
  clearValidateCollectionTitle: PropTypes.func.isRequired,
  fetchWalletBalance: PropTypes.func.isRequired,
  createCollectionSuccess: PropTypes.object,
  createCollectionError: PropTypes.object,
  valid: PropTypes.bool,
  title: PropTypes.string,
  walletBalance: PropTypes.object,
  validateCollectionTitleError: PropTypes.object,
  projectSettings: PropTypes.object,
  change: PropTypes.func,
};

CreateCollectionForm.defaultProps = {
  createCollectionSuccess: null,
  createCollectionError: null,
  valid: false,
  title: null,
  walletBalance: null,
  validateCollectionTitleError: null,
  projectSettings: null,
};

const mapStateToProps = state => {
  const selector = formValueSelector('CreateCollectionForm');
  return {
    title: selector(state, 'title'),
    createCollectionSuccess: state.collectionsReducer.createCollectionSuccess,
    createCollectionError: state.collectionsReducer.createCollectionError,
    validateCollectionTitleError: state.collectionsReducer.validateCollectionTitleError,
    walletBalance: state.blockchainReducer.walletBalance,
    me: state.usersReducer.me,
    projectSettings: state.commonReducer.projectSettings,
  };
};

export default connect(mapStateToProps, {
  createCollection,
  validateCollectionTitle,
  clearValidateCollectionTitle,
  fetchWalletBalance,
})(reduxForm({
  form: 'CreateCollectionForm',
  validate,
})(CreateCollectionForm));
