import React from 'react';

import { Helmet } from 'react-helmet';

import MainPageLayout from 'components/common/layouts/MainPageLayout';
import HowItWorksPageContent from 'components/main/info/HowItWorksPageContent';


const HowItWorksPage = () => (
  <MainPageLayout header={'How it works'}>
    <Helmet>
      <title>How it works, FAQ | HeyMint.me</title>
      <meta property="og:title" content="How it works, FAQ | HeyMint.me" />
      <meta
        name="description"
        content="Understand better how to mint NFTs on HeyMint.me. The amazing digital marketplace for NFTs of the world is here. Buy & Sell NFTs in a matter of seconds. "
      />
      <meta
        property="og:description"
        content="Understand better how to mint NFTs on HeyMint.me. The amazing digital marketplace for NFTs of the world is here. Buy & Sell NFTs in a matter of seconds. "
      />
    </Helmet>
    <HowItWorksPageContent />
  </MainPageLayout>
);

export default HowItWorksPage;
