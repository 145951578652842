import React from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import { patchCollection } from 'redux/collections/actions';

import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form } from 'semantic-ui-react';

import Button from 'components/common/elements/buttons/Button';
import TextField from 'components/redux-form-adapters/TextField';
import TextAreaField from 'components/redux-form-adapters/TextAreaField';
import CommonMessage from 'components/common/messages/CommonMessage';

import setInitialData from './setInitialData';
import validate from './validate';

import './EditCollectionForm.css';


function EditCollectionForm(props) {
  const {
    handleSubmit,
    patchCollection,
    patchCollectionError,
    valid,
    collectionDetails,
  } = props;

  const _handleSubmit = values => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      formData.append(key, values[key]);
    });
    patchCollection(collectionDetails.id, formData);
  };

  return (
    <div className="edit-collection-form__component">
      <h2>{collectionDetails?.title}</h2>
      <Form
        onSubmit={handleSubmit(_handleSubmit)}
        error={!valid}
      >
        <Field
          label="DESCRIPTION"
          component={TextAreaField}
          placeholder="Spread some words about your token collection"
          name="description"
        />

        <h3>Social Connections</h3>

        <Field
          component={TextField}
          label={<div>YOUR WEBSITE</div>}
          name="site_link"
          placeholder={'https://yoursite.io'}
          type="text"
        />

        <Field
          component={TextField}
          label={<div>INSTAGRAM</div>}
          name="instagram_link"
          prefix='https://www.instagram.com/'
          placeholder='YourInstagramHandle'
          labelPosition="left"
          type="text"
        />

        <Field
          component={TextField}
          label={<div>DISCORD</div>}
          name="discord_link"
          prefix='https://www.discord.gg/'
          placeholder='abcdef'
          labelPosition="left"
          type="text"
        />

        <Field
          component={TextField}
          label={<div>TELEGRAM</div>}
          name="telegram_link"
          prefix='https://t.me/'
          placeholder='abcdef'
          labelPosition="left"
          type="text"
        />

        <Field
          component={TextField}
          label={<div>MEDIUM</div>}
          name="medium_link"
          prefix='https://www.medium.com/'
          placeholder='@YourMediumHandle'
          labelPosition="left"
          type="text"
        />

        {/* Errors */}
        {patchCollectionError &&
          <CommonMessage error errors={patchCollectionError} />
        }

        <div className="buttons-block">
          <Button
            className="primary"
            submit
            disabled={!valid}
          >
            Submit changes &nbsp;&nbsp;&rarr;
          </Button>
        </div>

      </Form>
    </div>
  );
}

EditCollectionForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  patchCollection: PropTypes.func.isRequired,
  patchCollectionError: PropTypes.object,
  valid: PropTypes.bool,
  collectionDetails: PropTypes.object,
  initialValues: PropTypes.object.isRequired,
};

EditCollectionForm.defaultProps = {
  patchCollectionError: null,
  valid: null,
  collectionDetails: null,
};

const mapStateToProps = state => {
  const selector = formValueSelector('EditCollectionForm');
  return {
    title: selector(state, 'title'),
    initialValues: setInitialData(state.collectionsReducer.collectionDetails),
    patchCollectionError: state.collectionsReducer.patchCollectionError,
    collectionDetails: state.collectionsReducer.collectionDetails
  };
};

export default connect(mapStateToProps, {
  patchCollection,
})(reduxForm({
  form: 'EditCollectionForm',
  validate,
})(EditCollectionForm));
