import React from 'react';

import './ContactPoints.css';

const ContactPoints = () => (
  <div className="contact-points__component">
    <div className="contact-item">
      <div className="logo visit" />
      <div className="title">Visit Us</div>
      <div className="divider" />
      <div className="undertext">
        <div>Level 16, 55 Market Street</div>
        <div>Sydney NSW 2000, Australia</div>
      </div>
    </div>

    <div className="contact-item">
      <div className="logo contact" />
      <div className="title">Contact Us</div>
      <div className="divider" />
      <div className="undertext">
        <div>team@heymint.me</div>
        <div>+61 406 479 019</div>
      </div>
    </div>

    <div className="contact-item">
      <div className="logo persollo" />
      <div className="title">Persollo PTY LTD</div>
      <div className="divider" />
      <div className="undertext">
        <div>ACN: 607490611</div>
        <div>ABN: 38607490611</div>
      </div>
    </div>
  </div>
);

export default ContactPoints;
