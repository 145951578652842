import restapi from 'utils/restapi';
import { baseErrorsHandlerDecorator } from 'redux/utils';

import {
  CREATE_STRIPE_PAYMENT_INTENT_SUCCESS,
  CREATE_STRIPE_PAYMENT_INTENT_ERROR,
  CLEAR_CREATE_STRIPE_PAYMENT_INTENT,
  CONFIRM_STRIPE_PAYMENT_INTENT_SUCCESS,
  CONFIRM_STRIPE_PAYMENT_INTENT_ERROR,
  CLEAR_CONFIRM_STRIPE_PAYMENT_INTENT,
  FETCH_MOONPAY_URL_SUCCESS,
  FETCH_MOONPAY_URL_ERROR,
  CLEAR_FETCH_MOONPAY_URL,
} from 'redux/billing/types';


export const createStripePaymentIntent = amount => baseErrorsHandlerDecorator(
  CREATE_STRIPE_PAYMENT_INTENT_SUCCESS,
  CREATE_STRIPE_PAYMENT_INTENT_ERROR,
  () => restapi.post('v1/billing/stripe/payment-intent/', { amount }),
);

export const clearCreateStripePaymentIntent = () => dispatch => {
  dispatch({
    type: CLEAR_CREATE_STRIPE_PAYMENT_INTENT,
  });
};

export const confirmStripePaymentIntent = paymentIntentId => baseErrorsHandlerDecorator(
  CONFIRM_STRIPE_PAYMENT_INTENT_SUCCESS,
  CONFIRM_STRIPE_PAYMENT_INTENT_ERROR,
  () => restapi.post('v1/billing/stripe/payment-confirm/', { payment_intent_id: paymentIntentId }),
);

export const clearConfirmStripePaymentIntent = () => dispatch => {
  dispatch({
    type: CLEAR_CONFIRM_STRIPE_PAYMENT_INTENT,
  });
};

export const fetchMoonpayUrl = () => baseErrorsHandlerDecorator(
  FETCH_MOONPAY_URL_SUCCESS,
  FETCH_MOONPAY_URL_ERROR,
  () => restapi.get('v1/billing/moonpay/wallet/top-up/'),
  false,
);

export const clearFetchMoonpayUrl = () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_MOONPAY_URL,
  });
};
