import React, { useCallback } from 'react';
import PropTypes from "prop-types";

import { useDropzone } from 'react-dropzone';


import './UpdateProfileBanner.scss';


function UpdateProfileBanner(props) {
  const { patchUser, me } = props;

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles) {
      const formData = new FormData();
      formData.append('cover', acceptedFiles[0]);
      patchUser(formData);
    }
  }, []);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/png',
    maxFiles: 1,
  });

  return (
    <div className="upload-profile-banner__component">
      <div className="title">Banner image</div>
      <div className="undertext">
        Drag or choose your file to upload. This image will appear
        at the top of your Profile page. Avoid including too much
        text in this banner image, as the dimensions change on different
        devices.
      </div>
      <div
        className="dropzone-area"
        style={{ backgroundImage: me.cover && `url(${me.cover})` }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {!me.cover &&
          <div className="info-block">
            <div className="icon" />
            <div className="text">
              <div>We recommend a 1800x400px</div>
              <div>JPG, PNG (10MB max size)</div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

UpdateProfileBanner.propTypes = {
  patchUser: PropTypes.func.isRequired,
  me: PropTypes.object,
};

UpdateProfileBanner.defaultProps = {
  me: null,
};

export default UpdateProfileBanner;
