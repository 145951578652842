import React from 'react';
import PropTypes from 'prop-types';

import { TextArea, Form, Label } from 'semantic-ui-react';

import './TextAreaField.css';


const TextAreaField = props => {
  const {
    input,
    label,
    placeholder,
    disabled,
    autoHeight,
    meta: { touched, error },
  } = props;

  if (!('id' in input)) {
    input.id = `id-${input.name}`;
  }

  return (
    <Form.Field
      className="text-area-field__component"
      error={Boolean(touched && error)}
    >
      {label &&
      <div className="label">{label}</div>
      }

      <TextArea
        {...input}
        disabled={disabled}
        placeholder={placeholder}
        labelPosition="right corner"
        autoHeight={autoHeight}
      />

      {touched && error &&
        <div className="errors">{error}</div>
      }
    </Form.Field>
  );
};

TextAreaField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  autoHeight: PropTypes.bool,
};

TextAreaField.defaultProps = {
  label: null,
  placeholder: null,
  disabled: false,
  autoHeight: null,
};

export default TextAreaField;
