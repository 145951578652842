import React from 'react';
import PropTypes from "prop-types";

import CommonModal from 'components/common/elements/modals/CommonModal';
import AssetListingForm from 'components/main/assets/single-content/actions/AssetListingForm';


function AssetListingModal(props) {
  const {
    open,
    onClose,
    onUnmount,
  } = props;

  return (
    <CommonModal
      className="asset-listing-modal__component"
      open={open}
      onClose={onClose}
      onUnmount={onUnmount}
      title={'List item for sale'}
    >
      <AssetListingForm />
    </CommonModal>
  );
}

AssetListingModal.propTypes = {
  onUnmount: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

AssetListingModal.defaultProps = {
  open: null,
};

export default AssetListingModal;
