import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchMoonpayUrl,
  clearFetchMoonpayUrl,
} from 'redux/billing/actions';

import CommonTabList from 'components/common/elements/tabs/CommonTabList';
import CommonTabItem from 'components/common/elements/tabs/CommonTabItem';
import CommonModal from 'components/common/elements/modals/CommonModal';
import CommonMessage from 'components/common/messages/CommonMessage';

import ProfileWallet from 'components/main/profile/ProfileWallet';
import MoonpayFrame from 'components/main/profile-settings/moonpay/MoonpayFrame';

import { MOONPAY_PK } from 'shared/constants';

import './MoonpayUpdateBalanceModal.css';


function MoonpayUpdateBalanceModal(props) {
  const {
    fetchMoonpayUrl,
    clearFetchMoonpayUrl,
    open,
    onClose,
    moonpayUrl,
    moonpayUrlError,
    me,
  } = props;

  const [moonpayData, setMoonpayData] = useState(null);
  const [moonpayError, setMoonpayError] = useState(null);
  const [activeTab, setActiveTab] = useState('crypto');

  useEffect(() => {
    if (moonpayData) {
      fetchMoonpayUrl();

      if (moonpayData.isAllowed && moonpayData.isBuyAllowed) {
        setActiveTab('card');
      }
    }
  }, [moonpayData]);

  const onModalMount = () => {
    fetch(`https://api.moonpay.com/v4/ip_address?apiKey=${MOONPAY_PK}`)
      .then(response => response.json())
      .then(data => {
        setMoonpayData(data);
      })
      .catch(error => {
        setMoonpayError(error);
      });
  };

  const onModalUnmount = () => {
    setMoonpayData(null);
    setMoonpayError(null);
    setActiveTab('crypto');
    clearFetchMoonpayUrl();
  };

  return (
    <CommonModal
      className="moonpay-update-balance-modal__component"
      title={'Add Funds'}
      open={open}
      onClose={onClose}
      onUnmount={onModalUnmount}
      onMount={onModalMount}f
    >

      {!moonpayError && !moonpayData &&
        <div className="loading-text">Loading...</div>
      }

      {!moonpayError && moonpayData &&
        <Fragment>
          <CommonTabList center>
            <CommonTabItem
              active={activeTab === 'crypto'}
              tabName="Deposit crypto"
              onClick={() => { setActiveTab('crypto'); }}
            />
            <CommonTabItem
              active={activeTab === 'card'}
              tabName="Buy HBAR with card"
              onClick={() => { setActiveTab('card'); }}
            />
          </CommonTabList>

          {activeTab === 'crypto' &&
            <div className="crypto-block">
              <div className="wallet-icon" />
              <div className="infotext">
                Transfer funds from an exchange or another wallet to your wallet address below:
              </div>
              <ProfileWallet profileData={me} />
              <div className="undertext">
                Only send HBAR token to this address
              </div>
            </div>
          }

          {activeTab === 'card' &&
            <Fragment>
              {moonpayUrl &&
                <MoonpayFrame
                  frameUrl={moonpayUrl}
                />
              }
              {moonpayUrlError &&
                <CommonMessage error errors={moonpayUrlError} />
              }
            </Fragment>
          }
        </Fragment>
      }

      {/* Moonpay Error */}
      {moonpayError &&
        <CommonMessage error errors={moonpayError} />
      }

    </CommonModal>
  );
}

MoonpayUpdateBalanceModal.propTypes = {
  fetchMoonpayUrl: PropTypes.func.isRequired,
  clearFetchMoonpayUrl: PropTypes.func.isRequired,
  moonpayUrl: PropTypes.string,
  moonpayUrlError: PropTypes.object,
  me: PropTypes.object,
};

MoonpayUpdateBalanceModal.defaultProps = {
  moonpayUrl: null,
  moonpayUrlError: null,
  me: null,
};

const mapStateToProps = state => (
  {
    moonpayUrl: state.billingReducer.moonpayUrl,
    moonpayUrlError: state.billingReducer.moonpayUrlError,
    me: state.usersReducer.me,
  }
);

export default connect(
  mapStateToProps,
  {
    fetchMoonpayUrl,
    clearFetchMoonpayUrl,
  },
)(MoonpayUpdateBalanceModal);
