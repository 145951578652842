import React from 'react';
import PropTypes from "prop-types";

import CommonDropdown from 'components/common/elements/dropdowns/CommonDropdown';


function AssetsSaleTypeSelector(props) {
  const {
    currentFilters,
    setCurrentFilters,
  }  = props;

  const dropdownOptions = [
    { key: 'buy_now', value: 'buy_now', text: 'Buy now' },
    { key: 'not_for_sale', value: 'not_for_sale', text: 'Not for sale' },
    // { key: 'has_offers', value: 'has_offers', text: 'Has offers' },
  ];

  return (
    <div className="assets-sale-type-selector__component">
      <CommonDropdown
        clearable
        multiple
        selection
        options={dropdownOptions}
        placeholder='Sale Type'
        onChange={(e, data) => {
          setCurrentFilters({ ...currentFilters, saleType: data.value});
        }}
      />
    </div>
  );
}

AssetsSaleTypeSelector.propTypes = {
  setCurrentFilters: PropTypes.func.isRequired,
  currentFilters: PropTypes.object,
};

AssetsSaleTypeSelector.defaultProps = {
  currentFilters: null,
};

export default AssetsSaleTypeSelector;
