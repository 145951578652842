import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import InfiniteScroll from 'react-infinite-scroller';

import CommonMessage from 'components/common/messages/CommonMessage';
import NoItemsPlaceholder from 'components/common/placeholders/NoItemsPlaceholder';
import SegmentLoader from 'components/common/loaders/SegmentLoader';

import CollectionListItem from 'components/main/collections/common/CollectionListItem';

import './CollectionsList.css';

function CollectionsList(props) {
  const {
    loadFunction,
    currentList,
    currentListError,
  } = props;

  const [listItems, setListItems] = useState([]);
  const [fetching, setFetching] = useState(false);

  // Set Data
  useEffect(() => {
    if (currentList) {
      if (fetching) {
        setListItems([...listItems, ...currentList.results]);
        setFetching(false);
      } else {
        setListItems(currentList.results);
      }
    }
  }, [currentList]);

  // Fetch Additional Data
  useEffect(() => {
    if (fetching && currentList?.next) {
      loadFunction(currentList?.next);
    }
  }, [fetching]);

  return (
    <div className="collections-list__component">
      {/* Initial Loader */}
      {!currentList && !currentListError && <SegmentLoader />}

      {/* Empty Placeholder */}
      {currentList && listItems?.length === 0 &&
        <NoItemsPlaceholder title="No collections to display" />
      }

      {/* List */}
      {listItems?.length > 0 &&
        <InfiniteScroll
          className={'list-wrapper'}
          loadMore={() => {
            if (!fetching) {
              setFetching(true);
            }
          }}
          hasMore={!!currentList?.next}
        >
          {listItems.map(item => (
            <CollectionListItem
              key={`collection-list-item-${item.id}`}
              item={item}
            />
          ))}
        </InfiniteScroll>
      }

      {/* Additional Uploading Loader */}
      {fetching && <SegmentLoader />}

      {/* Errors */}
      {currentListError &&
        <CommonMessage error errors={currentListError} />
      }
    </div>
  );
}

CollectionsList.propTypes = {
  loadFunction: PropTypes.func.isRequired,
  currentList: PropTypes.object,
  currentListError: PropTypes.object,
};

CollectionsList.defaultProps = {
  currentList: null,
  currentListError: null,
};

export default CollectionsList;
