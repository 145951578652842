import React from 'react';
import PropTypes from "prop-types";

import AssetDetailsInfo from 'components/main/assets/single-content/AssetDetailsInfo';
import SingleAssetTabsContent from 'components/main/assets/single-content/tabs-content/SingleAssetTabsContent';
import AssetPriceInfo from 'components/main/assets/single-content/AssetPriceInfo';
import AssetOwnerActions from 'components/main/assets/single-content/actions/AssetOwnerActions';

import './AssetDetails.css';


function AssetDetails(props) {
  const {
    assetDetails,
    me,
  } = props;

  if (!assetDetails) return null;

  const isOwner = me?.id === assetDetails?.owner?.user_id;

  return (
    <div className="asset-details__component">
      {isOwner &&
        <AssetOwnerActions
          assetDetails={assetDetails}
        />
      }
      <div className="main-content-block">
        <div className="image-container">
          <div className="image">
            <img src={assetDetails.nft_media_cover} alt="" />
          </div>
        </div>
        <div className="info-conteiner">
          <AssetDetailsInfo assetDetails={assetDetails} />
          <AssetPriceInfo
            assetDetails={assetDetails}
            isOwner={isOwner}
          />
        </div>
      </div>

      <SingleAssetTabsContent assetDetails={assetDetails} />
    </div>
  );
}

AssetDetails.propTypes = {
  assetDetails: PropTypes.object.isRequired,
  me: PropTypes.object,
};

AssetDetails.defaultProps = {
  me: null,
};

export default AssetDetails;
