import React, { useState, useEffect, useRef  } from 'react';
import PropTypes from 'prop-types';

import { Form, Label, Loader } from 'semantic-ui-react';
import logoHeyMint from './icons/logo_heymint.svg';
import ic_checked from './icons/checked.svg';

import './InputTextField.css';


const InputTextField = props => {
  const {
    input,
    label,
    type,
    disabled,
    prefix,
    initialFocused,
    serverError,
    meta: {asyncValidating, touched, error },
    showCheckIcon,
  } = props;

  const inputReference = useRef(null);
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    if (initialFocused) {
       inputReference.current.focus();
    }
  }, [initialFocused]);

  if (!('id' in input)) {
    input.id = `id_${input.name}`;
  }

  const isValid =  touched && !error && !serverError;

  return (
    <Form.Field
      className={`input-text-field__component ${isValid ? 'valid' : ''}`}
      error={Boolean(touched && (error || serverError))}
    >
      <div className={`fieldset ${isFocused ? 'is-focused' : ''} ${touched && !input.value ? 'is-empty' : ''}`}>
        
        {prefix && 
          <span className="label-prefix">
            <img src={logoHeyMint}/>
            {prefix}
          </span>
        }
      
        <input
          {...input}
          ref={inputReference}
          disabled={disabled}
          type={type}
          data-empty={Boolean(!input.value)}
          onFocus={(e) => { input.onFocus(e); setFocused(true); }}
          onBlur={(e) => { input.onBlur(e); setFocused(false); }}
          className='async-validating'
        />
        {asyncValidating ? <Loader active size='mini' inline />: ""}
        {!asyncValidating && showCheckIcon && isValid && <img src={ic_checked}/>}
        
        {label &&
          <label htmlFor={input.id}>{label}</label>
        }
      </div>

      {touched && (error || serverError) &&
        <Label
          color={'red'}
          pointing
        >
          {error || serverError}
        </Label>
      }
    </Form.Field>
  );
};

InputTextField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  initialFocused: PropTypes.bool,
  showCheckIcon: PropTypes.bool,
  serverError: PropTypes.string,
};

InputTextField.defaultProps = {
  label: null,
  prefix: null,
  disabled: false,
  initialFocused: false,
  serverError: null,
};

export default InputTextField;
