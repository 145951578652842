import React from 'react';

import { Helmet } from 'react-helmet';

import MainPageLayout from 'components/common/layouts/MainPageLayout';
import ExplorePageContent from 'components/main/explore/ExplorePageContent';


const ExplorePage = () => (
  <MainPageLayout header={'Explore NFT'}>
    <Helmet>
      <title>Explore All NFTs in One place | HeyMint.me</title>
      <meta property="og:title" content="Explore All NFTs in One place | HeyMint.me" />
      <meta
        name="description"
        content="Explore the amazing digital marketplace for NFTs of the world is here. Buy & Sell NFTs in a matter of seconds. Lowest Fees to mint NFT"
      />
      <meta
        property="og:description"
        content="Explore the amazing digital marketplace for NFTs of the world is here. Buy & Sell NFTs in a matter of seconds. Lowest Fees to mint NFT"
      />
    </Helmet>
    <ExplorePageContent />
  </MainPageLayout>
);

export default ExplorePage;
