export const toThousandSeparator = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const modifyPrice = price => {
  const _price = parseFloat(price).toFixed(2);
  if (_price % 1 === 0) {
    return Math.floor(Number(_price));
  }
  return _price;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};


/**
 * performDownload function
 */

export const performDownload = (fileName, fileContent) => {
  if (fileContent === 'no data') {
    return false;
  }
  const blob = new Blob([fileContent]);
  // create hidden link
  const element = document.createElement('a');
  document.body.appendChild(element);
  element.setAttribute('href', window.URL.createObjectURL(blob));
  element.setAttribute('download', fileName);
  element.style.display = '';
  element.click();
};
