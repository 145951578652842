import React from 'react';

import SingleCollectionPageContent from 'components/main/collections/single-content/SingleCollectionPageContent';


const SingleCollectionPage = () => (
  <SingleCollectionPageContent />
);

export default SingleCollectionPage;
