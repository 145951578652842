import React from 'react';
import PropTypes from "prop-types";

import './NoItemsPlaceholder.css';


const NoItemsPlaceholder = ({ title, text }) => (
  <div className="no-items-placeholder__component">
    <div className="image" />
    {title &&
      <div className="title">{title}</div>
    }
    {text &&
      <div className="text">{text}</div>
    }
  </div>
);

NoItemsPlaceholder.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

NoItemsPlaceholder.defaultProps = {
  title: null,
  text: null,
};

export default NoItemsPlaceholder;
