import React, { useState } from 'react';
import PropTypes from "prop-types";

import './FixedNotification.css';


function FixedNotification(props) {
  const {
    onFadeComplete,
    children,
    displayTime,
    afterDisplayTime,
  } = props;

  const [fadeout, setFadeout] = useState(false);

  const _onFadeComplete = () => {
    if (onFadeComplete) {
      setFadeout(false);
      setTimeout(
        () => {
          onFadeComplete();
        }, afterDisplayTime);
    }
  }

  if (onFadeComplete) {
    setTimeout(() => _onFadeComplete(), displayTime);
  }

  return (
    <div className={`fixed-notification__component${fadeout ? ' fadeout' : ''}`}>
      <div className="content-wrapper">
        {children}
      </div>
    </div>
  );
}

FixedNotification.propTypes = {
  onFadeComplete: PropTypes.func.isRequired,
  displayTime: PropTypes.number.isRequired,
  afterDisplayTime: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
};

FixedNotification.defaultProps = {
  displayTime: 1000,
  afterDisplayTime: 1000,
};

export default FixedNotification;
