import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import {
  fetchMyCollectionsList,
  clearFetchMyCollectionsList,
} from 'redux/collections/actions';
import { clearCreateAsset } from 'redux/assets/actions';
import { fetchMe } from 'redux/users/actions';

import { useParams, Link } from 'react-router-dom';

import CommonMessage from 'components/common/messages/CommonMessage';
import CreateAssetForm from 'components/main/assets/create-content/CreateAssetForm';
import CreateAssetSuccessModal from 'components/main/assets/create-content/CreateAssetSuccessModal';

import './CreateAssetPageContent.css';
import PropTypes from "prop-types";


function CreateAssetPageContent(props) {
  const {
    fetchMyCollectionsList,
    clearFetchMyCollectionsList,
    clearCreateAsset,
    fetchMe,
    myCollectionsList,
    createAssetSuccess,
    createAssetError,
    me,
    projectSettings,
  } = props;

  const pageParams = useParams();

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    fetchMyCollectionsList();

    return () => {
      clearFetchMyCollectionsList();
      clearCreateAsset();
      setShowSuccessModal(false);
    };
  }, []);

  useEffect(() => {
    if (createAssetSuccess) {
      fetchMe();
      setShowSuccessModal(true);
    }
  }, [createAssetSuccess]);

  const collectionsOptions = myCollectionsList ? myCollectionsList.results.map(
    collection => (
      {
        key: collection.id,
        text: collection.title,
        value: collection.id,
        slug: collection.slug,
      }
    )
  ) : [];

  if (me && !me.wallet) {
    return (
      <CommonMessage warning>
        In order to create & mint new NFT, you need to create a wallet in&nbsp;
        <Link to={'/profile/settings'}>Profile settings</Link>
      </CommonMessage>
    );
  }

  if (myCollectionsList?.count === 0) {
    return (
      <CommonMessage warning>
        In order to create & mint new NFT, you need to create a collection in&nbsp;
        <Link to={'/collection/create'}>here</Link>
      </CommonMessage>
    );
  }

  return (
    <div className="create-asset-page-content__component">
      <CreateAssetForm
        collectionsOptions={collectionsOptions}
        createAssetError={createAssetError}
        createAssetSuccess={createAssetSuccess}
        pageParams={pageParams}
        me={me}
        projectSettings={projectSettings}
        myCollectionsList={myCollectionsList}
      />

      <CreateAssetSuccessModal
        open={showSuccessModal}
        item={createAssetSuccess}
        onClose={() => setShowSuccessModal(false)}
        onUnmount={() => {
          clearCreateAsset();
        }}
      />
    </div>
  );
}

CreateAssetPageContent.propTypes = {
  fetchMyCollectionsList: PropTypes.func.isRequired,
  clearFetchMyCollectionsList: PropTypes.func.isRequired,
  clearCreateAsset: PropTypes.func.isRequired,
  fetchMe: PropTypes.func.isRequired,
  myCollectionsList: PropTypes.arrayOf(PropTypes.element),
  createAssetSuccess: PropTypes.object,
  createAssetError: PropTypes.object,
  me: PropTypes.object,
  projectSettings: PropTypes.object,
};

CreateAssetPageContent.defaultProps = {
  myCollectionsList: null,
  createAssetSuccess: null,
  createAssetError: null,
  me: null,
  projectSettings: null,
};

const mapStateToProps = state => (
  {
    myCollectionsList: state.collectionsReducer.myCollectionsList,
    createAssetSuccess: state.assetsReducer.createAssetSuccess,
    createAssetError: state.assetsReducer.createAssetError,
    me: state.usersReducer.me,
    projectSettings: state.commonReducer.projectSettings,
  }
);

export default connect(mapStateToProps, {
  fetchMyCollectionsList,
  clearFetchMyCollectionsList,
  clearCreateAsset,
  fetchMe,
})(CreateAssetPageContent);
