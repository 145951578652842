export const FETCH_BLOCKCHAINS_LIST = 'BLOCKCHAIN/FETCH_BLOCKCHAINS_LIST';
export const FETCH_BLOCKCHAINS_LIST_ERROR = 'BLOCKCHAIN/FETCH_BLOCKCHAINS_LIST_ERROR';

export const CREATE_HEDERA_ACCOUNT_SUCCESS = 'BLOCKCHAIN/CREATE_HEDERA_ACCOUNT_SUCCESS';
export const CREATE_HEDERA_ACCOUNT_ERROR = 'BLOCKCHAIN/CREATE_HEDERA_ACCOUNT_ERROR';
export const CLEAR_CREATE_HEDERA_ACCOUNT = 'BLOCKCHAIN/CLEAR_CREATE_HEDERA_ACCOUNT';

export const GENERATE_MNEMONIC_SUCCESS = 'BLOCKCHAIN/GENERATE_MNEMONIC_SUCCESS';
export const GENERATE_MNEMONIC_ERROR = 'BLOCKCHAIN/GENERATE_MNEMONIC_ERROR';
export const CLEAR_GENERATE_MNEMONIC = 'BLOCKCHAIN/CLEAR_GENERATE_MNEMONIC';

export const FETCH_WALLET_BALANCE = 'BLOCKCHAIN/FETCH_WALLET_BALANCE';
export const FETCH_WALLET_BALANCE_ERROR = 'BLOCKCHAIN/FETCH_WALLET_BALANCE_ERROR';
export const CLEAR_FETCH_WALLET_BALANCE = 'BLOCKCHAIN/CLEAR_FETCH_WALLET_BALANCE';
