import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchCollectionAssetsList,
  clearFetchCollectionAssetsList,
} from 'redux/assets/actions';

import AssetsFiltersBar from 'components/main/assets/filters-sorting/AssetsFiltersBar';

import AssetList from 'components/main/assets/common/AssetList';

import { usePrevious } from 'utils/custom-hooks/usePrevious';

import './CollectionAssetsList.css';


function CollectionAssetsList(props) {
  const {
    fetchCollectionAssetsList,
    clearFetchCollectionAssetsList,
    collectionDetails,
    collectionAssetsList,
    collectionAssetsListError,
  } = props;

  const [currentFilters, setCurrentFilters] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const prevFilters = usePrevious(currentFilters);

  useEffect(() => {
    return () => {
      clearFetchCollectionAssetsList();
    };
  }, []);

  useEffect(() => {
    if (prevFilters !== currentFilters) {
      fetchCollectionAssetsList(collectionDetails.slug, 1, currentFilters);
    } else {
      fetchCollectionAssetsList(collectionDetails.slug, currentPage, currentFilters);
    }
  }, [currentPage, currentFilters]);

  const handlePaginationPageClick = data => {
    setCurrentPage(data.selected + 1);
  };

  return (
    <div className="collection-assets-list__component">
      {/* Filters */}
      <AssetsFiltersBar
        currentFilters={currentFilters}
        setCurrentFilters={setCurrentFilters}
      />

      {/* Assets List */}
      <AssetList
        list={collectionAssetsList}
        listError={collectionAssetsListError}
        handlePaginationPageClick={handlePaginationPageClick}
      />
    </div>
  );
}

CollectionAssetsList.propTypes = {
  fetchCollectionAssetsList: PropTypes.func.isRequired,
  clearFetchCollectionAssetsList: PropTypes.func.isRequired,
  collectionDetails: PropTypes.object,
  collectionAssetsList: PropTypes.arrayOf(PropTypes.element),
  collectionAssetsListError: PropTypes.object,
};

CollectionAssetsList.defaultProps = {
  collectionDetails: null,
  collectionAssetsList: null,
  collectionAssetsListError: null,
};

const mapStateToProps = state => (
  {
    collectionAssetsList: state.assetsReducer.collectionAssetsList,
    collectionAssetsListError: state.assetsReducer.collectionAssetsListError,
  }
);

export default connect(mapStateToProps, {
  fetchCollectionAssetsList,
  clearFetchCollectionAssetsList,
})(CollectionAssetsList);
