import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from "prop-types";

import { useDropzone } from 'react-dropzone';

import './UploadCollectionLogo.css';


function UploadCollectionLogo(props) {
  const {
    setCollectionLogo,
    initialLogo,
  } = props;

  const [currentLogo, setCurrentLogo] = useState(null);

  useEffect(() => {
    if (initialLogo) {
      setCurrentLogo({ preview: initialLogo });
    }
  }, []);

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles) {
      setCollectionLogo(acceptedFiles[0]);
      setCurrentLogo(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
      }))[0]);
    }
  }, []);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/png, image/gif, image/svg',
    maxFiles: 1,
  });

  return (
    <div className="upload-collection-logo__component">
      <div className="title">Collection Logo</div>
      <div className="undertext">
        We recommend an image of at least 400x400. Gifs work too
      </div>
      <div className="dropzone-container">
        <div
          className="dropzone-area"
          style={{ backgroundImage: currentLogo && currentLogo.preview && `url(${currentLogo.preview})` }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />

          {!currentLogo &&
            <div className="info-block">
              <div className="icon" />
            </div>
          }
        </div>
      </div>
    </div>
  );
}

UploadCollectionLogo.propTypes = {
  setCollectionLogo: PropTypes.func.isRequired,
  setCurrentLogo: PropTypes.func.isRequired,
  currentLogo: PropTypes.object,
  initialLogo: PropTypes.string,
};

UploadCollectionLogo.defaultProps = {
  currentLogo: null,
  initialLogo: null,
};

export default UploadCollectionLogo;
