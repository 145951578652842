import React, { useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchAssetActivitiesList,
  clearFetchAssetActivitiesList,
} from 'redux/assets/actions';

import { Link } from 'react-router-dom';

import CommonTable from 'components/common/elements/tables/common-table/CommonTable';
import SegmentLoader from 'components/common/loaders/SegmentLoader';
import CommonMessage from 'components/common/messages/CommonMessage';
import NoItemsPlaceholder from 'components/common/placeholders/NoItemsPlaceholder';

import './AssetActivitiesTabContent.css';


function AssetActivitiesTabContent(props) {
  const {
    fetchAssetActivitiesList,
    clearFetchAssetActivitiesList,
    assetDetails,
    assetActivitiesList,
    assetActivitiesListError,
  } = props;

  useEffect(() => {
    if (!assetActivitiesList) {
      fetchAssetActivitiesList(assetDetails.token_id, assetDetails.serial_number);
    }
    return () => {
      clearFetchAssetActivitiesList();
    }
  }, []);

  const renderActivitiesTable = (
    <CommonTable className="mobile-768">
      <CommonTable.Header>
        <CommonTable.Cell></CommonTable.Cell>
        <CommonTable.Cell>Price</CommonTable.Cell>
        <CommonTable.Cell>From</CommonTable.Cell>
        <CommonTable.Cell>To</CommonTable.Cell>
        <CommonTable.Cell>Date</CommonTable.Cell>
        <CommonTable.Cell>Transaction</CommonTable.Cell>
      </CommonTable.Header>

      {assetActivitiesList?.transactions.map(item => (
        <CommonTable.Row key={`asset-transaction-key-${item.transaction_id}`}>
          <CommonTable.Cell>
            <div className="parameter"></div>
            <div className="value">{item?.type}</div>
          </CommonTable.Cell>
          <CommonTable.Cell>
            <div className="parameter">Price</div>
            <div className="value">
              {item?.price}&nbsp;
              {item.currency_code ? item.currency_code.toUpperCase() : ''}
            </div>
          </CommonTable.Cell>
          <CommonTable.Cell>
            <div className="parameter">From</div>
            <div className="value">
              {item?.sender?.username &&
                <Link to={`/profile/${item.sender.username}`}>
                  {item.sender.username}
                </Link>
              }
            </div>
          </CommonTable.Cell>
          <CommonTable.Cell>
            <div className="parameter">To</div>
            <div className="value">
              {item?.receiver?.username &&
                <Link to={`/profile/${item.receiver.username}`}>
                  {item.receiver.username}
                </Link>
              }
            </div>
          </CommonTable.Cell>
          <CommonTable.Cell>
            <div className="parameter">Date</div>
            <div className="value">{item?.consensus_timestamp}</div>
          </CommonTable.Cell>
          <CommonTable.Cell>
            <div className="parameter">Transaction</div>
            <div className="value">{item?.transaction_id}</div>
          </CommonTable.Cell>
        </CommonTable.Row>
      ))}

    </CommonTable>
  );

  return (
    <div className="asset-activities-tab-content__component">
      {/* Loader */}
      {!assetActivitiesList && !assetActivitiesListError &&
        <SegmentLoader />
      }

      {/* Empty Placeholder */}
      {assetActivitiesList?.transactions?.length === 0 &&
        <NoItemsPlaceholder title="No data to display" />
      }

      {/* List */}
      {assetActivitiesList?.transactions?.length > 0 &&
        renderActivitiesTable
      }

      {/* Errors */}
      {assetActivitiesListError &&
        <CommonMessage error errors={assetActivitiesListError} />
      }
    </div>
  );
}

AssetActivitiesTabContent.propTypes = {
  fetchAssetActivitiesList: PropTypes.func.isRequired,
  clearFetchAssetActivitiesList: PropTypes.func.isRequired,
  assetDetails: PropTypes.object.isRequired,
  assetActivitiesList: PropTypes.arrayOf(PropTypes.element),
  assetActivitiesListError: PropTypes.object,
};

AssetActivitiesTabContent.defaultProps = {
  assetActivitiesList: null,
  assetActivitiesListError: null,
};

const mapStateToProps = state => (
  {
    assetActivitiesList: state.assetsReducer.assetActivitiesList,
    assetActivitiesListError: state.assetsReducer.assetActivitiesListError,
  }
);

export default connect(mapStateToProps, {
  fetchAssetActivitiesList,
  clearFetchAssetActivitiesList,
})(AssetActivitiesTabContent);
