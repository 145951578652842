import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchBlockchainsList,
  createHederaAccount,
  clearCreateHederaAccount,
  generateHederaMnemonic,
  clearGenerateHederaMnemonic,
} from 'redux/blockchain/actions';
import { fetchMe } from 'redux/users/actions';

import { Dimmer, Loader, Checkbox } from 'semantic-ui-react';

import Button from 'components/common/elements/buttons/Button';
import CommonMessage from 'components/common/messages/CommonMessage';
import CommonModal from 'components/common/elements/modals/CommonModal';

import './ConnectHederaAccountModal.css';


function ConnectHederaAccountModal(props) {
  const {
    fetchBlockchainsList,
    createHederaAccount,
    clearCreateHederaAccount,
    generateHederaMnemonic,
    clearGenerateHederaMnemonic,
    fetchMe,
    open,
    onClose,
    blockchainsList,
    createHederaAccountSuccess,
    createHederaAccountError,
    generateMnemonicSuccess,
    generateMnemonicError,
  } = props;

  const [showLoader, setShowLoader] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const hederaId = blockchainsList &&
    blockchainsList.filter(item => item.name === 'Hedera')[0].id;

  useEffect(() => {
    if (!blockchainsList) {
      fetchBlockchainsList();
    }
  }, []);

  useEffect(() => {
    if (createHederaAccountSuccess) {
      fetchMe(false);
      setShowLoader(false);
      clearGenerateHederaMnemonic();
    }
  }, [createHederaAccountSuccess]);

  useEffect(() => {
    if (createHederaAccountError) {
      setShowLoader(false);
      clearGenerateHederaMnemonic();
    }
  }, [createHederaAccountError]);

  const onGenerateMnemonic = () => {
    generateHederaMnemonic(hederaId);
  };

  const onCreateAccount = () => {
    let mnemonic = '';
    for (let i = 0; i < generateMnemonicSuccess?.mnemonic.length; i += 1) {
      mnemonic += `${generateMnemonicSuccess?.mnemonic[i]} `;
    }

    setShowLoader(true);

    if (hederaId) {
      createHederaAccount(hederaId, mnemonic.trim());
    }
  };

  const renderConnectionStep = (
    <div className="connection-step">
      <div className="info-text">
        It is easy as 1-2-3 to create a new wallet for storage of your NFTs.
        Please click the button below and follow the process
      </div>
      <Button
        className="primary"
        onClick={onGenerateMnemonic}
      >
        Continue
      </Button>
    </div>
  );

  const renderCreationStep = (
    <div className="creation-step">

      <div className="phrase-info">
        <div className="title">Secret Backup Phrase</div>
        <div className="info-text">
          Your secret backup phrase makes it easy to back up
          and restore your account. Write down the 12-word
          phrase below. Keep it in a safe place.
        </div>
        <div className="info-text">
          <span>WARNING:</span> Never disclose your backup phrase.
          Anyone with this phrase can take your account forever.
        </div>
        <div className="phrase-container">
          {generateMnemonicSuccess?.mnemonic.map(item => (
            <div className="word-item">{item}</div>
          ))}
        </div>
      </div>

      <div className="checkbox-container">
        <Checkbox
          label="I have saved my mnemonic seed safely."
          onChange={() => setIsCopied(!isCopied)}
        />
      </div>

      <Button
        className="primary"
        onClick={onCreateAccount}
        disabled={!isCopied}
      >
        Create New Hedera Wallet
      </Button>
    </div>
  );

  const renderSuccessStep = () => {
    return (
      <div className="success-step">
        <div className="undertext">
          Thanks for creating an account on Hedera Hashgraph.
        </div>

        <div className="info-wrapper">
          <div className="section account-info">
            <div className="title-block">
              <div className="icon" />
              <div className="title">Hedera Account</div>
            </div>

            <div className="parameter">
              <div className="label">Account ID:</div>
              <div className="value">{createHederaAccountSuccess.address}</div>
            </div>
            <div className="parameter">
              <div className="label">Public Key: </div>
              <div className="value">{createHederaAccountSuccess.public_key}</div>
            </div>
            <div className="parameter">
              <div className="label">Private Key:</div>
              <div className="value">{createHederaAccountSuccess.private_key}</div>
            </div>
          </div>

          <div className="phrase-info">
            <div className="title">Secret Backup Phrase</div>
            <div className="info-text">
              Your secret backup phrase makes it easy to back up
              and restore your account. Write down the 12-word
              phrase below. Keep it in a safe place.
            </div>
            <div className="info-text">
              <span>WARNING:</span> Never disclose your backup phrase.
              Anyone with this phrase can take your account forever.
            </div>
            <div className="phrase-container">
              {createHederaAccountSuccess.mnemonic.map(item => (
                <div className="word-item">{item}</div>
              ))}
            </div>
          </div>
        </div>

        <div className="attention-text">
          <div>
            <span>Attention!</span> We show the private key once.
          </div>
          <div>
            Save it or you can display it in your account settings by filling in the passphrase.
          </div>
        </div>

        <Button className="primary" onClick={onClose}>
          Ok, sounds good!
        </Button>
      </div>
    );
  };

  return (
    <CommonModal
      className="connect-hedera-account-modal__component"
      open={open}
      onClose={onClose}
      title={createHederaAccountSuccess ? 'Congratulations!' : 'Hedera Wallet'}
      onUnmount={() => {
        clearGenerateHederaMnemonic();
        clearCreateHederaAccount();
        if (showLoader) {
          setShowLoader(false);
        }
      }}
    >
      {/* Loader */}
      {showLoader &&
        !createHederaAccountSuccess &&
        !createHederaAccountError &&
        <Dimmer active>
          <Loader>Hold tight… we are creating a new Hedera wallet…</Loader>
        </Dimmer>
      }

      {/* Connection */}
      {
        !createHederaAccountSuccess &&
        !createHederaAccountError &&
        !generateMnemonicSuccess &&
        !generateMnemonicError &&
        renderConnectionStep
      }

      {/* Creation */}
      {generateMnemonicSuccess && !generateMnemonicError && renderCreationStep}

      {/* Mnemonic Error */}
      {generateMnemonicError &&
        <CommonMessage error errors={generateMnemonicError} />
      }

      {/* Creation Success */}
      {createHederaAccountSuccess && !createHederaAccountError && renderSuccessStep()}

      {/* Creation Error */}
      {createHederaAccountError &&
        <CommonMessage error errors={createHederaAccountError} />
      }

    </CommonModal>
  );
}

ConnectHederaAccountModal.propTypes = {
  fetchBlockchainsList: PropTypes.func.isRequired,
  createHederaAccount: PropTypes.func.isRequired,
  clearCreateHederaAccount: PropTypes.func.isRequired,
  generateHederaMnemonic: PropTypes.func.isRequired,
  clearGenerateHederaMnemonic: PropTypes.func.isRequired,
  fetchMe: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  blockchainsList: PropTypes.arrayOf(PropTypes.element),
  createHederaAccountSuccess: PropTypes.object,
  createHederaAccountError: PropTypes.object,
  generateMnemonicSuccess: PropTypes.object,
  generateMnemonicError: PropTypes.object,
};

ConnectHederaAccountModal.defaultProps = {
  open: null,
  blockchainsList: null,
  createHederaAccountSuccess: null,
  createHederaAccountError: null,
  generateMnemonicSuccess: null,
  generateMnemonicError: null,
};

const mapStateToProps = state => (
  {
    blockchainsList: state.blockchainReducer.blockchainsList,
    createHederaAccountSuccess: state.blockchainReducer.createHederaAccountSuccess,
    createHederaAccountError: state.blockchainReducer.createHederaAccountError,
    generateMnemonicSuccess: state.blockchainReducer.generateMnemonicSuccess,
    generateMnemonicError: state.blockchainReducer.generateMnemonicError,
  }
);

export default connect(
  mapStateToProps,
  {
    fetchBlockchainsList,
    createHederaAccount,
    clearCreateHederaAccount,
    generateHederaMnemonic,
    clearGenerateHederaMnemonic,
    fetchMe,
  },
)(ConnectHederaAccountModal);
