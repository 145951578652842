export const CLIENT_ID = 'icLhM0lJi6AIhimXSw5AJeRrFDtWQqHo';
export const CLIENT_SECRET = '8cRTl93DdKzigeDfovuKyoQU6VyFb8riUlOzErplZDXe2dKULqfW3hM6zChSYfk2Dvs2zijMbL42WK5pmaG79EmbhsFAZZ1Z5vN89iJqAlxl9QlB4H6Nh4jLKY6QM8IO';
export const DEFAULT_SCOPES = 'users:read users:write billing:read billing:write blockchain:read blockchain:write tools:read tools:write';

export const REGISTRATE_USER_SUCCESS = 'USERS/REGISTRATE_USER_SUCCESS';
export const REGISTRATE_USER_ERROR = 'USERS/REGISTRATE_USER_ERROR';
export const CLEAR_REGISTRATE_USER = 'USERS/CLEAR_REGISTRATE_USER';

export const VERIFY_REGISTRATION_EMAIL_SUCCESS = 'USERS/VERIFY_REGISTRATION_EMAIL_SUCCESS';
export const VERIFY_REGISTRATION_EMAIL_ERROR = 'USERS/VERIFY_REGISTRATION_EMAIL_ERROR';

export const VALIDATE_USERNAME_SUCCESS = 'USERS/VALIDATE_USERNAME_SUCCESS';
export const VALIDATE_USERNAME_ERROR = 'USERS/VALIDATE_USERNAME_ERROR';
export const CLEAR_VALIDATE_USERNAME = 'USERS/CLEAR_VALIDATE_USERNAME';

export const VALIDATE_EMAIL_SUCCESS = 'USERS/VALIDATE_EMAIL_SUCCESS';
export const VALIDATE_EMAIL_ERROR = 'USERS/VALIDATE_EMAIL_ERROR';
export const CLEAR_VALIDATE_EMAIL = 'USERS/CLEAR_VALIDATE_EMAIL';

export const LOGGED_IN = 'USERS/LOGGED_IN';
export const LOGIN_ERROR = 'USERS/LOGIN_ERROR';
export const CLEAR_LOGIN_ERROR = 'USERS/CLEAR_LOGIN_ERROR';

export const UNAUTHORIZED = 'USERS/UNAUTHORIZED';
export const RESET_UNAUTHORIZED = 'USERS/RESET_UNAUTHORIZED';

export const FETCH_ME = 'USERS/FETCH_ME';
export const FETCH_ME_ERROR = 'USERS/FETCH_ME_ERROR';

export const SET_ME = 'USERS/SET_ME';

export const LOGGING_OUT = 'USERS/LOGGING_OUT';
export const LOGGED_OUT = 'USERS/LOGGED_OUT';

export const REFRESH_TOKEN = 'USERS/REFRESH_TOKEN';
export const REFRESH_TOKEN_ERROR = 'USERS/REFRESH_TOKEN_ERROR';

export const SEND_PASSWORD_RESET_LINK_SUCCESS = 'USERS/SEND_PASSWORD_RESET_LINK_SUCCESS';
export const SEND_PASSWORD_RESET_LINK_ERROR = 'USERS/SEND_PASSWORD_RESET_LINK_ERROR';
export const CLEAR_SEND_PASSWORD_RESET_LINK = 'USERS/ClEAR_SEND_PASSWORD_RESET_LINK';

export const RESET_PASSWORD_SUCCESS = 'USERS/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'USERS/RESET_PASSWORD_ERROR';
export const CLEAR_RESET_PASSWORD = 'USERS/CLEAR_RESET_PASSWORD';

export const CONFIRMATION_RESENT_SUCCESS = 'USERS/CONFIRMATION_RESENT_SUCCESS';
export const CONFIRMATION_RESENT_ERROR = 'USERS/CONFIRMATION_RESENT_ERROR';
export const CLEAR_CONFIRMATION_RESENT = 'USERS/CLEAR_CONFIRMATION_RESENT';

export const PATCH_USER_SUCCESS = 'USERS/PATCH_USER_SUCCESS';
export const PATCH_USER_ERROR = 'USERS/PATCH_USER_ERROR';
export const CLEAR_PATCH_USER = 'USERS/CLEAR_PATCH_USER';

export const FETCH_PUBLIC_PROFILE_DETAILS_SUCCESS = 'USERS/FETCH_PUBLIC_PROFILE_DETAILS_SUCCESS';
export const FETCH_PUBLIC_PROFILE_DETAILS_ERROR = 'USERS/FETCH_PUBLIC_PROFILE_DETAILS_ERROR';
export const CLEAR_FETCH_PUBLIC_PROFILE_DETAILS = 'USERS/CLEAR_FETCH_PUBLIC_PROFILE_DETAILS';
