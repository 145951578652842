import React, { useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchProfileCollectionsList,
  clearFetchProfileCollectionsList,
} from 'redux/collections/actions';

import CollectionsList from 'components/main/collections/common/CollectionsList';


function ProfileCollectionsList(props) {
  const {
    fetchProfileCollectionsList,
    clearFetchProfileCollectionsList,
    profileCollectionsList,
    profileCollectionsListError,
    publicUsername,
    me,
  } = props;

  useEffect(() => {
    if (publicUsername) {
      fetchProfileCollectionsList(publicUsername);
    } else if (me?.username) {
      fetchProfileCollectionsList(me.username);
    }

    return () => {
      clearFetchProfileCollectionsList();
    }
  }, []);

  return (
    <div className="profile-collections-list__component">
      <CollectionsList
        currentList={profileCollectionsList}
        currentListError={profileCollectionsListError}
        loadFunction={pageNumber => {
          if (publicUsername) {
            fetchProfileCollectionsList(publicUsername, pageNumber);
          } else if (me?.username) {
            fetchProfileCollectionsList(me.username, pageNumber);
          }
        }}
      />
    </div>
  )
}

ProfileCollectionsList.propTypes = {
  fetchProfileCollectionsList: PropTypes.func.isRequired,
  clearFetchProfileCollectionsList: PropTypes.func.isRequired,
  profileCollectionsList: PropTypes.arrayOf(PropTypes.object),
  profileCollectionsListError: PropTypes.object,
  publicUsername: PropTypes.string,
  me: PropTypes.object,
};

ProfileCollectionsList.defaultProps = {
  profileAssetsList: null,
  profileAssetsListError: null,
  publicUsername: null,
  me: null,
};

const mapStateToProps = state => (
  {
    profileCollectionsList: state.collectionsReducer.profileCollectionsList,
    profileCollectionsListError: state.collectionsReducer.profileCollectionsListError,
    me: state.usersReducer.me,
  }
);

export default connect(mapStateToProps, {
  fetchProfileCollectionsList,
  clearFetchProfileCollectionsList,
})(ProfileCollectionsList);
