import React from 'react';
import PropTypes from "prop-types";

import './CommonSection.css';

function CommonSection(props) {
  const {
    children,
    className,
  } = props;

  return (
    <div className={`common-section__component${className ? ` ${className}` : ''}`}>
      {children}
    </div>
  );
}

CommonSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
  className: PropTypes.string,
};

CommonSection.defaultProps = {
  className: null,
};

export default CommonSection;
