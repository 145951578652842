import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import { STRIPE_API_KEY, GA_TAG } from 'shared/constants.js';
import ReactGA from "react-ga4";

import CommonLoader from 'components/common/loaders/CommonLoader';
import NotFound from 'components/errors/NotFound';
import NetworkError from 'components/errors/NetworkError';

import MainAuthContainer from 'components/containers/MainAuthContainer';
import MainAppContainer from 'components/containers/MainAppContainer';

import routes from 'routes';

import './App.css';


function App(props) {
  const { siteLoading, notFound, networkError } = props;

  ReactGA.initialize(GA_TAG);
  ReactGA.send("pageview");

  if (networkError) return <NetworkError />;

  return (
    <BrowserRouter>
      <StripeProvider apiKey={STRIPE_API_KEY}>
        <MainAuthContainer>
          <MainAppContainer>
            <CommonLoader active={!!siteLoading} />
            {notFound ? <NotFound /> : routes}
          </MainAppContainer>
        </MainAuthContainer>
      </StripeProvider>
    </BrowserRouter>
  );
}

App.propTypes = {
  siteLoading: PropTypes.bool,
  notFound: PropTypes.bool,
  networkError: PropTypes.bool,
};

App.defaultProps = {
  siteLoading: false,
  notFound: false,
  networkError: false,
};

const mapStateToProps = state => (
  {
    siteLoading: state.commonReducer.siteLoading,
    notFound: state.commonReducer.notFound,
    networkError: state.commonReducer.networkError,
  }
);

export default connect(
  mapStateToProps,
  {},
)(App);
