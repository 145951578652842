import React from 'react';

import { Helmet } from 'react-helmet';

import MainPageLayout from 'components/common/layouts/MainPageLayout';
import ContactPageContent from 'components/main/contact/ContactPageContent';


const ContactPage = () => (
  <MainPageLayout header={'Contact'}>
    <Helmet>
      <title>Contact Us, About Us | HeyMint.me</title>
      <meta property="og:title" content="Contact Us, About Us | HeyMint.me" />
      <meta
        name="description"
        content="HeyMint.me helps the best NFT creators, sellers and buyers. Feel free to reach out if there are any questions."
      />
      <meta
        property="og:description"
        content="HeyMint.me helps the best NFT creators, sellers and buyers. Feel free to reach out if there are any questions."
      />
    </Helmet>
    <ContactPageContent />
  </MainPageLayout>
);

export default ContactPage;
