import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CommonTableHeader from 'components/common/elements/tables/common-table/CommonTableHeader';
import CommonTableRow from 'components/common/elements/tables/common-table/CommonTableRow';
import CommonTableCell from 'components/common/elements/tables/common-table/CommonTableCell';

import './CommonTable.css';

class CommonTable extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.string,
    ]).isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };

  static Header = CommonTableHeader;
  static Row = CommonTableRow;
  static Cell = CommonTableCell;

  calculateClassname = () => {
    let value = 'common-table__component';
    if (this.props.className) { value += ` ${this.props.className}`; }
    return value;
  };

  render() {
    return (
      <div className={this.calculateClassname()}>
        <div className="table-wrapper">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default CommonTable;
