import React from 'react';
import PropTypes from 'prop-types';

import { Dimmer } from 'semantic-ui-react';

import './CommonLoader.css';

const CommonLoader = ({ active, text }) => (
  <Dimmer
    active={!!active}
    className="common-loader__component"
    page
  >
    <div className="wrapper">
      <div className="loader-logo" />
      {text && <div className="text">{text}</div>}
    </div>
  </Dimmer>
);

CommonLoader.propTypes = {
  active: PropTypes.bool.isRequired,
  text: PropTypes.string,
};

CommonLoader.defaultProps = {
  text: null,
};

export default CommonLoader;
