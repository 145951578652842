import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  fetchMe,
  logoutComplete,
  refreshTokenFunc,
  resetUnauthorized,
} from 'redux/users/actions';

import { getCookie, setCookies, removeCookie } from 'utils/cookies';


function MainAuthContainer(props) {
  const {
    me,
    children,
    fetchMe,
    refreshTokenFunc,
    resetUnauthorized,
    logoutComplete,
    unauthorized,
    accessToken,
    refreshToken,
    loggingOut,
  } = props;

  useEffect(() => {
    const cookieAccessToken = getCookie('accessToken');
    const cookieRefreshToken = getCookie('refreshToken');

    if (!me && cookieAccessToken) {
      fetchMe();
    }

    if (!cookieAccessToken && cookieRefreshToken) {
      refreshTokenFunc(cookieRefreshToken);
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      setCookies(accessToken, refreshToken);
      fetchMe();
    }
  }, [accessToken]);

  useEffect(() => {
    if (loggingOut) {
      removeCookie();
      logoutComplete();
      window.location.href = '/';
    }
  }, [loggingOut]);

  if (me && unauthorized) { resetUnauthorized(); }

  return children;
}

MainAuthContainer.propTypes = {
  fetchMe: PropTypes.func.isRequired,
  logoutComplete: PropTypes.func.isRequired,
  refreshTokenFunc: PropTypes.func.isRequired,
  resetUnauthorized: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
  me: PropTypes.object,
  accessToken: PropTypes.string,
  refreshToken: PropTypes.string,
  unauthorized: PropTypes.bool,
  loggingOut: PropTypes.bool,
};

MainAuthContainer.defaultProps = {
  me: null,
  accessToken: null,
  refreshTokenFunc: null,
  refreshToken: null,
  unauthorized: false,
  loggingOut: false,
};

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
    accessToken: state.usersReducer.accessToken,
    refreshToken: state.usersReducer.refreshToken,
    loggingOut: state.usersReducer.loggingOut,
    unauthorized: state.usersReducer.unauthorized,
  }
);

export default connect(
  mapStateToProps,
  {
    fetchMe,
    logoutComplete,
    refreshTokenFunc,
    resetUnauthorized,
  },
)(MainAuthContainer);
