import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchGlobalSearchData,
  clearFetchGlobalSearchData,
} from 'redux/tools/actions';

import { Link } from 'react-router-dom';
import { Input } from 'semantic-ui-react';

import OutsideHandler from 'components/common/handlers/OutsideHandler';

import './GlobalSearch.css';


function GlobalSearch(props) {
  const {
    fetchGlobalSearchData,
    clearFetchGlobalSearchData,
    globalSearchData,
    globalSearchDataError,
  } = props;

  const [showLoader, setShowLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);

  useEffect(() => {
    return () => {
      clearFetchGlobalSearchData();
      setShowLoader(false);
      setShowPopup(false);
      setCurrentValue(null);
    };
  }, []);

  useEffect(() => {
    if (currentValue?.length > 2) {
      fetchGlobalSearchData(currentValue);
      setShowLoader(true);
    }

    if (!currentValue || currentValue?.length < 3) {
      clearFetchGlobalSearchData();
      setShowPopup(false);
    }
  }, [currentValue]);

  useEffect(() => {
    if (globalSearchData || globalSearchDataError) {
      setShowLoader(false);
      setShowPopup(true);
    }
  }, [globalSearchData, globalSearchDataError]);

  const renderInputContainer =  (
    <div className="input-container">
      <Input
        icon="search"
        loading={showLoader}
        placeholder="Search NFTs, collections or profiles"
        value={currentValue}
        onChange={e => {
          e.preventDefault();
          setCurrentValue(e.target.value);
        }}
        onFocus={() =>{
          if (globalSearchData) {
            setShowPopup(true);
          }
        }}
      />
    </div>
  );

  const renderPopupContainer = (
    <OutsideHandler handleClickOutside={() => setShowPopup(false)}>
      <div className="popup-container">
        {/* Collections */}
        {globalSearchData?.collection &&
          <div className="section">
            <div className="section-title">COLLECTIONS</div>
            {globalSearchData.collection.map(item => (
              <a
                className="link-item"
                href={`/collection/${item.slug}`}
              >
                {item.title}
              </a>
            ))}
          </div>
        }

        {/* Profiles */}
        {globalSearchData?.profile &&
          <div className="section">
            <div className="section-title">PROFILES</div>
            {globalSearchData.profile.map(item => (
              <a
                className="link-item"
                href={`/profile/${item.username}`}
              >
                {item.username}
              </a>
            ))}
          </div>
        }

        {/* Assets */}
        {globalSearchData?.nft &&
          <div className="section">
            <div className="section-title">ITEMS</div>
            {globalSearchData.nft.map(item => (
              <a
                className="link-item"
                href={`/asset/${item.token_id}`}
              >
                {item.token_name}
              </a>
            ))}
          </div>
        }
        {globalSearchData &&
          !globalSearchData.collection &&
          !globalSearchData.profile &&
          !globalSearchData.nft &&
          <div className="no-items">No items found</div>
        }
      </div>
    </OutsideHandler>
  );

  return (
    <div className="global-search__component">
      {renderInputContainer}
      {showPopup && renderPopupContainer}
    </div>
  );
}

GlobalSearch.propTypes = {
  fetchGlobalSearchData: PropTypes.func.isRequired,
  clearFetchGlobalSearchData: PropTypes.func.isRequired,
  globalSearchData: PropTypes.arrayOf(PropTypes.element),
  globalSearchDataError: PropTypes.object,
};

GlobalSearch.defaultProps = {
  globalSearchData: null,
  globalSearchDataError: null,
};

const mapStateToProps = state => (
  {
    globalSearchData: state.toolsReducer.globalSearchData,
    globalSearchDataError: state.toolsReducer.globalSearchDataError,
  }
);

export default connect(mapStateToProps, {
  fetchGlobalSearchData,
  clearFetchGlobalSearchData,
})(GlobalSearch);
