import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { resetNetworkError } from 'redux/common/actions';
import { fetchMe, setMe } from 'redux/users/actions';

import './NetworkError.css';

class NetworkError extends Component {
  static propTypes = {
    resetNetworkError: PropTypes.func.isRequired,
    fetchMe: PropTypes.func.isRequired,
    setMe: PropTypes.func.isRequired,
    me: PropTypes.object,
  }

  static defaultProps = {
    me: null,
  }

  constructor(props) {
    super(props);
    this.intervalRefetch = null;
  }

  componentDidMount() {
    this.props.setMe(null);

    this.intervalRefetch = setInterval(() => {
      this.props.fetchMe();
    }, 15000);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.me && this.props.me) {
      this.props.resetNetworkError();
      clearInterval(this.intervalRefetch);
    }
  }

  componentWillUnmount() {
    this.props.resetNetworkError();
    clearInterval(this.intervalRefetch);
  }

  render() {
    return (
      <div className="network-error__component">
        <div className="image-wrapper" />

        <div className="description">
          <div>Sorry, we are currently updating our site.</div>
          <div>We will be back soon...</div>
        </div>

        <div className="footer-text">
          Have a question? <a href="mailto:team@heymint.me">team@heymint.me</a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    me: state.usersReducer.me,
  }
);

export default connect(
  mapStateToProps,
  {
    resetNetworkError,
    fetchMe,
    setMe,
  },
)(NetworkError);
