export const FETCH_MY_COLLECTIONS_LIST = 'COLLECTIONS/FETCH_MY_COLLECTIONS_LIST';
export const FETCH_MY_COLLECTIONS_LIST_ERROR = 'COLLECTIONS/FETCH_MY_COLLECTIONS_LIST_ERROR';
export const CLEAR_FETCH_MY_COLLECTIONS_LIST = 'COLLECTIONS/CLEAR_FETCH_MY_COLLECTIONS_LIST';

export const FETCH_COLLECTION_DETAILS = 'COLLECTIONS/FETCH_COLLECTION_DETAILS';
export const FETCH_COLLECTION_DETAILS_ERROR = 'COLLECTIONS/FETCH_COLLECTION_DETAILS_ERROR';
export const CLEAR_FETCH_COLLECTION_DETAILS = 'COLLECTIONS/CLEAR_FETCH_COLLECTION_DETAILS';

export const CREATE_COLLECTION_SUCCESS = 'COLLECTIONS/CREATE_COLLECTION_SUCCESS';
export const CREATE_COLLECTION_ERROR = 'COLLECTIONS/CREATE_COLLECTION_ERROR';
export const CLEAR_CREATE_COLLECTION = 'COLLECTIONS/CLEAR_CREATE_COLLECTION';

export const UPDATE_COLLECTION_SUCCESS = 'COLLECTIONS/UPDATE_COLLECTION_SUCCESS';
export const UPDATE_COLLECTION_ERROR = 'COLLECTIONS/UPDATE_COLLECTION_ERROR';
export const CLEAR_UPDATE_COLLECTION = 'COLLECTIONS/CLEAR_UPDATE_COLLECTION';

export const DELETE_COLLECTION_SUCCESS = 'COLLECTIONS/DELETE_COLLECTION_SUCCESS';
export const DELETE_COLLECTION_ERROR = 'COLLECTIONS/DELETE_COLLECTION_ERROR';
export const CLEAR_DELETE_COLLECTION = 'COLLECTIONS/CLEAR_DELETE_COLLECTION';

export const VALIDATE_COLLECTION_TITLE_SUCCESS = 'COLLECTIONS/VALIDATE_COLLECTION_TITLE_SUCCESS';
export const VALIDATE_COLLECTION_TITLE_ERROR = 'COLLECTIONS/VALIDATE_COLLECTION_TITLE_ERROR';
export const CLEAR_VALIDATE_COLLECTION_TITLE = 'COLLECTIONS/CLEAR_VALIDATE_COLLECTION_TITLE';

export const FETCH_EXPLORE_COLLECTIONS_LIST = 'COLLECTIONS/FETCH_EXPLORE_COLLECTIONS_LIST';
export const FETCH_EXPLORE_COLLECTIONS_LIST_ERROR = 'COLLECTIONS/FETCH_EXPLORE_COLLECTIONS_LIST_ERROR';
export const CLEAR_FETCH_EXPLORE_COLLECTIONS_LIST = 'COLLECTIONS/CLEAR_FETCH_EXPLORE_COLLECTIONS_LIST';

export const FETCH_PROFILE_COLLECTIONS_LIST = 'COLLECTIONS/FETCH_PROFILE_COLLECTIONS_LIST';
export const FETCH_PROFILE_COLLECTIONS_LIST_ERROR = 'COLLECTIONS/FETCH_PROFILE_COLLECTIONS_LIST_ERROR';
export const CLEAR_FETCH_PROFILE_COLLECTIONS_LIST = 'COLLECTIONS/CLEAR_FETCH_PROFILE_COLLECTIONS_LIST';

export const FETCH_COLLECTION_ACTIVITIES_LIST = 'COLLECTIONS/FETCH_COLLECTION_ACTIVITIES_LIST';
export const FETCH_COLLECTION_ACTIVITIES_LIST_ERROR = 'COLLECTIONS/FETCH_COLLECTION_ACTIVITIES_LIST_ERROR';
export const CLEAR_FETCH_COLLECTION_ACTIVITIES_LIST = 'COLLECTIONS/CLEAR_FETCH_COLLECTION_ACTIVITIES_LIST';

export const FETCH_COLLECTION_CATEGORIES = 'COLLECTIONS/FETCH_COLLECTION_CATEGORIES';
export const FETCH_COLLECTION_CATEGORIES_ERROR = 'COLLECTIONS/FETCH_COLLECTION_CATEGORIES_ERROR';
export const CLEAR_FETCH_COLLECTION_CATEGORIES = 'COLLECTIONS/CLEAR_FETCH_COLLECTION_CATEGORIES';
