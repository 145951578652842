import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Dropdown, Form } from 'semantic-ui-react';

import './SelectField.css';


function SelectField(props) {
  const {
    input,
    label,
    options,
    placeholder,
    loading,
    disabled,
    search,
    firstSelected,
    defaultValue,
    meta: { touched, error },
  } = props;

  useEffect(() => {
    if (
      firstSelected &&
      (options && options.length > 0) &&
      !input.value
    ) {
      input.onChange(options[0].value);
    }
  }, []);

  if (!('id' in input)) {
    input.id = `id_${input.name}`;
  }

  return (
    <Form.Field
      className="select-field__component"
      error={Boolean(touched && error)}
    >
      {label &&
        <div className="label">{label}</div>
      }

      <Dropdown
        {...input}
        search={search || null}
        selection
        options={options}
        placeholder={placeholder}
        label={label || null}
        value={input.value}
        onChange={(param, data) => input.onChange(data.value)}
        loading={loading || null}
        disabled={disabled}
        defaultValue={defaultValue}
      />

      {touched && error &&
        <div className="errors">{error}</div>
      }
    </Form.Field>
  );
}

SelectField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  firstSelected: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  search: PropTypes.bool,
  defaultValue: PropTypes.number,
};

SelectField.defaultProps = {
  firstSelected: false,
  label: null,
  placeholder: null,
  loading: false,
  disabled: false,
  search: false,
  options: [],
  defaultValue: null,
};

export default SelectField;
