import React from 'react';
import PropTypes from 'prop-types';

import './CommonMessage.css';


function CommonMessage(props) {
  const {
    children,
    content,
    errors,
    info,
    success,
    warning,
    error,
  } = props;

  const calculateClassName = () => {
    let value = 'common-message__component';
    if (info) { value += ' info'; }
    if (success) { value += ' success'; }
    if (warning) { value += ' warning'; }
    if (error) { value += ' error'; }
    return value;
  };

  const parseErrors = () => {
    if (typeof errors === 'string') return errors;

    const elements = [];
    Object.keys(errors).map(key => {
      if (key === 'non_field_errors') {
        errors[key].map(message => {
          elements.push(
            <div>{message}</div>,
          );
        });
      } else {
        elements.push(
          <div>{errors[key]}</div>,
        );
      }
    });
    return elements;
  };

  return (
    <div className={calculateClassName()}>
      <div className="icon" />
      <div className="content-wrapper">
        {children && children}
        {content && content}
        {errors && parseErrors()}
      </div>
    </div>
  );
}

CommonMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]).isRequired,
  errors: PropTypes.object,
  info: PropTypes.bool,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  error: PropTypes.bool,
  content: PropTypes.string,
};

CommonMessage.defaultProps = {
  info: null,
  success: null,
  warning: null,
  error: null,
  errors: null,
  content: null,
};

export default CommonMessage;
