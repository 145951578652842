import React, { useCallback } from 'react';
import PropTypes from "prop-types";

import { useDropzone } from 'react-dropzone';

import CommonLogoContainer from 'components/common/logos/CommonLogoContainer';
import Button from 'components/common/elements/buttons/Button';

import './UpdateProfileAvatar.css';


function UpdateProfileAvatar(props) {
  const { patchUser, me } = props;

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles) {
      const formData = new FormData();
      formData.append('avatar', acceptedFiles[0]);
      patchUser(formData);
    }
  }, []);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: 'image/jpeg,image/png, image/gif, image/svg',
    maxFiles: 1,
  });

  return (
    <div className="upload-profile-avatar__component">
      <CommonLogoContainer
        logoUrl={me.avatar}
        width={160}
        height={160}
      />

      <div className="info-block">
        <div className="text-block">
          <div className="title">Profile photo</div>
          <div className="undertext">
            We recommend an image of at least 400x400. Gifs work too.
          </div>
        </div>
        <div
          className="buttons-block"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Button className="secondary small">Upload</Button>
        </div>
      </div>

    </div>
  );
}

UpdateProfileAvatar.propTypes = {
  patchUser: PropTypes.func.isRequired,
  me: PropTypes.object,
};

UpdateProfileAvatar.defaultProps = {
  me: null,
};

export default UpdateProfileAvatar;
