import restapi from 'utils/restapi';
import { baseErrorsHandlerDecorator } from 'redux/utils';

import {
  CLIENT_ID,
  CLIENT_SECRET,
  DEFAULT_SCOPES,
  REGISTRATE_USER_SUCCESS,
  REGISTRATE_USER_ERROR,
  CLEAR_REGISTRATE_USER,
  VERIFY_REGISTRATION_EMAIL_SUCCESS,
  VERIFY_REGISTRATION_EMAIL_ERROR,
  VALIDATE_USERNAME_SUCCESS,
  VALIDATE_USERNAME_ERROR,
  CLEAR_VALIDATE_USERNAME,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_ERROR,
  CLEAR_VALIDATE_EMAIL,
  LOGGED_IN,
  LOGIN_ERROR,
  CLEAR_LOGIN_ERROR,
  RESET_UNAUTHORIZED,
  FETCH_ME,
  FETCH_ME_ERROR,
  SET_ME,
  LOGGING_OUT,
  LOGGED_OUT,
  REFRESH_TOKEN,
  REFRESH_TOKEN_ERROR,
  PATCH_USER_SUCCESS,
  PATCH_USER_ERROR,
  CLEAR_PATCH_USER,
  FETCH_PUBLIC_PROFILE_DETAILS_SUCCESS,
  FETCH_PUBLIC_PROFILE_DETAILS_ERROR,
  CLEAR_FETCH_PUBLIC_PROFILE_DETAILS,
  SEND_PASSWORD_RESET_LINK_SUCCESS,
  SEND_PASSWORD_RESET_LINK_ERROR,
  CLEAR_SEND_PASSWORD_RESET_LINK,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CLEAR_RESET_PASSWORD,
} from 'redux/users/types';

export const registrateUser = (email, username, password) => baseErrorsHandlerDecorator(
  REGISTRATE_USER_SUCCESS,
  REGISTRATE_USER_ERROR,
  () => restapi.post('v1/users/register/',
    { email, username, password },
  ),
);

export const clearRegistrateUser = () => (dispatch) => {
  dispatch({
    type: CLEAR_REGISTRATE_USER,
  });
};

export const verifyRegistrationEmail = key => baseErrorsHandlerDecorator(
  VERIFY_REGISTRATION_EMAIL_SUCCESS,
  VERIFY_REGISTRATION_EMAIL_ERROR,
  () => restapi.post('v1/users/register/verify_email/',
    {
      key,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      scopes: DEFAULT_SCOPES,
    },
  ),
);

export const retrieveToken = (email, password)  => baseErrorsHandlerDecorator(
  LOGGED_IN,
  LOGIN_ERROR,
  () => restapi.post(
    'v1/users/token/',
    {
      email,
      password,
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      scopes: DEFAULT_SCOPES,
    },
  ),
);

export const clearLoginError = () => dispatch => {
  dispatch({
    type: CLEAR_LOGIN_ERROR,
  });
};

export const resetUnauthorized = () => dispatch => {
  dispatch({
    type: RESET_UNAUTHORIZED,
  });
};

export const fetchMe = (isLoader = true) => baseErrorsHandlerDecorator(
  FETCH_ME,
  FETCH_ME_ERROR,
  () => restapi.get('v1/users/me/'),
  isLoader,
);

export const setMe = (me) => (dispatch) => {
  dispatch({
    type: SET_ME,
    payload: {
      data: me,
    },
  });
};

export const logout = () => dispatch => {
  dispatch({
    type: LOGGING_OUT,
  });
};

export const logoutComplete = () => dispatch => {
  dispatch({
    type: LOGGED_OUT,
  });
};

export const refreshTokenFunc = refreshToken => baseErrorsHandlerDecorator(
  REFRESH_TOKEN,
  REFRESH_TOKEN_ERROR,
  () => restapi.post(
    'v1/users/token/refresh/',
    {
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
      refresh_token: refreshToken,
    },
  ),
);

export const adminSetTokens = (accessToken, refreshToken) => dispatch => {
  dispatch({
    type: LOGGED_IN,
    payload: {
      data: {
        access_token: accessToken,
        refresh_token: refreshToken,
      },
    },
  });
};

export const validateUsername = username => baseErrorsHandlerDecorator(
  VALIDATE_USERNAME_SUCCESS,
  VALIDATE_USERNAME_ERROR,
  () => restapi.post('v1/users/validate-username/', { username }),
  false,
);

export const clearValidateUsername = () => dispatch => {
  dispatch({
    type: CLEAR_VALIDATE_USERNAME,
  });
};

export const validateEmail = email => baseErrorsHandlerDecorator(
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_ERROR,
  () => restapi.post(
    'v1/users/validate-email/',
    { email },
  ),
  false,
);

export const clearValidateEmail = () => dispatch => {
  dispatch({
    type: CLEAR_VALIDATE_EMAIL,
  });
};

export const patchUser = formData => baseErrorsHandlerDecorator(
  PATCH_USER_SUCCESS,
  PATCH_USER_ERROR,
  () => restapi.patch('v1/users/me/', formData),
  false,
  true /* actionSucces */,
);

export const clearPatchUser = () => dispatch => {
  dispatch({
    type: CLEAR_PATCH_USER,
  });
};

export const fetchPublicProfileDetails = username => baseErrorsHandlerDecorator(
  FETCH_PUBLIC_PROFILE_DETAILS_SUCCESS,
  FETCH_PUBLIC_PROFILE_DETAILS_ERROR,
  () => restapi.get(`v1/users/me/public/${username}/`),
  false,
  true /* actionSucces */,
);

export const clearFetchPublicProfileDetails = () => dispatch => {
  dispatch({
    type: CLEAR_FETCH_PUBLIC_PROFILE_DETAILS,
  });
};

export const sendPasswordResetLink = email => baseErrorsHandlerDecorator(
  SEND_PASSWORD_RESET_LINK_SUCCESS,
  SEND_PASSWORD_RESET_LINK_ERROR,
  () => restapi.post('v1/users/password/recovery/', { email }),
);

export const clearSendPasswordResetLink = () => dispatch => {
  dispatch({
    type: CLEAR_SEND_PASSWORD_RESET_LINK,
  });
};

export const resetPassword = (confirmationKey, password1, password2) => baseErrorsHandlerDecorator(
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  () => restapi.post(
    'v1/users/password/recovery/confirm/',
    {
      key: confirmationKey,
      new_password1: password1,
      new_password2: password2,
    },
  ),
);

export const clearResetPassword = () => dispatch => {
  dispatch({
    type: CLEAR_RESET_PASSWORD,
  });
};
