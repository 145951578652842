import React, { useState } from 'react';
import PropTypes from "prop-types";

import CommonTabList from 'components/common/elements/tabs/CommonTabList';
import CommonTabItem from 'components/common/elements/tabs/CommonTabItem';

import ProfileInfo from 'components/main/profile/ProfileInfo';
import ProfileAssetsList from 'components/main/profile/tabs-content/ProfileAssetsList';
import ProfileCollectionsList from 'components/main/profile/tabs-content/ProfileCollectionsList';
import CommonLogoContainer from 'components/common/logos/CommonLogoContainer';
import ic_instagram from './icons/icon_instagram.svg';
import ic_site from './icons/icon_web.svg';

import './ProfileView.css';


function ProfileView(props) {
  const {
    profileData,
    publicUsername,
  } = props;

  const [activeTab, setActiveTab] = useState('owned');

  const profileSocialIcons = [
    {
      icon: ic_site,
      available: profileData?.site_link
    },
     {
      icon: ic_instagram,
      available: profileData?.instagram_link
    },
  ];

  const renderSocialIcons = () => (
    <div className="social-icons-wrapper">
      {profileSocialIcons.map((item, index) => (
        item.available && 
        <a 
          key={`profile-social-icon-${index}`} 
          href={item.available} 
          className='social-icon' 
          target='_blank' 
          rel='noreferrer'
        >
            <img src={item.icon} />
        </a>
      ))}
    </div>
  );

  return (
    <div className="profile-view__component">
      <div
        className="header-block"
        style={{ backgroundImage: profileData.cover && `url(${profileData.cover})` }}
      >
        <div className="logo-container">
          <CommonLogoContainer logoUrl={profileData.avatar} />
        </div>
      </div>

      <div className="content-wrapper">
        <ProfileInfo profileData={profileData} />
        {renderSocialIcons()}
        <CommonTabList center>
          <CommonTabItem
            active={activeTab === 'owned'}
            tabName="Owned"
            onClick={() => { setActiveTab('owned'); }}
          />
          <CommonTabItem
            active={activeTab === 'creacted'}
            tabName="Created"
            onClick={() => { setActiveTab('creacted'); }}
          />
          <CommonTabItem
            active={activeTab === 'on-sale'}
            tabName="On Sale"
            onClick={() => { setActiveTab('on-sale'); }}
          />
          {!publicUsername &&
            <CommonTabItem
              active={activeTab === 'hidden'}
              tabName="Hidden"
              onClick={() => { setActiveTab('hidden'); }}
            />
          }
          <CommonTabItem
            active={activeTab === 'collections'}
            tabName="Collections"
            onClick={() => { setActiveTab('collections'); }}
          />
        </CommonTabList>

        <div className="tabs-content-container">
          {activeTab === 'owned' &&
            <ProfileAssetsList
              type={activeTab}
              publicUsername={publicUsername}
            />
          }

          {activeTab === 'creacted' &&
            <ProfileAssetsList
              type={activeTab}
              publicUsername={publicUsername}
            />
          }

          {activeTab === 'on-sale' &&
            <ProfileAssetsList
              type={activeTab}
              publicUsername={publicUsername}
            />
          }

          {activeTab === 'hidden' && !publicUsername &&
            <ProfileAssetsList
              type={activeTab}
              publicUsername={publicUsername}
            />
          }

          {activeTab === 'collections' &&
            <ProfileCollectionsList publicUsername={publicUsername} />
          }
        </div>
      </div>
    </div>
  );
}

ProfileView.propTypes = {
  profileData: PropTypes.object.isRequired,
  publicPage: PropTypes.bool,
  publicUsername: PropTypes.string,
};

ProfileView.defaultProps = {
  publicPage: null,
  publicUsername: null,
};

export default ProfileView;
