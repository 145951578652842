const validate = values => {
  const errors = {};

  if (!values.username) {
    errors.username = "Username is required";
  } else if (values.username.length > 30) {
    errors.username = "Username must has no more than 30 symbols";
  } else if (values.username && values.username.length < 3) {
    errors.username = "Username must be longer than 3 characters";
  } else if (/[^\w\d-+.]/.test(values.username)) {
    errors.username = "Sorry, you can use only letters, digits and . + - _";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password1) {
    errors.password1 = "Password is required";
  }

  if (!values.password2) {
    errors.password2 = "Password confirmation is required";
  }

  if (values.password1 !== values.password2) {
    errors.password2 = "Password doesn't match";
  }

  if (values.password1 && values.password1.length < 8) {
    errors.password1 = "Password must contain at least 8 characters";
  }

  if (values.password2 && values.password2.length < 8) {
    errors.password2 = "Password must contain at least 8 characters";
  }

  return errors;
};

export default validate;
