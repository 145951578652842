import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  sendPasswordResetLink,
  clearSendPasswordResetLink,
} from 'redux/users/actions';

import Button from 'components/common/elements/buttons/Button';
import InputTextField from 'components/redux-form-adapters/InputTextField';
import CommonMessage from 'components/common/messages/CommonMessage';

import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form } from 'semantic-ui-react';

import validate from "./validate";

import './PasswordRecoveryForm.css';


function PasswordRecoveryForm(props) {
  const {
    handleSubmit,
    sendPasswordResetLink,
    clearSendPasswordResetLink,
    sendPasswordResetLinkSuccess,
    sendPasswordResetLinkError,
    valid,
    email,
  } = props;

  useEffect(() => {
    return () => {
      clearSendPasswordResetLink();
    };
  }, []);

  const _handleSubmit = values => {
    sendPasswordResetLink(values.email);
  };

  return (
    <div className="password-recovery-form__component">
      <h1>Password Recovery</h1>

      {!sendPasswordResetLinkSuccess &&
        <Form
          onSubmit={handleSubmit(_handleSubmit)}
          error={!valid}
        >
          <Field
            label="Email Address"
            component={InputTextField}
            name="email"
            type="email"
            required
          />

          <Button
            className="primary"
            submit
            disabled={!valid}
          >
            Send Reset Link
          </Button>
        </Form>
      }

      {sendPasswordResetLinkSuccess?.detail &&
        <CommonMessage success>
          <div>Open the email we just sent you to reset your password.</div>
          <div>
            <strong>{email}</strong>
          </div>
          <div>Didn&prime;t see the email? Check your spam folder.</div>
        </CommonMessage>
      }

      {sendPasswordResetLinkError &&
        <CommonMessage
          error
          errors={sendPasswordResetLinkError}
        />
      }

    </div>
  );
}

PasswordRecoveryForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  sendPasswordResetLink: PropTypes.func.isRequired,
  clearSendPasswordResetLink: PropTypes.func.isRequired,
  sendPasswordResetLinkSuccess: PropTypes.object,
  sendPasswordResetLinkError: PropTypes.object,
  valid: PropTypes.bool,
  email: PropTypes.string,
};

PasswordRecoveryForm.defaultProps = {
  sendPasswordResetLinkSuccess: null,
  sendPasswordResetLinkError: null,
  email: null,
};

const mapStateToProps = (state) => {
  const selector = formValueSelector('PasswordRecoveryForm');
  return {
    email: selector(state, 'email'),
    sendPasswordResetLinkSuccess: state.usersReducer.sendPasswordResetLinkSuccess,
    sendPasswordResetLinkError: state.usersReducer.sendPasswordResetLinkError,
  };
};

export default connect(
  mapStateToProps,
  {
    sendPasswordResetLink,
    clearSendPasswordResetLink,
  },
)(reduxForm({
  form: 'PasswordRecoveryForm',
  validate,
})(PasswordRecoveryForm));
