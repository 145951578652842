import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'semantic-ui-react';

import './CommonModal.css';

function CommonModal(props) {
  const {
    className,
    open,
    onClose,
    children,
    size,
    title,
    closeOnDimmerClick,
    onMount,
    onUnmount,
  } = props;

  return (
    <Modal
      className={`common-modal__component${className ? ` ${className}` : ''}`}
      title={title}
      open={open}
      onClose={onClose}
      size={size || 'small'}
      closeOnDimmerClick={closeOnDimmerClick}
      onMount={onMount}
      onUnmount={onUnmount}
    >
      <div className="header-block">
        <div className="title">
          {title && title}
        </div>
        <div
          className="close-button"
          onClick={onClose}
          role="button"
          tabIndex={-1}
        />
      </div>
      <Modal.Content>
        {children}
      </Modal.Content>
    </Modal>
  );
}

CommonModal.propTypes = {
  onClose: PropTypes.func,
  onMount: PropTypes.func,
  onUnmount: PropTypes.func,
  className: PropTypes.string,
  open: PropTypes.bool,
  size: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]),
  title: PropTypes.string,
  closeOnDimmerClick: PropTypes.bool,
};

CommonModal.defaultProps = {
  onClose: null,
  className: null,
  open: null,
  children: null,
  size: null,
  title: null,
  closeOnDimmerClick: null,
  onMount: null,
  onUnmount: null,
};

export default CommonModal;
