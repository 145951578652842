import React from 'react';
import PropTypes from "prop-types";

import Button from 'components/common/elements/buttons/Button';
import CommonModal from 'components/common/elements/modals/CommonModal';

import './ConfirmationModal.css';


function ConfirmationModal(props) {
  const {
    onClose,
    onConfirm,
    onUnmount,
    open,
    title,
    cancelButton,
    confitmButtonText,
    text,
    children,
  } = props;

  return (
    <CommonModal
      className="confirmation-modal__component"
      open={open}
      onClose={onClose}
      onUnmount={onUnmount}
      title={title}
    >
      <div className="content-area">
        {text &&
          <div className="text-block">
            {text}
          </div>
        }

        {children &&
          <div className="content-block">
            {children}
          </div>
        }

        <div className="buttons-block">
          {cancelButton &&
            <Button className="secondary" onClick={onClose}>
              Cancel
            </Button>
          }

          <Button className="primary" onClick={onConfirm}>
            {confitmButtonText || 'Confirm'}
          </Button>
        </div>
      </div>
    </CommonModal>
  );
}

ConfirmationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onUnmount: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  cancelButton: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  confitmButtonText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.string,
  ]),
};

ConfirmationModal.defaultProps = {
  open: null,
  cancelButton: null,
  title: null,
  confitmButtonText: null,
  text: null,
  children: null,
};

export default ConfirmationModal;
