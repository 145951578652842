import {
  REGISTRATE_USER_SUCCESS,
  REGISTRATE_USER_ERROR,
  CLEAR_REGISTRATE_USER,
  VERIFY_REGISTRATION_EMAIL_SUCCESS,
  VERIFY_REGISTRATION_EMAIL_ERROR,
  VALIDATE_USERNAME_SUCCESS,
  VALIDATE_USERNAME_ERROR,
  CLEAR_VALIDATE_USERNAME,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_ERROR,
  CLEAR_VALIDATE_EMAIL,
  LOGGED_IN,
  LOGIN_ERROR,
  CLEAR_LOGIN_ERROR,
  UNAUTHORIZED,
  RESET_UNAUTHORIZED,
  FETCH_ME,
  FETCH_ME_ERROR,
  SET_ME,
  LOGGING_OUT,
  LOGGED_OUT,
  REFRESH_TOKEN,
  REFRESH_TOKEN_ERROR,
  PATCH_USER_SUCCESS,
  PATCH_USER_ERROR,
  CLEAR_PATCH_USER,
  FETCH_PUBLIC_PROFILE_DETAILS_SUCCESS,
  FETCH_PUBLIC_PROFILE_DETAILS_ERROR,
  CLEAR_FETCH_PUBLIC_PROFILE_DETAILS,
  SEND_PASSWORD_RESET_LINK_SUCCESS,
  SEND_PASSWORD_RESET_LINK_ERROR,
  CLEAR_SEND_PASSWORD_RESET_LINK,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  CLEAR_RESET_PASSWORD,
} from 'redux/users/types';

const initialState = {
  me: null,
  meError: null,
  accessToken: null,
  refreshToken: null,
  loginError: null,
  unauthorized: false,
  loggingOut: false,
  registrateUserSuccess: null,
  registrateUserError: null,
  verifyRegistrationEmailSuccess: false,
  verifyRegistrationEmailError: null,
  validateUsernameSuccess: null,
  validateUsernameError: null,
  validateEmailSuccess: null,
  validateEmailError: null,
  patchUserSuccess: null,
  patchUserError: null,
  publicProfileDetails: null,
  publicProfileDetailsError: null,
  sendPasswordResetLinkSuccess: null,
  sendPasswordResetLinkError: null,
  resetPasswordSuccess: null,
  resetPasswordError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REGISTRATE_USER_SUCCESS:
      return {
        ...state,
        registrateUserSuccess: action.payload.data,
        registrateUserError: null,
      };
    case REGISTRATE_USER_ERROR:
      return {
        ...state,
        registrateUserSuccess: null,
        registrateUserError: action.payload.data,
      };
    case CLEAR_REGISTRATE_USER:
      return {
        ...state,
        registrateUserSuccess: null,
        registrateUserError: null,
      };
    case VERIFY_REGISTRATION_EMAIL_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.data.access_token,
        refreshToken: action.payload.data.refresh_token,
        verifyRegistrationEmailSuccess: true,
        verifyRegistrationEmailError: null,
      };
    case VERIFY_REGISTRATION_EMAIL_ERROR:
      return {
        ...state,
        verifyRegistrationEmailSuccess: false,
        verifyRegistrationEmailError: action.payload.data,
      };
    case LOGGED_IN:
      return {
        ...state,
        accessToken: action.payload.data.access_token,
        refreshToken: action.payload.data.refresh_token,
        loginError: null,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        accessToken: null,
        refreshToken: null,
        loginError: action.payload.data,
      };
    case CLEAR_LOGIN_ERROR:
      return {
        ...state,
        loginError: null,
      };
    case UNAUTHORIZED:
      return {
        ...state,
        unauthorized: true,
      };
    case RESET_UNAUTHORIZED:
      return {
        ...state,
        unauthorized: false,
      };
    case FETCH_ME:
      return {
        ...state,
        me: action.payload.data,
        meError: null,
      };
    case FETCH_ME_ERROR:
      return {
        ...state,
        me: null,
        meError: action.payload.data,
      };
    case SET_ME:
      return {
        ...state,
        me: action.payload.data,
      };
    case LOGGING_OUT:
      return {
        ...state,
        loggingOut: true,
      };
    case LOGGED_OUT:
      return {
        ...state,
        loggingOut: false,
        accessToken: null,
        refreshToken: null,
        me: null,
        meError: null,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        accessToken: action.payload.data.access_token,
        refreshToken: action.payload.data.refresh_token,
        refreshTokenError: null,
      };
    case REFRESH_TOKEN_ERROR:
      return {
        ...state,
        refreshTokenError: action.payload.data,
      };
    case VALIDATE_USERNAME_SUCCESS:
      return {
        ...state,
        validateUsernameSuccess: action.payload.data,
        validateUsernameError: null,
      };
    case VALIDATE_USERNAME_ERROR:
      return {
        ...state,
        validateUsernameSuccess: null,
        validateUsernameError: action.payload.data,
      };
    case CLEAR_VALIDATE_USERNAME:
      return {
        ...state,
        validateUsernameSuccess: null,
        validateUsernameError: null,
      };
    case VALIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        validateEmailSuccess: action.payload.data,
        validateEmailError: null,
      };
    case VALIDATE_EMAIL_ERROR:
      return {
        ...state,
        validateEmailSuccess: null,
        validateEmailError: action.payload.data,
      };
    case CLEAR_VALIDATE_EMAIL:
      return {
        ...state,
        validateEmailSuccess: null,
        validateEmailError: null,
      };
    case PATCH_USER_SUCCESS:
      return {
        ...state,
        patchUserSuccess: action.payload.data,
        patchUserError: null,
      };
    case PATCH_USER_ERROR:
      return {
        ...state,
        patchUserSuccess: null,
        patchUserError: action.payload.data,
      };
    case CLEAR_PATCH_USER:
      return {
        ...state,
        patchUserSuccess: null,
        patchUserError: null,
      };
    case FETCH_PUBLIC_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        publicProfileDetails: action.payload.data,
        publicProfileDetailsError: null,
      };
    case FETCH_PUBLIC_PROFILE_DETAILS_ERROR:
      return {
        ...state,
        publicProfileDetails: null,
        publicProfileDetailsError: action.payload.data,
      };
    case CLEAR_FETCH_PUBLIC_PROFILE_DETAILS:
      return {
        ...state,
        publicProfileDetails: null,
        publicProfileDetailsError: null,
      };
    case SEND_PASSWORD_RESET_LINK_SUCCESS:
      return {
        ...state,
        sendPasswordResetLinkSuccess: action.payload.data,
        sendPasswordResetLinkError: null,
      };
    case SEND_PASSWORD_RESET_LINK_ERROR:
      return {
        ...state,
        sendPasswordResetLinkSuccess: null,
        sendPasswordResetLinkError: action.payload.data,
      };
    case CLEAR_SEND_PASSWORD_RESET_LINK:
      return {
        ...state,
        sendPasswordResetLinkSuccess: null,
        sendPasswordResetLinkError: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: action.payload.data,
        resetPasswordError: null,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordSuccess: null,
        resetPasswordError: action.payload.data,
      };
    case CLEAR_RESET_PASSWORD:
      return {
        ...state,
        resetPasswordSuccess: null,
        resetPasswordError: null,
      };
    default:
      return state;
  }
};
