import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import commonReducer from 'redux/common';
import usersReducer from 'redux/users';
import collectionsReducer from 'redux/collections';
import assetsReducer from 'redux/assets';
import blockchainReducer from 'redux/blockchain';
import billingReducer from 'redux/billing';
import toolsReducer from 'redux/tools';

export default combineReducers({
  commonReducer,
  usersReducer,
  collectionsReducer,
  assetsReducer,
  blockchainReducer,
  billingReducer,
  toolsReducer,
  form: formReducer,
});
