import React from 'react';

import RequireAuth from 'hoc/RequireAuth';
import MainPageLayout from 'components/common/layouts/MainPageLayout';
import CreateAssetPageContent from 'components/main/assets/create-content/CreateAssetPageContent';

const breadcrambs = [
  {
    text: 'Home',
    link: "/",
  },
  {
    text: 'Create & Mint New NFT',
    link: "#",
  },
];

const CreateAssetPage = () => (
  <RequireAuth>
    <MainPageLayout
      breadcrambs={breadcrambs}
      header="Create & Mint New NFT"
    >
      <CreateAssetPageContent />
    </MainPageLayout>
  </RequireAuth>
);

export default CreateAssetPage;
