import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import { createAsset } from 'redux/assets/actions';
import { fetchWalletBalance } from 'redux/blockchain/actions';

import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form } from 'semantic-ui-react';

import { Dimmer, Loader, Checkbox } from 'semantic-ui-react';

import UploadAssetMedia from 'components/main/assets/common/UploadAssetMedia';
import Button from 'components/common/elements/buttons/Button';
import TextField from 'components/redux-form-adapters/TextField';
import TextAreaField from 'components/redux-form-adapters/TextAreaField';
import SelectField from 'components/redux-form-adapters/SelectField';
import CommonMessage from 'components/common/messages/CommonMessage';

import MoonpayUpdateBalanceModal from 'components/main/profile-settings/moonpay/MoonpayUpdateBalanceModal';

import validate from './validate';

import './CreateAssetForm.css';


function CreateAssetForm(props) {
  const {
    handleSubmit,
    createAsset,
    fetchWalletBalance,
    valid,
    createAssetSuccess,
    createAssetError,
    collectionsOptions,
    change,
    reset,
    pageParams,
    me,
    walletBalance,
    projectSettings,
    collection,
    myCollectionsList,
  } = props;

  if (!me) return null;

  const [showLoader, setShowLoader] = useState(false);
  const [assetMedia, setAssetMedia] = useState(null);
  const [currentMedia, setCurrentMedia] = useState(null);
  const [showMoonpayModal, setShowMoonpayModal] = useState(false);
  const [isAgree, setIsAgree] = useState(false);

  const creationFee = projectSettings?.platform_fees?.nft_mint_fee_coin;
  const isBalanceEnough = (Number(walletBalance?.balance)) > Number(creationFee) ? true : false;

  const selectedCollectionStats = myCollectionsList?.results.filter(item => item.id === collection);

  const checkboxText = `I understand the current marketplace fee is
    ~$${projectSettings?.platform_fees?.nft_mint_fee_usd} USD
     (${projectSettings?.platform_fees?.nft_mint_fee_coin} HBAR)
      and I agree to the Terms of Service.`;

  useEffect(() => {
    if (me?.wallet && !walletBalance) {
      fetchWalletBalance();
    }
  }, []);

  useEffect(() => {
    if (collectionsOptions) {
      let defaultId = null;
      if (pageParams && pageParams.collectionSlug) {
        defaultId = collectionsOptions.filter(item => item.slug === pageParams.collectionSlug)[0] &&
          collectionsOptions.filter(item => item.slug === pageParams.collectionSlug)[0].value;
        change('collection', defaultId);
      } else {
        defaultId = collectionsOptions[0] && collectionsOptions[0].value;
        change('collection', defaultId);
      }
    }
  }, [collectionsOptions, pageParams]);

  useEffect(() => {
    if (createAssetSuccess) {
      setShowLoader(false);
      setAssetMedia(null);
      setCurrentMedia(null);
      setIsAgree(false);
      reset();
    }
  }, [createAssetSuccess]);

  useEffect(() => {
    if (createAssetError) {
      setShowLoader(false);
    }
  }, [createAssetError]);

  const _handleSubmit = values => {
    setShowLoader(true);

    const formData = new FormData();
    formData.append('token_name', values.token_name);
    formData.append('collection', values.collection);
    if (values.description) {
      formData.append('description', values.description);
    }
    if (assetMedia) {
      formData.append('nft_media', assetMedia);
    }

    createAsset(formData);
  };

  const renderMaxSupplyWarning = collection && myCollectionsList && selectedCollectionStats &&
    selectedCollectionStats[0]?.max_supply === selectedCollectionStats[0]?.items && (
      <div className="max-supply-warning">
        <CommonMessage warning>
          You reach collection max supply: {selectedCollectionStats[0]?.max_supply}
        </CommonMessage>
      </div>
    );

  return (
    <div className="create-asset-form__component">

      {/* Loader */}
      {showLoader &&
        <Dimmer active>
          <Loader>Please, wait... NFT creation is in progress.</Loader>
        </Dimmer>
      }

      <div className="form-wrapper">
        <Form
          onSubmit={handleSubmit(_handleSubmit)}
          error={!valid}
        >
          <Field
            label={'NAME'}
            component={TextField}
            placeholder={'e. g. "Redeemable Bitcoin Card with logo"'}
            name="token_name"
            type="text"
            labelPosition="right corner"
            required
          />

          <Field
            label="DESCRIPTION"
            component={TextAreaField}
            placeholder={'e. g. “After purchasing you will able to recived the logo...”'}
            name="description"
          />

          <Field
            search
            component={SelectField}
            label="COLLECTION"
            name="collection"
            options={collectionsOptions}
            placeholder={'Select collection'}
            onBlur={(e) => e.preventDefault()}
            required
          />

          {renderMaxSupplyWarning}

          {creationFee &&
            <div className="fee-text">
              The cost of NFT creating is ~{creationFee} HBAR
            </div>
          }

          <div className="checkbox-container">
            <Checkbox
              checked={isAgree}
              label={checkboxText}
              onChange={() => setIsAgree(!isAgree)}
            />
          </div>

          {!isBalanceEnough &&
            <CommonMessage warning>
              <div>
                You don&prime;t have enough <strong>HBAR</strong> to create NFT.
              </div>
              <div
                className="add-hbar-button"
                onClick={() => setShowMoonpayModal(true)}
              >
                Add HBAR with card
              </div>
            </CommonMessage>
          }

          {createAssetError &&
            <CommonMessage error errors={createAssetError} />
          }

          <div className="button-block">
            <Button
              className="primary"
              submit
              disabled={
                !valid ||
                !assetMedia ||
                !isBalanceEnough ||
                !isAgree ||
                renderMaxSupplyWarning
              }
            >
              Create item&nbsp;&nbsp;&rarr;
            </Button>
          </div>
        </Form>
      </div>

      <div className="upload-wrapper">
        <UploadAssetMedia
          setAssetMedia={setAssetMedia}
          currentMedia={currentMedia}
          setCurrentMedia={setCurrentMedia}
        />
      </div>

      <MoonpayUpdateBalanceModal
        open={showMoonpayModal}
        onClose={() => setShowMoonpayModal(false)}
      />
    </div>
  );
}

CreateAssetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  fetchWalletBalance: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  collectionsOptions: PropTypes.arrayOf(PropTypes.element),
  createAssetSuccess: PropTypes.object,
  createAssetError: PropTypes.object,
  pageParams: PropTypes.object,
  me: PropTypes.object,
  walletBalance: PropTypes.object,
  projectSettings: PropTypes.object,
  collection: PropTypes.number,
  myCollectionsList: PropTypes.arrayOf(PropTypes.element),
};

CreateAssetForm.defaultProps = {
  valid: false,
  collectionsOptions: null,
  createAssetSuccess: null,
  createAssetError: null,
  pageParams: null,
  me: null,
  walletBalance: null,
  projectSettings: null,
  collection: null,
  myCollectionsList: null,
};

const mapStateToProps = state => {
  const selector = formValueSelector('CreateAssetForm');
  return {
    collection: selector(state, 'collection'),
    walletBalance: state.blockchainReducer.walletBalance,
  };
};

export default connect(mapStateToProps, {
  createAsset,
  fetchWalletBalance,
})(reduxForm({
  form: 'CreateAssetForm',
  validate,
})(CreateAssetForm));
