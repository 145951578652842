import React from 'react';

import picOlga from './icons/contact-img-olga.webp';
import picKyrylo from './icons/contact-img-kyrylo.webp';
import picYou from './icons/contact-maybeyou.webp';
import ic_fb from './icons/facebook.svg';
import ic_instagram from './icons/instagram.svg';
import ic_linked from './icons/linkedin.svg';
import ic_pinterest from './icons/pinterest.svg';
import ic_tiktok from './icons/tiktok.svg';
import ic_anthill from './icons/anthill.svg';
import visa1 from './icons/visa1.svg';
import visa2 from './icons/visa2.svg';
import forbes from './icons/forbes.svg';
import telstra from './icons/telstra.svg';
import three_in_one from './icons/three.svg';
import muru from './icons/muru.svg';
import incubate from './icons/incubate.svg';
import partners from './icons/partners.svg';
import forbes2 from './icons/forbes2.svg';
import smh_com from './icons/smth_com.svg';
import insider from './icons/insider.svg';
import anthill2 from './icons/anthill2.svg';
import big_visa from './icons/big_visa.svg';
import lenovo from './icons/lenovo.svg';
import martec from './icons/martec.svg';




import './AboutAndFounders.css';

function AboutAndFounders() {
  const founders = [
    {
      photo: picOlga,
      fio: 'OLGA OLEINIKOVA',
      link: (
        <a href="https://www.linkedin.com/in/oleinikova/" target="blank">
          LinkedIn
        </a>
      ),
      mail: 'team@heylink.me',
      jobTitle: ['Co-founder', 'CEO'],
    },
    {
      photo: picKyrylo,
      fio: 'KYRYLO MEDVEDIEV',
      link: (
        <a href="https://www.linkedin.com/in/medvediev/" target="blank">
          LinkedIn
        </a>
      ),
      mail: 'kyrylo@heylink.me',
      jobTitle: ['Co-founder', 'COO'],
    },
    {
      photo: picYou,
      fio: 'MAYBE YOU?',
      link: '',
      mail: 'team@heylink.me',
      jobTitle: ['Contact Us'],
    },
  ];

  const RenderFounder = (item, index) => (
    <div className="founder" key={`founder-${index}`}>
      <img src={item.photo} />
      <div className="founder-name">{item.fio}</div>
      <div className="job-title">{item.jobTitle[0]}</div>
      <div className="job-title">{item.jobTitle[1]}</div>
      <div className="e-mail">{item.mail}</div>
      <div className="link">{item.link}</div>
    </div>
  );

  return (
    <div className="about-and-founders__component">
      <div className="title">About HeyMint.me</div>
      <div className="line"></div>
      <div className="text-wrapper">
        <div>
          We are an Australian company powered by awesome brands and people, for
          all those doing what they love. We`ve crafted HeyMint.me to empower
          brands, influencers, bloggers, freelancers and artists anywhere and
          anytime.
        </div>
        <div>
          Join our community of successful businesses and passionate creators
          and let HeyMint.me take care of the rest!
        </div>
        <div>
          HeyMint.me is powered &amp; run by the award-winning Persollo team.
        </div>
      </div>
      <div className="title">Meet our founders &amp; join the team!</div>
      <div className="line"></div>
      <div className="text-wrapper">
        <div>
          Persollo won`t be possible without the great team of professionals who
          are passionate about what they are working on.
        </div>
      </div>
      <div className="founders-wrapper">
        {founders.map((item, index) => RenderFounder(item, index))}
      </div>
      <div className="title">Follow us in social networks</div>
      <div className="line"></div>
      <div className="text-wrapper">
        <div>
          It′s the best way for you to see our latest news, read stories of our
          happy customers and learn new things about influencer marketing and
          online sales.
        </div>
      </div>
      <div className="social-wrapper">
        <a href='https://www.facebook.com/heylink.me/' target='_blank' rel='noreferrer'>
          <img src={ic_fb} alt="" />
        </a>
        <a href='https://www.instagram.com/heylinkme/' target='_blank' rel='noreferrer'><img src={ic_instagram} alt="" /></a>
        <a href='https://www.linkedin.com/company/heylink-me/' target='_blank' rel='noreferrer'>
          <img src={ic_linked} alt="" />
        </a>
        <a href='https://www.pinterest.com.au/heylinkme/' target='_blank' rel='noreferrer'>
          <img src={ic_pinterest} alt="" />
        </a>
        <a href='https://www.tiktok.com/@heylink.me' target='_blank' rel='noreferrer'>
          <img src={ic_tiktok} alt="" />
        </a>
      </div>
      <div className="title">A bit more about us…</div>
      <div className="text-wrapper">
        <div>(just click on any logo)</div>
      </div>
      <div className="title">Awards</div>
      <div className="awards-wrapper">
        <div className='awards-content'>
          <a href="https://anthillonline.com/olga-oleinikova-kyrylo-medvediew-serg-metelin-2017-anthill-30under30-winner/" target='_blank' rel='noreferrer'>
            <div>
              <img src={ic_anthill} alt="" />
            </div>
          </a>
          <a href="https://www.visa.com.au/visa-everywhere/everywhere-initiative.html#4" target='_blank' rel='noreferrer'>
            <div className='visa'>
              <img src={visa1} alt="" />
              <img src={visa2} alt="" />
            </div>
          </a>
          <a href="https://www.forbes.com/30-under-30-asia/2018/retail-ecommerce/#1c3888326e8d" target='_blank' rel='noreferrer'>
            <div>
              <img src={forbes} alt="" />
            </div>
          </a>
        </div>
        <div className="accelerated-content">
          <a>
            <div className='side'>
              <span className="text">POWERED BY</span>
              <img src={telstra} alt="" />
            </div>
          </a>
          <a href="https://www.cityofsydney.nsw.gov.au/" target='_blank' rel='noreferrer'>
            <div className='middle'>
              <span className="text">SUPPORTED BY</span>
              <img src={three_in_one} alt="" />
            </div>
          </a>
          <div className='muru-incubate'>
            <div className='side two-in-one'>
              <span className="text">ACCELERATED BY</span>
              <a href="https://muru-d.com/portfolio/persollo/" target='_blank' rel='noreferrer'><img src={muru} alt="" /></a>
              <a href="https://incubate.org.au/" target='_blank' rel='noreferrer'><img src={incubate} alt="" /></a>
            </div>
          </div>
        </div>
      </div>
      <div className="title">Partners</div>
      <div className='partners-wrapper'>
        <a href='https://hedera.com/' target='_blank' rel='noreferrer'>
          <div className="partners">
            <img src={partners} alt="" />
          </div>
        </a>
      </div>
      <div className="title">Work is featured &amp; trusted by</div>
      <div className="featured-trusted-wrapper">
        <div className="first-line-wrapper">
          <a href="https://www.forbes.com/profile/persollo/?sh=c566e72f735d" target='_blank' rel='noreferrer'>
            <div className='featured-trusted-item'>
              <img src={forbes2} alt="" />
            </div>
          </a>
          <a href="https://www.smh.com.au/business/small-business/13-australian-startups-to-watch-in-2016-20160108-gm1ucq.html" target='_blank' rel='noreferrer'>
            <div className='featured-trusted-item'>
              <img src={smh_com} alt="" />
            </div>
          </a>
          <a>
            <div className='featured-trusted-item'>
              <img src={insider} alt="" />
            </div>
          </a>
          <a href="https://anthillonline.com/olga-oleinikova-kyrylo-medvediew-serg-metelin-2017-anthill-30under30-winner/" target='_blank' rel='noreferrer'>
            <div className='featured-trusted-item'>
              <img src={anthill2} alt="" />
            </div>
          </a>
        </div>
        <div className="second-line-wrapper">
          <a>
            <div className="featured-trusted-item">
              <img src={big_visa} alt="" />
            </div>
          </a>
          <a>
            <div className="featured-trusted-item">
              <img src={lenovo} alt="" />
            </div>
          </a>
          <a href="https://www.themartec.com/insidelook/50-top-startups-sydney" target='_blank' rel='noreferrer'>
            <div className="featured-trusted-item">
              <img src={martec} alt="" />
            </div>
          </a>
        </div>
      </div>      
    </div>
  );
}

export default AboutAndFounders;
