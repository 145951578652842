export const calculateFiltersPath = (path, filters) => {
  let _path = path;

  // Sell Type
  if (filters?.saleType?.length > 0) {
    _path += `&sale_type=`;
    filters.saleType.forEach((item, i) => {
      _path += `${item}${i < filters.saleType.length - 1 ? ',' : ''}`;
    });
  }

  // Sorting
  if (filters?.sorting) {
    _path += `&ordering=${filters.sorting}`;
  }

  return _path;
};
