import React from 'react';
import { Helmet } from 'react-helmet';
import MainPageLayout from 'components/common/layouts/MainPageLayout';
import PrivacyPageContent from 'components/main/info/PrivacyPageContent';

const PrivacyPage =() => (
  <MainPageLayout header={'Privacy Policy'}>
    <Helmet>
      <title>Privacy Policy | HeyMint.me </title>
      <meta property="og:title" content="Privacy Policy | HeyMint.me " />
      <meta
        name="description"
        content="HeyMint.me Privacy page. Learn more about our privacy terms and policies."
      />
      <meta
        property="og:description"
        content="HeyMint.me Privacy page. Learn more about our privacy terms and policies."
      />
    </Helmet>
    <PrivacyPageContent/>
  </MainPageLayout>
);

export default PrivacyPage;
