import restapi from 'utils/restapi';
import { baseErrorsHandlerDecorator } from 'redux/utils';

import {
  FETCH_GLOBAL_SEARCH_DATA,
  FETCH_GLOBAL_SEARCH_DATA_ERROR,
  CLEAR_GLOBAL_SEARCH_DATA,
  SEND_CONTACT_MESSAGE_SUCCESS,
  SEND_CONTACT_MESSAGE_ERROR,
  CLEAR_SEND_CONTACT_MESSAGE,
} from 'redux/tools/types';


export const fetchGlobalSearchData = (value, area = null) => baseErrorsHandlerDecorator(
  FETCH_GLOBAL_SEARCH_DATA,
  FETCH_GLOBAL_SEARCH_DATA_ERROR,
  () => restapi.get(`v1/tools/search-bar/?query=${value}${area ? `&search_area=${area}` : ''}`),
  false,
);

export const clearFetchGlobalSearchData = () => dispatch => {
  dispatch({
    type: CLEAR_GLOBAL_SEARCH_DATA,
  });
};

export const sendContactMessage = formData => baseErrorsHandlerDecorator(
  SEND_CONTACT_MESSAGE_SUCCESS,
  SEND_CONTACT_MESSAGE_ERROR,
  () => restapi.post('v1/tools/get-in-touch/', formData),
  false,
);

export const clearSendContactMessage = () => dispatch => {
  dispatch({
    type: CLEAR_SEND_CONTACT_MESSAGE,
  });
};
