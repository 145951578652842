import React from 'react';
import PropTypes from 'prop-types';

import ReactPaginate from 'react-paginate';

import './CommonPagination.css';

function CommonPagination(props) {
  const {
    pageCount,
    marginPagesDisplayed,
    pageRangeDisplayed,
    onPageChange,
    containerClassName,
  } = props;

  return (
    <ReactPaginate
      containerClassName={`common-pagination__component${containerClassName ? ` ${containerClassName}` : ''}`}
      activeClassName="active"
      previousLabel="<"
      nextLabel=">"
      pageCount={pageCount}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      onPageChange={onPageChange}
    />
  );
}

CommonPagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  marginPagesDisplayed: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  forcePage: PropTypes.number,
  containerClassName: PropTypes.string,
};

CommonPagination.defaultProps = {
  marginPagesDisplayed: null,
  pageRangeDisplayed: null,
  forcePage: null,
  containerClassName: null,
};

export default CommonPagination;
