import {
  FETCH_PROFILE_ASSETS_LIST,
  FETCH_PROFILE_ASSETS_LIST_ERROR,
  CLEAR_FETCH_PROFILE_ASSETS_LIST,
  CREATE_ASSET_SUCCESS,
  CREATE_ASSET_ERROR,
  CLEAR_CREATE_ASSET,
  FETCH_ASSET_DETAILS,
  FETCH_ASSET_DETAILS_ERROR,
  CLEAR_FETCH_ASSET_DETAILS,
  FETCH_COLLECTION_ASSETS_LIST,
  FETCH_COLLECTION_ASSETS_LIST_ERROR,
  CLEAR_FETCH_COLLECTION_ASSETS_LIST,
  FETCH_EXPLORE_ASSETS_LIST,
  FETCH_EXPLORE_ASSETS_LIST_ERROR,
  CLEAR_FETCH_EXPLORE_ASSETS_LIST,
  ASSET_LISTING_SUCCESS,
  ASSET_LISTING_ERROR,
  CLEAR_ASSET_LISTING,
  CANCEL_ASSET_LISTING_SUCCESS,
  CANCEL_ASSET_LISTING_ERROR,
  CLEAR_CANCEL_ASSET_LISTING,
  FETCH_ASSET_ACTIVITIES_LIST,
  FETCH_ASSET_ACTIVITIES_LIST_ERROR,
  CLEAR_FETCH_ASSET_ACTIVITIES_LIST,
  BUY_ASSET_SUCCESS,
  BUY_ASSET_ERROR,
  CLEAR_BUY_ASSET,
  HIDE_ASSET_SUCCESS,
  HIDE_ASSET_ERROR,
  CLEAR_HIDE_ASSET,
} from 'redux/assets/types';


const initialState = {
  profileAssetsList: null,
  profileAssetsListError: null,
  createAssetSuccess: null,
  createAssetError: null,
  assetDetails: null,
  assetDetailsError: null,
  collectionAssetsList: null,
  collectionAssetsListError: null,
  exploreAssetsList: null,
  exploreAssetsListError: null,
  assetListingSuccess: null,
  assetListingError: null,
  cancelAssetListingSuccess: null,
  cancelAssetListingError: null,
  assetActivitiesList: null,
  assetActivitiesListError: null,
  buyAssetSuccess: null,
  buyAssetError: null,
  hideAssetSuccess: null,
  hideAssetError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE_ASSETS_LIST:
      return {
        ...state,
        profileAssetsList: action.payload.data,
        profileAssetsListError: null,
      };
    case FETCH_PROFILE_ASSETS_LIST_ERROR:
      return {
        ...state,
        profileAssetsList: null,
        profileAssetsListError: action.payload.data,
      };
    case CLEAR_FETCH_PROFILE_ASSETS_LIST:
      return {
        ...state,
        profileAssetsList: null,
        profileAssetsListError: null,
      };
    case CREATE_ASSET_SUCCESS:
      return {
        ...state,
        createAssetSuccess: action.payload.data,
        createAssetError: null,
      };
    case CREATE_ASSET_ERROR:
      return {
        ...state,
        createAssetSuccess: null,
        createAssetError: action.payload.data,
      };
    case CLEAR_CREATE_ASSET:
      return {
        ...state,
        createAssetSuccess: null,
        createAssetError: null,
      };
    case FETCH_ASSET_DETAILS:
      return {
        ...state,
        assetDetails: action.payload.data,
        assetDetailsError: null,
      };
    case FETCH_ASSET_DETAILS_ERROR:
      return {
        ...state,
        assetDetails: null,
        assetDetailsError: action.payload.data,
      };
    case CLEAR_FETCH_ASSET_DETAILS:
      return {
        ...state,
        assetDetails: null,
        assetDetailsError: null,
      };
    case FETCH_COLLECTION_ASSETS_LIST:
      return {
        ...state,
        collectionAssetsList: action.payload.data,
        collectionAssetsListError: null,
      };
    case FETCH_COLLECTION_ASSETS_LIST_ERROR:
      return {
        ...state,
        collectionAssetsList: null,
        collectionAssetsListError: action.payload.data,
      };
    case CLEAR_FETCH_COLLECTION_ASSETS_LIST:
      return {
        ...state,
        collectionAssetsList: null,
        collectionAssetsListError: null,
      };
    case FETCH_EXPLORE_ASSETS_LIST:
      return {
        ...state,
        exploreAssetsList: action.payload.data,
        exploreAssetsListError: null,
      };
    case FETCH_EXPLORE_ASSETS_LIST_ERROR:
      return {
        ...state,
        exploreAssetsList: null,
        exploreAssetsListError: action.payload.data,
      };
    case CLEAR_FETCH_EXPLORE_ASSETS_LIST:
      return {
        ...state,
        exploreAssetsList: null,
        exploreAssetsListError: null,
      };
    case ASSET_LISTING_SUCCESS:
      return {
        ...state,
        assetListingSuccess: action.payload.data,
        assetListingError: null,
      };
    case ASSET_LISTING_ERROR:
      return {
        ...state,
        assetListingSuccess: null,
        assetListingError: action.payload.data,
      };
    case CLEAR_ASSET_LISTING:
      return {
        ...state,
        assetListingSuccess: null,
        assetListingError: null,
      };
    case CANCEL_ASSET_LISTING_SUCCESS:
      return {
        ...state,
        cancelAssetListingSuccess: action.payload.data,
        cancelAssetListingError: null,
      };
    case CANCEL_ASSET_LISTING_ERROR:
      return {
        ...state,
        cancelAssetListingSuccess: null,
        cancelAssetListingError: action.payload.data,
      };
    case CLEAR_CANCEL_ASSET_LISTING:
      return {
        ...state,
        cancelAssetListingSuccess: null,
        cancelAssetListingError: null,
      };
    case FETCH_ASSET_ACTIVITIES_LIST:
      return {
        ...state,
        assetActivitiesList: action.payload.data,
        assetActivitiesListError: null,
      };
    case FETCH_ASSET_ACTIVITIES_LIST_ERROR:
      return {
        ...state,
        assetActivitiesList: null,
        assetActivitiesListError: action.payload.data,
      };
    case CLEAR_FETCH_ASSET_ACTIVITIES_LIST:
      return {
        ...state,
        assetActivitiesList: null,
        assetActivitiesListError: null,
      };
    case BUY_ASSET_SUCCESS:
      return {
        ...state,
        buyAssetSuccess: action.payload.data,
        buyAssetError: null,
      };
    case BUY_ASSET_ERROR:
      return {
        ...state,
        buyAssetSuccess: null,
        buyAssetError: action.payload.data,
      };
    case CLEAR_BUY_ASSET:
      return {
        ...state,
        buyAssetSuccess: null,
        buyAssetError: null,
      };
    case HIDE_ASSET_SUCCESS:
      return {
        ...state,
        hideAssetSuccess: action.payload.data,
        hideAssetError: null,
      };
    case HIDE_ASSET_ERROR:
      return {
        ...state,
        hideAssetSuccess: null,
        hideAssetError: action.payload.data,
      };
    case CLEAR_HIDE_ASSET:
      return {
        ...state,
        hideAssetSuccess: null,
        hideAssetError: null,
      };
    default:
      return state;
  }
};
