export const CREATE_STRIPE_PAYMENT_INTENT_SUCCESS = 'BILLING/billing/CREATE_STRIPE_PAYMENT_INTENT';
export const CREATE_STRIPE_PAYMENT_INTENT_ERROR = 'BILLING/billing/CREATE_STRIPE_PAYMENT_INTENT_ERROR';
export const CLEAR_CREATE_STRIPE_PAYMENT_INTENT = 'BILLING/billing/CLEAR_CREATE_STRIPE_PAYMENT_INTENT';

export const CONFIRM_STRIPE_PAYMENT_INTENT_SUCCESS = 'BILLING/billing/CONFIRM_STRIPE_PAYMENT_INTENT';
export const CONFIRM_STRIPE_PAYMENT_INTENT_ERROR = 'BILLING/billing/CONFIRM_STRIPE_PAYMENT_INTENT_ERROR';
export const CLEAR_CONFIRM_STRIPE_PAYMENT_INTENT = 'BILLING/billing/CLEAR_CONFIRM_STRIPE_PAYMENT_INTENT';

export const FETCH_MOONPAY_URL_SUCCESS = 'BILLING/billing/FETCH_MOONPAY_URL_SUCCESS';
export const FETCH_MOONPAY_URL_ERROR = 'BILLING/billing/FETCH_MOONPAY_URL_ERROR';
export const CLEAR_FETCH_MOONPAY_URL = 'BILLING/billing/CLEAR_FETCH_MOONPAY_URL';
