import React from 'react';
import PropTypes from "prop-types";

import Parser from 'html-react-parser';

import './SingleAssetInfoTabContent.css';


function SingleAssetInfoTabContent(props) {
  const { assetDetails } = props;

  return (
    <div className="single-asset-info-tab-content__component">
      <div className="blockchain-info">
        <div className="icon">
          {Parser(assetDetails.blockchain.logo)}
        </div>
        <div className="name">{assetDetails.blockchain.name}</div>
      </div>

      {assetDetails.description &&
        <div className="description">
          <div className="label">Description:</div>
          <div className="text">{assetDetails.description}</div>
        </div>
      }
    </div>
  );
}

SingleAssetInfoTabContent.propTypes = {
  assetDetails: PropTypes.object.isRequired,
};

export default SingleAssetInfoTabContent;
