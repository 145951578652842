import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

import { connect } from 'react-redux';
import {
  fetchAssetDetails,
  clearSetAssetListing,
  cancelAssetListing,
  clearCancelAssetListing,
  hideAsset,
  clearHideAsset,
} from 'redux/assets/actions';

import Button from 'components/common/elements/buttons/Button';
import ConfirmationModal from 'components/common/elements/modals/ConfirmationModal';
import CommonMessage from 'components/common/messages/CommonMessage';

import AssetListingModal from 'components/main/assets/single-content/actions/AssetListingModal';

import './AssetOwnerActions.css';


function AssetOwnerActions(props) {
  const {
    fetchAssetDetails,
    clearSetAssetListing,
    cancelAssetListing,
    clearCancelAssetListing,
    hideAsset,
    clearHideAsset,
    assetDetails,
    assetListingSuccess,
    cancelAssetListingSuccess,
    cancelAssetListingError,
    hideAssetSuccess,
    hideAssetError,
  } = props;

  const [showListingModal, setShowListingModal] = useState(false);
  const [showCancelListingModal, setShowCancelListigModal] = useState(false);
  const [showHideModal, setShowHideModal] = useState(false);

  useEffect(() => {
    return () => {
      clearSetAssetListing(assetDetails.token_id);
      clearCancelAssetListing();
      clearHideAsset();
    }
  }, []);

  useEffect(() => {
    if (assetListingSuccess) {
      fetchAssetDetails(assetDetails.token_id, assetDetails.serial_number);
      clearSetAssetListing();
      setShowListingModal(false);
    }
  }, [assetListingSuccess]);

  useEffect(() => {
    if (cancelAssetListingSuccess) {
      fetchAssetDetails(assetDetails.token_id, assetDetails.serial_number);
      clearCancelAssetListing();
      setShowCancelListigModal(false);
    }
  }, [cancelAssetListingSuccess]);

  useEffect(() => {
    if (hideAssetSuccess) {
      fetchAssetDetails(assetDetails.token_id, assetDetails.serial_number);
      clearHideAsset();
      setShowHideModal(false);
    }
  }, [hideAssetSuccess]);

  return (
    <div className="asset-owner-actions__component">
      {/* Hide/Unhide */}
      <Button
        className="secondary small"
        onClick={() => setShowHideModal(true)}
      >
        {assetDetails?.is_published ? 'Hide' : 'Unhide'}
      </Button>

      {/* Buy/Sell */}
      <Button
        className="primary small"
        onClick={() => {
          if (assetDetails?.is_token_listed) {
            setShowCancelListigModal(true);
          } else {
            setShowListingModal(true);
          }
        }}
      >
        {assetDetails?.is_token_listed ? 'Cancel listing' : 'Sell'}
      </Button>

      <AssetListingModal
        open={showListingModal}
        onClose={() => setShowListingModal(false)}
        onUnmount={() => {
          clearSetAssetListing();
        }}
      />

      {/* Listing */}
      <ConfirmationModal
        open={showCancelListingModal}
        onClose={() => setShowCancelListigModal(false)}
        onUnmount={() => {
          clearCancelAssetListing();
        }}
        title={'Cancel listing'}
        text={'Are you sure you want to cancel your listing?'}
        onConfirm={() => {
          cancelAssetListing(
            assetDetails?.collection?.token_id,
            assetDetails.serial_number,
          );
        }}
        cancelButton
      >
        {cancelAssetListingError &&
          <CommonMessage error errors={cancelAssetListingError} />
        }
      </ConfirmationModal>

      {/* Hide */}
      <ConfirmationModal
        open={showHideModal}
        onClose={() => setShowHideModal(false)}
        onUnmount={() => {
          clearHideAsset();
        }}
        title={`${assetDetails?.is_published ? 'Hide' : 'Unhide'} Item`}
        text={`Are you sure you want to ${assetDetails?.is_published ? 'hide' : 'unhide'} your item?`}
        onConfirm={() => {
          hideAsset(
            assetDetails?.collection?.token_id,
            assetDetails.serial_number,
            assetDetails?.is_published ? false : true,
          );
        }}
        cancelButton
      >
        {hideAssetError &&
          <CommonMessage error errors={hideAssetError} />
        }
      </ConfirmationModal>

    </div>
  );
}

AssetOwnerActions.propTypes = {
  fetchAssetDetails: PropTypes.func.isRequired,
  clearSetAssetListing: PropTypes.func.isRequired,
  cancelAssetListing: PropTypes.func.isRequired,
  clearCancelAssetListing: PropTypes.func.isRequired,
  hideAsset: PropTypes.func.isRequired,
  clearHideAsset: PropTypes.func.isRequired,
  assetDetails: PropTypes.object.isRequired,
  assetListingSuccess: PropTypes.object,
  cancelAssetListingSuccess: PropTypes.object,
  cancelAssetListingError: PropTypes.object,
  hideAssetSuccess: PropTypes.object,
  hideAssetError: PropTypes.object,
};

AssetOwnerActions.defaultProps = {
  assetListingSuccess: null,
  cancelAssetListingSuccess: null,
  cancelAssetListingError: null,
  hideAssetSuccess: null,
  hideAssetError: null,
};

const mapStateToProps = state => (
  {
    assetListingSuccess: state.assetsReducer.assetListingSuccess,
    cancelAssetListingSuccess: state.assetsReducer.cancelAssetListingSuccess,
    cancelAssetListingError: state.assetsReducer.cancelAssetListingError,
    hideAssetSuccess: state.assetsReducer.hideAssetSuccess,
    hideAssetError: state.assetsReducer.hideAssetError,
  }
);

export default connect(mapStateToProps, {
  fetchAssetDetails,
  clearSetAssetListing,
  cancelAssetListing,
  clearCancelAssetListing,
  hideAsset,
  clearHideAsset,
})(AssetOwnerActions);
