export const FETCH_PROFILE_ASSETS_LIST = 'ASSETS/FETCH_PROFILE_ASSETS_LIST';
export const FETCH_PROFILE_ASSETS_LIST_ERROR = 'ASSETS/FETCH_PROFILE_ASSETS_LIST_ERROR';
export const CLEAR_FETCH_PROFILE_ASSETS_LIST = 'ASSETS/CLEAR_FETCH_PROFILE_ASSETS_LIST';

export const CREATE_ASSET_SUCCESS = 'ASSETS/CREATE_ASSET_SUCCESS';
export const CREATE_ASSET_ERROR = 'ASSETS/CREATE_ASSET_ERROR';
export const CLEAR_CREATE_ASSET = 'ASSETS/CLEAR_CREATE_ASSET';

export const FETCH_ASSET_DETAILS = 'ASSETS/FETCH_ASSET_DETAILS';
export const FETCH_ASSET_DETAILS_ERROR = 'ASSETS/FETCH_ASSET_DETAILS_ERROR';
export const CLEAR_FETCH_ASSET_DETAILS = 'ASSETS/CLEAR_FETCH_ASSET_DETAILS';

export const FETCH_COLLECTION_ASSETS_LIST = 'ASSETS/FETCH_COLLECTION_ASSETS_LIST';
export const FETCH_COLLECTION_ASSETS_LIST_ERROR = 'ASSETS/FETCH_COLLECTION_ASSETS_LIST_ERROR';
export const CLEAR_FETCH_COLLECTION_ASSETS_LIST = 'ASSETS/CLEAR_FETCH_COLLECTION_ASSETS_LIST';

export const FETCH_EXPLORE_ASSETS_LIST = 'ASSETS/FETCH_EXPLORE_ASSETS_LIST';
export const FETCH_EXPLORE_ASSETS_LIST_ERROR = 'ASSETS/FETCH_EXPLORE_ASSETS_LIST_ERROR';
export const CLEAR_FETCH_EXPLORE_ASSETS_LIST = 'ASSETS/CLEAR_FETCH_EXPLORE_ASSETS_LIST';

export const ASSET_LISTING_SUCCESS = 'ASSETS/ASSET_LISTING_SUCCESS';
export const ASSET_LISTING_ERROR = 'ASSETS/ASSET_LISTING_ERROR';
export const CLEAR_ASSET_LISTING = 'ASSETS/CLEAR_ASSET_LISTING';

export const CANCEL_ASSET_LISTING_SUCCESS = 'ASSETS/CANCEL_ASSET_LISTING_SUCCESS';
export const CANCEL_ASSET_LISTING_ERROR = 'ASSETS/CANCEL_ASSET_LISTING_ERROR';
export const CLEAR_CANCEL_ASSET_LISTING = 'ASSETS/CLEAR_CANCEL_ASSET_LISTING';

export const FETCH_ASSET_ACTIVITIES_LIST = 'ASSETS/FETCH_ASSET_ACTIVITIES_LIST';
export const FETCH_ASSET_ACTIVITIES_LIST_ERROR = 'ASSETS/FETCH_ASSET_ACTIVITIES_LIST_ERROR';
export const CLEAR_FETCH_ASSET_ACTIVITIES_LIST = 'ASSETS/CLEAR_FETCH_ASSET_ACTIVITIES_LIST';

export const BUY_ASSET_SUCCESS = 'ASSETS/BUY_ASSET_SUCCESS';
export const BUY_ASSET_ERROR = 'ASSETS/BUY_ASSET_ERROR';
export const CLEAR_BUY_ASSET = 'ASSETS/CLEAR_BUY_ASSET';

export const HIDE_ASSET_SUCCESS = 'ASSETS/HIDE_ASSET_SUCCESS';
export const HIDE_ASSET_ERROR = 'ASSETS/HIDE_ASSET_ERROR';
export const CLEAR_HIDE_ASSET = 'ASSETS/CLEAR_HIDE_ASSET';
